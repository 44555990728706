.c-copyright {
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: rgba($g-color-secondary, 0.3);

  > a {
    color: rgba($g-color-secondary, 0.3);
    text-decoration: underline;
  }

  img {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
