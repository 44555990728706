&--search-funnel {
  width: 100%;

  #{$root} {
    &__input {
      border-width: 0;
      background-color: transparent;
      width: 100%;
      padding-left: 26px;
      padding-right: 20px;
      color: $g-color-secondary;
      @include h-typo--body-2;

      &::placeholder {
        color: rgba($g-color-secondary, 0.3);
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 16px;
      color: $g-color-primary;
    }

    &__cancel {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      padding: 3px;
      color: $g-color-red;
      cursor: pointer;
      border: none;
      background-color: transparent;

      &:hover {
        color: $g-color-red-hover;
      }
    }
  }
}
