.c-sidebar {
  $root: &;
  min-width: 250px;
  max-width: 100%;
  height: 100%;
  position: relative;

  @include media-breakpoint-up(sm) {
    margin: 0;
  }

  &__overlay {
    @include media-breakpoint-up(sm) {
      height: 100%;
      width: 100%;
      opacity: 0;
      right: 0;
      top: 0;
      position: fixed;
      transform: translate(250px, 64px);
      z-index: 1;
    }

    @include media-breakpoint-up(lg) {
      background-color: $g-color-light-gray;
      opacity: 0.85;
      position: fixed;
      transform: none;
      left: 0;
      width: 250px;
    }
  }

  &__search {
    padding: 0 20px;
    margin-top: 20px;
    position: relative;
  }

  &__mobile {
    display: block;

    #{$root} {
      &__logo-menu {
        margin: 10px 0;
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__search-results {
    position: absolute;
    top: calc(100% + 10px);
    background-color: $g-color-white;
    border-radius: 5px;
    z-index: 1;
    width: calc(100% - 40px);
    border: 1px solid rgba(0, 14, 75, 0.2);
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    max-height: 300px;
    overflow-y: auto;
    left: 20px;
    @include h-general-transition;
    opacity: 0;
    visibility: hidden;

    &.is-shown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__search-result {
    cursor: pointer;
    @include h-general-transition;
    display: flex;
    align-items: center;
    padding: 0 11px;
    height: 38px;
    border-bottom: 1px solid $g-color-gray;

    &:hover,
    &.is-selected {
      background-color: rgba($g-color-primary, 0.07);
      color: $g-color-primary;
    }

    &:last-child {
      border-bottom: none;
    }

    &.-not-found {
      opacity: 0.5;
    }
  }

  &__inner {
    height: 100%;
    width: 100%;
    flex: none;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    background-image: url('../../../../assets/new-img/sidebar/sidebar-bottom-bg.svg');
    background-repeat: no-repeat;
    background-position: right bottom;

    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }

  &__header {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $g-color-gray;

    @include media-breakpoint-up(sm) {
      padding-top: initial;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
      padding-top: 20px;
      padding-bottom: 0;
      justify-content: space-between;
      border-bottom: none;
    }
    &:hover {
      #{$root}__header-btn::after {
        opacity: 1;
      }
    }
  }

  &__header-btn {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &:after {
      color: $g-color-primary;
      font-family: 'media500';
      content: '\e904';
      font-size: 6px;
      margin-left: 6px;
      opacity: 0.3;
      @include h-general-transition(opacity);
    }

    &.-active::after {
      opacity: 1;
      transform: scale(1, -1);
    }
  }

  &__application-mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }

  &__application-desktop {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__selector {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 70px;
      justify-content: center;
      top: 88px;
      z-index: 12;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 16px;
    }
  }

  &__control {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: rgba($g-color-secondary, 0.3);
  }

  &__control-btn,
  &__control-btn.p500-ui-text-button {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: $g-color-primary;
  }

  &__navigation {
    height: 100%;
    overflow-y: scroll;
    @include h-scrolled;

    &::after {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      top: 0;
      display: block;
    }
  }

  &__footer {
    position: relative;
    margin-top: auto;
    background-color: $g-color-white;
    border-top: 1px solid $g-color-gray;
    padding: 0;
    height: 50px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__pattern {
    display: none;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      display: initial;
    }
  }

  &__logo {
    flex-flow: column nowrap;
    position: relative;
    display: none;
    background-color: rgba($g-color-primary, 0.05);
    border-radius: 8px;
    padding: 12px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
      display: flex;
      min-width: 202px;
    }

    &-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      @include h-typo--additional;
      font-weight: bold;
      max-width: 146px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        margin-bottom: 2px;
      }
    }

    &-menu {
      display: none;

      &.-isShow {
        display: block;
        margin-top: 10px;
      }
    }

    &-toggle {
      flex-shrink: 0;
      position: relative;
      width: 28px;
      height: 28px;
      font-size: 10px;
      border-radius: 8px;
      background-color: rgba($g-color-primary, 0.1);
      color: $g-color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }

    img {
      margin-right: 8px;
      object-fit: contain;
    }
  }

  &__info {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__tickers {
    z-index: 2;
  }

  &__datetime {
    z-index: 2;
    color: $g-color-primary;
  }

  &__notifications {
    z-index: 2;
    margin-left: auto;
  }

  &__account {
    display: flex;
    align-items: center;
  }

  &__logout {
    margin-left: auto;
  }

  &__navigation-footer {
    @include h-typo--body-2;
    display: flex;
    margin-left: 15px;
    .c-field-value {
      font-weight: bold;
    }
  }

  &__navigation-button {
    @include h-general-transition(background-color);
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($g-color-primary, 0.1);
    border-radius: 8px;
    margin-right: 14px;
    padding: 9px;
    font-weight: bold;
    color: $g-color-primary;
    gap: 6px;

    &:hover {
      background-color: $g-color-primary;
      color: $g-color-white;
    }
  }

  &.-open-selector {
    @include media-breakpoint-up(lg) {
      #{$root} {
        &__overlay {
          top: 60px;
        }
      }
    }
  }

  &__header-stub {
    padding: 6px 0;
    width: 70%;
    position: relative;
    background-color: #dedfe3;
    margin: auto;
  }

  &__header-stub:before {
    content: '';
    background-position: 0 0;
    height: 11px;
    width: 100%;
    position: absolute;
    top: 0;
    animation-name: backgrounds;
    animation-duration: 1s;
    animation-timing-function: easeOutCubic;
    background: linear-gradient(to left, #e2e1e1 10%, #e6e6e6 30%);
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}
