.c-form-field {
  $root: &;
  position: relative;
  display: block;

  label {
    margin: 0;
  }

  &__label {
    cursor: pointer;
    user-select: none;
  }

  /*TODO: Remove c-form-field--d  */
  @import 'c-form-field--d';
  /*TODO:
     Remove c-form-field--pagination
   */
  @import 'c-form-field--pagination';
  @import 'c-form-field--search';
  @import 'c-form-field--state--error';
  @import 'c-form-field--search-wiki';
  @import 'c-form-field--search-funnel';
  @import 'c-form-field--radio';
  @import 'c-form-field--upload';
  @import 'c-cofmr-field--settings';
}
