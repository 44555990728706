.c-table-header {
  @include h-typo--body-2;
  background-color: $g-color-white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(mx) {
    min-width: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    @include h-typo--headline-1;
    margin-right: 15px;

    @include media-breakpoint-down(xs) {
      display: none;
    }

    &.-gray {
      color: rgba($g-color-secondary, 0.3);
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    @include h-typo--headline-1;
    margin-right: 15px;
  }

  &__multi-action {
    margin-right: 15px;

    .p500-ui-button__text {
      min-width: 70px;
    }
  }

  &__content-left {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    width: auto;
    min-height: 43px;

    .p500-ui-circled-button .p500-ui-icon-controls-refresh {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      margin-right: 20px;
      padding-bottom: 18px;

      .p500-ui-circled-button .p500-ui-icon-controls-refresh {
        display: inherit;
      }
    }
  }

  &__content-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 18px;
    }
  }

  &__pagination {
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }
  }

  &__reset-filters {
    margin-right: 28px;
  }

  &__filters {
    margin-right: 10px;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
  }

  &__search {
    display: block;
    margin-bottom: $y-main-content-mobile-padding;
    width: 100%;
    order: 1;

    @include media-breakpoint-up(sm) {
      display: none;
      margin-bottom: 0;
      order: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 230px;
      margin-right: 10px;
      display: block;
    }
  }

  &__search-mobile {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__settings,
  &__filters {
    .is-active {
      color: $g-color-secondary;
    }
  }

  &__settings-inner {
    right: 32px;
    z-index: 15;
    display: none !important;

    &.is-open {
      display: block !important;
    }
  }

  &__add-button {
    margin-right: 12px;
  }

  &__hint-button {
    position: relative;
    top: -8px;
    left: -10px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__action {
    margin-right: 12px;
    height: 28px;
  }

  &__filter-values {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
      margin-bottom: 18px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      border-radius: $g-value-border-radius-xl;
      padding: 18px;
      background-color: rgba($g-color-primary, 0.05);
    }
  }
}
