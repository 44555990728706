&--d {
  width: 100%;

  #{$root} {
    &__groups-conditions {
      display: flex;
      flex-direction: column;

      @media (min-width: 760px) {
        align-items: flex-start;
      }
    }

    &__condition {
      display: grid;
      grid-column: auto;
      grid-row: auto;
      grid-row-gap: 7px;
    }

    &__config {
      display: inline-flex;
      align-items: center;
    }

    &__radio {
      display: inline-block;
      padding-left: 6px;
      padding-right: 6px;
    }

    &__radio-value {
      font-size: 11px;
      line-height: 16px;
      font-weight: bold;
      color: rgba($g-color-primary, 0.3);

      &:hover {
        color: rgba($g-color-primary, 0.5);
      }

      &.is-active {
        color: $g-color-primary;
      }
    }

    &__radio-line {
      height: 1px;
      width: auto;
      background-color: $g-color-gray;
      flex: 1 1 100%;

      &.-dividing {
        flex: 0 0 10px;
      }
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
    }

    &__field {
      display: inline-block;
      max-width: 100%;
      flex: 0 1 100%;

      @media (min-width: 760px) {
        width: 225px;
        flex: 0 0 225px;
        margin-right: 20px;
      }

      &--option {
        max-width: 100%;
        flex: 0 0 100%;
        @media (min-width: 760px) {
          max-width: 140px;
          flex: 0 0 140px;
        }
      }
    }

    &__delete {
      justify-content: center;
      flex: 0 0 100%;
      display: flex;
      align-items: flex-start;
      transform: translateY(4px);
      margin-bottom: 20px;
      #{$root} {
        &__remove {
          display: none;
        }
      }

      @media (min-width: 760px) {
        justify-content: initial;
        margin-top: 24px;
        flex: initial;
        margin-bottom: 0;
        #{$root} {
          &__remove-mobile {
            display: none;
          }
          &__remove {
            display: flex;
          }
        }
      }
    }
  }
}
