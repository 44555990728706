.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-placeholder {
  color: rgba($g-color-secondary, 0.3);
}

.ng-dropdown-panel {
  left: 0;
  top: 30px;
  padding-top: 4px;
  overflow: hidden;
}

.ng-select-opened {
  background-color: $g-color-light-gray;
  .ng-arrow-wrapper {
    &:before {
      content: '\e936';
    }
  }
}

.ng-arrow-wrapper {
  position: absolute;
  font-size: 6px;
  right: 0;
  top: 50%;

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 4px;
    color: $g-color-secondary;
    content: '\e933';
    font-family: 'media500';
    display: block;
  }
}

.ng-clear-wrapper {
  width: 13px !important;
  height: 20px;
  right: 18px;
  z-index: 10;

  .ng-clear {
    font-size: 20px !important;
    vertical-align: middle;
    color: rgba($g-color-red, 0.3);
  }
}

.ng-value-container {
  input {
    line-height: 18px;
    cursor: pointer !important;
  }
}

.ng-dropdown-panel-items {
  max-height: 235px !important;
  background-color: $g-color-white;
  border: 1px solid rgba($g-color-secondary, 0.2);
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  .ng-option-disabled {
    padding: 5px 11px;
    @include h-typo--body-2;
  }

  .ng-option {
    white-space: nowrap;

    > span {
      display: block;
      padding: 5px 11px;
    }

    .ng-option-label {
      display: flex;
      align-items: center;
      margin: 4px 0;
      min-width: 120px;
      @include h-typo--body-2;
      @include h-general-transition(background-color, color);

      &:hover {
        background-color: rgba($g-color-primary, 0.07);
        color: $g-color-primary;

        .text-color--gray {
          color: $g-color-primary;
        }
      }
    }

    &:after {
      display: block;
      content: '';
      width: 100%;
      border-bottom: 1px solid $g-color-gray;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.ng-option-selected {
      cursor: default;
      pointer-events: none;

      .ng-option-label {
        color: rgba($g-color-secondary, 0.5);
        pointer-events: none;
      }
    }

    &.ng-option-disabled {
      display: block;
      min-width: 120px;
    }
  }
}

// styles for multiple selector

.ng-select-multiple {
  .ng-value-container {
    .ng-value {
      display: none;
    }
  }

  .ng-has-value .ng-placeholder {
    display: block;
  }
}

// styles for tags selector

.ng-select-taggable {
  &.ng-select-filtered {
    .ng-placeholder {
      display: none;
    }
  }
  .ng-arrow-wrapper {
    display: none;
  }

  .ng-clear-wrapper {
    margin-right: -21px;
  }
}
