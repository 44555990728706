.main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: $y-main-content-mobile-padding $x-main-content-mobile-padding;

  @include media-breakpoint-up(md) {
    padding: $x-main-content-table-padding;
  }

  @include media-breakpoint-up(lg) {
    padding: $y-main-content-padding $x-main-content-padding;
  }

  &__content {
    flex: 1 1 100%;
  }

  &__copyright {
    display: flex;
    margin-top: auto;
    margin-bottom: -16px;
    padding-top: 16px;
    padding-right: 10px;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    z-index: $z-copyright;

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }
}
