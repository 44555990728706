.l-base {
  $root: &;
  display: block;

  &__inner {
    display: grid;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 64px calc(100% - 64px);
    grid-template-columns: 253px calc(100% - 253px);
    grid-template-areas:
      'top-bar top-bar'
      'body body';

    @include media-breakpoint-up(lg) {
      grid-template-columns: 70px 253px calc(100% - 253px - 70px);
      grid-template-areas:
        'nav-panel sidebar body'
        'nav-panel sidebar body';
    }
  }

  &__top-bar {
    grid-area: top-bar;
    grid-row: 1;
    grid-column: 1 / 3;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-top-bar;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__body {
    grid-area: body;
    position: relative;
    height: 100%;
    left: 0;
    transition: all 0.5s ease;
    background-color: $g-color-white;
    display: block;
    z-index: 11;
  }

  &__sidebar {
    grid-area: sidebar;
    display: none;
    overflow: visible;
    z-index: $z-sidebar;
    background-color: $g-color-pale-blue;
    box-shadow: inset -5px 0px 40px rgba($g-color-secondary, 0.05);

    @include media-breakpoint-up(sm) {
      position: relative;
      top: 0;
      width: 253px;
      bottom: 0;
      border-right: 1px solid rgba($g-color-primary, 0.1);
    }

    @include media-breakpoint-up(lg) {
      top: 0;
      display: block;
    }
  }

  &.is-overlay {
    .c-top-bar {
      &__toggle {
        &--rect {
          width: 12px;
        }

        .top {
          transform-origin: 1px 2px;
          transform: rotate(45deg) translateY(0em) translateX(0em);
          -webkit-transform: rotate(45deg) translateY(0em) translateX(0em);
        }

        .bottom {
          transform-origin: 1px 0px;
          transform: rotate(-45deg) translateY(0em) translateX(0em);
          -webkit-transform: rotate(-45deg) translateY(0em) translateX(0em);
        }
      }
    }

    #{$root} {
      &__inner {
        grid-template-areas:
          'top-bar top-bar'
          'sidebar sidebar';

        @include media-breakpoint-up(sm) {
          grid-template-areas:
            'top-bar top-bar'
            'sidebar body';
        }

        @include media-breakpoint-up(lg) {
          grid-template-areas:
            'nav-panel sidebar body'
            'nav-panel sidebar body';
        }
      }

      &__body {
        display: none;
        position: fixed;
        overflow: hidden;
        pointer-events: none;
        border-radius: $g-value-border-radius-xl;
        opacity: 0.5;
        @include media-breakpoint-up(sm) {
          display: block;
          top: $height-top-bar;
          left: 253px;
          right: -253px;
        }

        @include media-breakpoint-up(lg) {
          left: 0;
          top: 0;
          display: block;
          position: relative;
          pointer-events: initial;
          opacity: 1;
        }
      }

      &__sidebar {
        display: block;
      }
    }
  }

  &.is-open-modal {
    #{$root} {
      &__inner {
        position: fixed;
        top: 0;
        overflow: hidden;
      }
    }
  }

  &.is-tour-opened {
    #{$root} {
      @include media-breakpoint-up(xxl) {
        &__inner {
          grid-template-rows: 64px calc(100% - 64px) 100%;
          grid-template-columns: 70px 253px calc(100% - 253px - 360px - 70px) 360px;
          grid-template-areas:
            'nav-panel sidebar body onboarding'
            'nav-panel sidebar body onboarding';
        }

        &__onboarding {
          display: block;
        }
      }
    }

    .c-filters {
      right: 386px !important;
    }

    .c-settings {
      right: 386px !important;
    }
  }

  &.is-collapsed-sidebar {
    #{$root} {
      &__inner {
        @include media-breakpoint-up(lg) {
          grid-template-columns: 70px 70px calc(100% - 70px - 70px);
        }
      }

      &__sidebar {
        @include media-breakpoint-up(lg) {
          min-width: 0;
          width: 100%;
          .c-sidebar {
            min-width: 0;
          }
        }
      }
    }
  }

  &__onboarding {
    grid-area: onboarding;
    display: none;
  }

  &__nav-panel {
    grid-area: nav-panel;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
