.c-tree-help {
  position: relative;

  display: flex;

  background-color: $g-color-sidebar-bg;
  background-image: url('../../../../assets/new-img/bg/rotation-help-vector-1.svg'),
    url('../../../../assets/new-img/bg/rotation-help-vector-2.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: 84% 0, 40% bottom;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    right: 0;

    width: 111px;
    height: 90px;

    background-color: $g-color-pattern-blue;
    border-top-left-radius: 100%;
  }

  &__content {
    padding: 30px 0 30px 55px;
  }

  &__title {
    min-width: 300px;
    position: relative;

    margin-bottom: 15px;

    @include h-typo--headline-2;
    font-weight: 700;

    &::before {
      content: '';

      position: absolute;
      bottom: 0;
      left: -35px;

      width: 15px;
      height: 15px;

      background-color: $g-color-pattern-red;
      border-bottom-right-radius: 100%;
    }
  }

  &__text {
    margin-bottom: 15px;

    @include h-typo--body-2;
    color: rgba($color: $g-color-secondary, $alpha: 0.5);
  }

  &__link {
    position: relative;

    padding-left: 30px;

    @include h-typo--body-2;
    font-weight: 700;

    &.-wiki {
      margin-right: 45px;

      &::before {
        content: '\e963';

        position: absolute;
        top: 0;
        left: 0;

        font-family: 'media500';
        font-size: 18px;
      }
    }

    &.-help {
      &::before {
        content: '\e95f';

        position: absolute;
        top: 0;
        left: 0;

        font-family: 'media500';
        font-size: 18px;
      }
    }

    &:not(:last-of-type):after {
      content: '';

      position: absolute;
      top: calc(50% - 3px / 2);
      right: -20px;

      width: 3px;
      height: 3px;

      background-color: rgba($color: $g-color-secondary, $alpha: 0.2);
      border-radius: 50%;
    }
  }

  &__bg {
    width: 100%;
    height: auto;
    background-image: url('../../../../assets/new-img/bg/bg-rotation-help.png');
    background-repeat: no-repeat;
    background-position: 40% bottom;
    background-size: contain;
    z-index: 5;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;

    display: block;

    font-size: 14px;
    line-height: 1em;

    cursor: pointer;
    z-index: 7;
    opacity: 0.3;
    @include h-general-transition(opacity);

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 1500px) {
  .c-tree-help {
    background-image: none;

    &__bg {
      background: none;
    }
  }
}

@media (max-width: 1100px) {
  .c-tree-help {
    &::after {
      display: none;
    }

    &__title {
      min-width: 250px;
    }
  }
}

@media (max-width: 1024px) {
  .c-tree-help {
    &__text {
      background-image: none;
      display: none;
    }

    &__bg {
      background: none;
    }

    &::after {
      display: none;
    }

    &__content {
      padding: 12px 20px;
    }

    &__title {
      &::before,
      &:after {
        display: none;
      }
    }
  }
}
