.c-tp-depend-field {
  &__wrapper {
    display: flex;
    gap: 10px;
  }

  &__item {
    width: 50%;
  }
}
