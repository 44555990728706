&--marketplace {
  #{$root} {
    &__content {
      display: flex;
      flex-direction: column;

      padding: 0 15px;
      margin-bottom: 100px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        padding: 0 38px 38px;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    &__preview {
      display: flex;
      align-items: center;
      flex: 0 0 350px;

      padding: 18px;
      margin-bottom: 20px;

      background-color: rgba($g-color-gray, 0.4);
      border-radius: $g-value-border-radius-xl;

      @include media-breakpoint-up(lg) {
        margin-right: 14px;
        margin-bottom: 0;
      }

      img {
        width: 100%;
        border-radius: $g-value-border-radius;
      }

      &-link {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 8px;

        background-color: $g-color-light-blue;
        border-radius: $g-value-border-radius;

        &-wrapper {
          display: flex;
        }

        &-key {
          text-transform: uppercase;
          font-size: 13px;
          color: rgba($g-color-secondary, 0.5);
          margin-right: 8px;
        }

        &-value {
          color: $g-color-primary;
          font-size: 13px;
        }

        &-icon {
          margin-top: 4px;

          span {
            font-size: 17px;
            color: $g-color-primary;
            margin-top: -2px;
          }

          &:hover {
            span {
              color: $g-color-primary-hover;
            }
          }
        }
      }
    }

    &__widgets {
      display: flex;
      flex-direction: column;
    }

    &__widget {
      padding: 0;
      flex-wrap: wrap;

      ul {
        background-color: #ffffff;
        padding: 18px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
      }
    }

    &__language-dropdown {
      display: flex;
      align-items: center;

      .p500-ui-form-field__input {
        padding-left: 0;
        padding-right: 70px;
        border: none;
        border-radius: 0;
      }

      .ng-select-opened {
        .ng-arrow-wrapper {
          &:before {
            color: $g-color-primary;
            font-family: 'platform500';
            content: '\e906';
          }
        }
      }

      .ng-arrow-wrapper {
        margin-left: 10px;
        &:before {
          color: $g-color-primary;
          font-size: 10px;
          font-family: 'platform500';
          content: '\e903';
        }
      }
    }

    &__rules-tabs {
      display: flex;
      margin-top: 22px;
      margin-bottom: 10px;
    }

    &__rules-tab {
      flex: 1;
      padding: 8px;
      margin-right: 10px;
      text-align: center;
      background-color: rgba($g-color-secondary, 0.03);
      border: 1px solid rgba($g-color-secondary, 0.1);
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 13px;
      font-weight: 800;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $g-color-primary;
        border-color: rgba($g-color-primary, 0.3);
      }

      &.-active {
        color: $g-color-primary;
        background-color: $g-color-white;
        border-color: $g-color-primary;
        box-shadow: 0px 5px 20px rgba($g-color-secondary, 0.08);
        pointer-events: none;
      }
    }

    &__form-inner {
      background-color: rgba($g-color-secondary, 0.03);
      padding: 12px;
      border-radius: $g-value-border-radius;
    }

    &__copy-link {
      width: 34px;
      height: 34px;
      margin-top: -24px;
      flex-shrink: 0;
    }

    &__generated-link {
      display: flex;
      flex-direction: column;
      padding: 14px;
      background-color: rgba($g-color-primary, 0.05);
      border-radius: $g-value-border-radius-xl;
    }

    &__generated-link .p500-ui-cube-preloader {
      margin: 0 !important;
    }

    &__link-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__link {
      font-size: 13px;
      font-weight: bold;
      color: $g-color-primary;
    }

    &__link-subtitle {
      font-size: 12px;
      color: rgba($g-color-secondary, 0.5);
    }

    &__select {
      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      &-label {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;

        font-size: 12px;
      }

      &-status {
        margin-left: auto;
        display: flex;
        align-items: center;

        &-dot {
          display: block;
          width: 6px;
          height: 6px;

          border-radius: 100%;
          margin-right: 5px;
        }
      }

      &-tooltip {
        margin-left: 5px;
        border-radius: 100px;
        background-color: $g-color-gray;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 16px;
        height: 16px;

        &:hover {
          background-color: $g-color-secondary;
          border-color: $g-color-secondary;

          span {
            color: $g-color-white;
          }
        }
      }
    }

    &__language {
      margin-top: 22px;
    }

    &__warning {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      padding: 8px;
      margin-top: 5px;
      border-radius: $g-value-border-radius;

      background-color: rgba($g-color-yellow, 0.1);

      &-icon {
        margin-right: 10px;
        span {
          color: $g-color-yellow;
        }
      }
    }
  }
}
