.c-today-data {
  background-color: $g-color-white;
  box-shadow: 0px 10px 20px rgba($g-color-secondary, 0.05);
  border-radius: 8px;

  &__head {
    @include h-typo--additional;
    font-weight: 800;
    padding: 8px 20px;
    text-transform: uppercase;
    color: rgba($g-color-secondary, 0.3);
    border-bottom: 1px solid rgba($g-color-secondary, 0.1);
  }

  &__body {
    @include h-typo--body-2;
    padding: 12px 20px;
  }

  &__item {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__value {
    @include h-typo--body-1;
    margin-right: 4px;
  }

  &__key {
    color: rgba($g-color-secondary, 0.5);
  }

  &__empty {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
      color: $g-color-red;
      font-size: 14px;
    }
  }
}
