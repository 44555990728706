&--info {
  &--blue {
    background-color: rgba($g-color-light-blue, 0.8);
    padding: 0 18px 18px;

    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }

  &--white {
    padding: 0 18px 24px;

    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }

  &--footer {
    @include h-typo--body-2;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;

    .key,
    .value {
      margin-right: 8px;
      margin-top: 12px;
    }
  }

  @include h-typo--body-2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  .title {
    @include h-typo--headline-2;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
  }

  ul {
    width: 100%;
    margin: 0;
    margin-right: 40px;
    margin-top: 18px;
    padding: 0;
    list-style: none;

    &:last-child {
      margin-right: 0;
    }

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .key {
    color: rgba($g-color-secondary, 0.5);
    margin-right: 8px;
  }

  .value {
    display: block;
    margin-right: 10px;

    img {
      max-width: 100%;
    }

    span.c-field-value {
      span.c-field-value__text {
        word-break: break-all;
      }
    }

    &__link {
      margin-left: 5px;
    }

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  &.-border-top {
    padding-top: 12px;

    border-top: 1px solid #e6e6e6;
  }

  &.-border-bottom-radius {
    border-bottom-left-radius: $g-value-border-radius-xl;
    border-bottom-right-radius: $g-value-border-radius-xl;
  }
}
