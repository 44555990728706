&--upload {
  /*
  <label class="c-form-field__inner">
    <span class="c-form-field__body">
      <span class="c-form-field__icon">
        <span class="c-icon c-icon-other-upload"></span>
      </span>
      <span class="c-form-field__info">Please <span>Upload File</span></span>
      <span class="c-form-field__info">{{ info }}</span>
      <input autocomplete="off" class="c-form-field__input" clearable="true" type="file" accept="text/csv-schema" />
    </span>
  </label>
  */

  .c-form-field {
    &__inner {
      cursor: pointer;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 126px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 28px 60px;
      border: 1px dashed rgba(#000e4b, 0.3);
      background: #eef5ff;
      border-radius: $g-value-border-radius-xl;
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      color: #0f5ef7;
      font-size: 32px;
      margin-bottom: 10px;
    }
    &__info {
    }

    &__info-info {
      //styleName: XS - L / Body 3 / Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(#000e4b, 0.5);
    }

    &__input {
      position: absolute;
      z-index: -1;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }
  }
}
