.l-print {
  width: 100%;
  $root: &;

  &--bg {
    background-color: $g-color-white;
    border-radius: $g-value-border-radius-xl;
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 22px;
    border-bottom: 1px solid $g-color-gray;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  &__subtitle {
    margin-top: 5px;
    color: rgba($g-color-secondary, 0.5);
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
  }

  &__inner {
    font-size: 10px;

    .tag {
      border-radius: 5px;
      background-color: $g-color-light-blue;
      font-weight: bold;
      padding: 6px;
      display: inline-block;
      color: $g-color-secondary;

      .icon {
        margin-right: 6px;
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      margin-left: 16px;
      white-space: pre-wrap;
    }

    ul,
    li,
    p,
    br,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote {
      margin-bottom: 6px;
    }

    h1 {
      font-size: 12px;
      font-weight: bold;
    }

    .total {
      min-width: 180px;
      border: 1px solid $g-color-secondary;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: bold;
      overflow: hidden;
      line-height: 1em;

      .title {
        background-color: $g-color-secondary;
        color: $g-color-white;
        padding: 10px;
        flex: none;
      }

      .value {
        color: $g-color-secondary;
        padding: 10px;
        margin-left: auto;
      }
    }
  }

  &__section {
    padding: 22px 0;
    border-bottom: 1px solid $g-color-gray;

    &--p-0 {
      padding: 0;

      #{$root}__col {
        padding: 22px 0;
      }
    }

    &--last {
      border-bottom: 0;
    }
  }

  &__icon {
    margin-right: 14px;

    &--alt {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__row {
    display: flex;
    justify-content: stretch;
  }

  &__line {
    width: 1px;
    background-color: $g-color-gray;
    content: '';
    display: block;
    flex: none;
    margin: 0 22px;
  }

  &__col {
    flex: 1;
  }
}
