.c-tabs {
  $root: &;

  display: inline-block;

  .p500-ui-text-button--secondary {
    color: unset;
  }

  &__item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &-collapsed {
      display: none;

      @include media-breakpoint-up(xxl) {
        display: inline;
      }
    }
  }

  &__wrapper {
    white-space: nowrap;
  }

  &__hr {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: rgba($g-color-primary, 0.1);
    z-index: 1;
  }

  .collapsed-intervals__button {
    display: flex;

    @include media-breakpoint-up(xxl) {
      display: none;
    }
  }

  .collapsed-intervals__content {
    .small-tab {
      width: 100%;
      padding: 5px;
      border-bottom: 1px solid rgba($g-color-gray, 0.5);
      border-radius: 4px;

      &:hover {
        background-color: rgba($g-color-primary, 0.1);
      }

      &.is-active {
        .p500-ui-button__text {
          color: $g-color-primary;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .p500-ui-button.c-tabs__item-collapsed {
      display: none;
    }
  }
  .collapsed-intervals__content {
    display: flex;
    flex-direction: column;
  }
}
