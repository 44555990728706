&--hint {
  visibility: visible;
  position: initial;
  @include h-general-transition;
  @include fadeIn($duration: $g-value-animation-duration, $function: $g-value-animation-function);

  #{$root} {
    &__inner {
      background: $g-color-secondary;
      color: $g-color-white;
      border-radius: 3px;
      font-size: 11px;
      line-height: 16px;
      padding: 4px;
      position: relative;

      &:after {
        top: -3px;
        left: 12px;
        transform: translateX(-50%);
        content: '';
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3px 3px 3px;
        border-color: transparent transparent $g-color-secondary transparent;
      }
    }

    &__dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 1px 4px;
      background-color: $g-color-gray;
    }
  }
}
