&--custom-range {
  #{$root} {
    &__block {
      &:hover {
        .c-form-field__label {
          opacity: 1;
        }
      }
    }
  }
}
