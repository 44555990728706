.c-empty {
  $root: &;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;

  #{$root} {
    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      min-height: 125px;
      width: 225px;
    }

    &__icon {
      margin-bottom: 20px;
    }

    &__desc {
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      white-space: normal;
    }
  }
}
