.p-marketplace {
  &__d-xl {
    display: none;

    @media (min-width: 1660px) {
      display: block;
    }
  }

  &__d-lg {
    display: none;

    @media (min-width: 1350px) {
      display: block;
    }

    @media (min-width: 1660px) {
      display: none;
    }
  }

  &__d-md {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1350px) {
      display: none;
    }
  }

  &__d-sm {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &__card {
    margin-bottom: 1.7rem;
  }

  &__empty-block {
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
  }

  &__small-dot-separator {
    display: none;
    width: 3px;
    height: 3px;

    @media (min-width: 1110px) {
      display: block;
    }
  }

  &__total-funnels {
    display: none;
    font-size: 12px;
    color: rgba($g-color-secondary, 0.4);
    margin-top: 2px;
    margin-right: 5px;

    @media (min-width: 1110px) {
      display: block;
    }
  }

  &__refresh {
    font-size: 13px;
    font-weight: bold;
  }

  &__load-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__settings {
    margin-left: 10px;

    @include media-breakpoint-up(xl) {
      margin-left: 16px;
    }
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($g-color-white, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  &__filters {
    margin-right: 0;
    margin-left: 0;

    @include media-breakpoint-up(xl) {
      margin-right: 15px;
    }
  }
}
