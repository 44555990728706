&--marketplace {
  #{$root} {
    &__inner {
      display: flex;
      flex-direction: column;
      padding: 4px;
      border: 1px solid rgba($g-color-secondary, 0.1);
      border-radius: $g-value-border-radius-xl;
    }

    &__image-container {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__favorite {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $g-color-gray;
      border: 1px solid $g-color-white;
      cursor: pointer;

      button {
        margin-left: 8px;
        font-size: 15px;
        color: rgba($g-color-black, 0.4);
      }

      img {
        width: 12px;
        height: 12px;
      }

      &:hover {
        border-color: $g-color-primary;

        button {
          color: $g-color-primary;
        }
      }

      &.--is-favorite {
        button {
          color: $g-color-primary;
        }
      }
    }

    &__data {
      padding: 14px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
    }

    &__title,
    &__category {
      @include h-typo--body-1;
      font-weight: bold;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      margin-bottom: 12px;
    }

    &__title {
      color: $g-color-secondary;
    }

    &__category {
      display: flex;

      &-count {
        margin-left: 5px;
        color: $g-color-secondary;
        font-weight: bold;
        padding: 3px 4px;
        background-color: rgba($g-color-secondary, 0.1);

        &:hover {
          background-color: $g-color-secondary;
          color: $g-color-white;
        }
      }
    }

    &__description {
      font-size: 12px;
      line-height: 18px;
      color: $g-color-secondary;
      word-wrap: break-word;

      opacity: 0.5;
      margin-bottom: 10px;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      margin-bottom: 16px;

      border-radius: 5px;
      margin-bottom: 10px;
      background-color: rgba($g-color-primary, 0.05);

      .separator {
        margin: 0 10px;
        width: 2px;
        height: 16px;
        border-radius: 1px;
        background-color: rgba($g-color-primary, 0.1);
      }

      button {
        font-size: 14px;
      }

      button > span > span {
        font-size: 12px;
      }

      @include media-breakpoint-up(sm) {
        button {
          font-size: 18px;
        }

        button > span > span {
          font-size: 13px;
        }
      }
    }

    // &__statistics {
    //   display: flex;
    //   @include h-typo--additional;
    //   font-weight: bold;
    //   white-space: nowrap;
    //   padding-bottom: 10px;

    //   border-bottom: 1px solid rgba($g-color-secondary, 0.1);

    //   &-value:first-child {
    //     margin-right: 4px;
    //   }

    //   &-value,
    //   &-text {
    //     font-size: 13px;
    //     color: $g-color-secondary;
    //   }

    //   &-text {
    //     margin-left: 3px;
    //     opacity: 0.5;
    //   }
    // }

    &__languages {
      @include h-typo--body-2;
      margin-top: 10px;
    }

    &__more {
      font-weight: bold;
      background-color: rgba($g-color-primary, 0.1);
      color: $g-color-secondary;
      cursor: pointer;

      &:hover {
        background-color: $g-color-secondary;
        color: $g-color-white;
      }
    }
  }
}
