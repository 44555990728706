.c-selector {
  $root: &;

  &__inner {
    display: flex;
    flex-direction: column;
    background-color: $g-color-white;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    overflow-y: auto;
    max-height: 230px;
    box-shadow: 0px 10px 60px rgba(0, 14, 75, 0.1);

    @include media-breakpoint-up(lg) {
      border-radius: 0px 0px 20px 0px !important;
      border: none !important;
      box-shadow: 10px 30px 60px rgba(0, 14, 75, 0.15) !important;
      max-height: 260px;

      @include fadeInDown(1, 0.5s);
    }
  }

  &__el-icon {
    position: relative;
    object-fit: contain;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  &__el-toggle {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    pointer-events: auto;
    flex-shrink: 0;
    background-color: rgba($g-color-primary, 0.1);
    display: none;
    justify-content: center;
    align-items: center;

    &::after {
      content: '\e908';
      font-size: 7px;
      line-height: 1;
      color: $g-color-primary;
      width: 12px;
      height: 8px;
      font-family: 'media500';
    }
  }

  &__el-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__el-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: $g-color-black;
  }

  &__el-subtitle {
    @include h-typo--body-3;
    color: rgba($g-color-black, 0.5);
    text-transform: capitalize;
    font-weight: bold;
  }

  &__el {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:not(:first-child) {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &:first-child {
      pointer-events: none;

      #{$root} {
        &__el-icon {
          display: none;
          @include media-breakpoint-up(lg) {
            display: initial;
          }
        }

        &__el-toggle {
          display: flex;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
    }

    &:first-child:last-child {
      #{$root} {
        &__el-icon {
          display: block;
        }

        &__el-toggle {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba($g-color-gray, 0.5);
    }

    &.-open {
      #{$root}__el-toggle::after {
        transform: scale(1, -1);
      }

      ~ #{$root}__el {
        display: flex;
        display: -webkit-box;
      }
    }

    &.-active {
      display: flex;
      display: -webkit-box;
      justify-content: space-between;
      align-items: center;

      &:not(.-open) {
        border-bottom: none;
      }

      @include media-breakpoint-up(lg) {
        background-color: rgba($g-color-light-blue, 0.7);
      }

      #{$root} {
        &__el-title {
          position: relative;
        }
      }

      &::after {
        position: absolute;
        content: '';
        background: $g-color-primary;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 16px;
        right: 10px;
      }
    }

    &.-active,
    &:hover {
      &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        background-color: rgba($g-color-light-blue, 0.7);
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 14px;

      &.-active {
        &:before {
          display: none;
        }

        &::after {
          top: 19px;
          right: 13px;
        }
      }

      &:hover {
        &:before {
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
        }
      }
    }
  }
}
