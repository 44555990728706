.c-error-notification {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 23px;
  padding: 10px 15px 10px 40px;

  @include h-typo--body-2;
  color: $g-color-secondary;

  background-color: rgba($color: $g-color-red, $alpha: 0.08);
  border-radius: 5px;

  &::before {
    content: '\e919';

    position: absolute;
    top: 10px;
    left: 14px;

    font-family: 'media500';
    font-size: 18px;

    color: $g-color-red;
  }

  &__close {
    display: block;

    font-size: 14px;
    line-height: 1em;

    opacity: 0.3;
    @include h-general-transition(opacity);
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
