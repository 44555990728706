.c-tree-header {
  $root: &;

  #{$root} {
    &__title {
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 28px;
      }
    }

    &__button {
      margin-left: 12px;
    }

    &__hint-button {
      position: relative;
      top: -10px;
      left: 2px;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    &__quick-search {
      margin-top: 30px;
      margin-bottom: 14px;
      border-radius: 10px;
      display: flex;
      .c-form-field {
        width: 100%;

        &__input {
          background-color: $g-color-white;
          padding: 9px 34px;
        }
      }
    }

    &__filter {
      margin-left: 8px;
    }

    &__filters-button {
      position: relative;
      display: flex;
      padding: 10px;
      border-radius: $g-value-border-radius-xl;
      background-color: rgba($g-color-primary, 0.1);

      .is-active {
        color: $g-color-secondary;
      }
    }

    &__filters-count-mark {
      position: absolute;
      display: block;
      top: -7px;
      right: 43px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $g-color-black;

      &-value {
        position: relative;
        top: -4px;
        left: 5px;
        color: $g-color-white;
        font-size: 10px;
      }
    }

    &__filters-title {
      font-size: 15px;
      font-weight: bold;
      color: $g-color-primary;

      margin-top: 2px;
      margin-left: 8px;

      .is-active {
        color: $g-color-secondary;
      }
    }

    &__root {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: $g-color-pale-blue;
      padding: 12px;
      border-radius: $g-value-border-radius-xl;

      button {
        background-color: $g-color-white !important;
        span {
          color: $g-color-black !important;
        }
        &:hover,
        &:active,
        &:focus {
          background-color: $g-color-black !important;
          span {
            color: $g-color-white !important;
          }
        }
      }

      .c-tree-header__reset-btn {
        margin-left: 15px;
      }
    }

    &__root-icon {
      margin-right: 6px;
      display: inline-flex;
    }

    &__root-title {
      font-size: 13px;
      line-height: 20px;
      margin-right: auto;
    }

    &__reset-btn {
      @include media-breakpoint-up(xs) {
        display: none;
      }

      @include media-breakpoint-up(sm) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        display: block;
      }
    }

    &__expand-btn {
      @include media-breakpoint-up(xs) {
        margin-left: 5px;
        margin-right: 5px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 18px;
      }
    }
  }
}
