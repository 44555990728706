&__table {
  background-color: $g-color-white;
  border-radius: $g-value-border-radius-xl;
  position: relative;

  &.-body {
    overflow: auto;
  }

  #{$root} {
    &__table-body {
      position: relative;
      border: 1px solid $g-color-gray;
      padding-left: 0;
      padding-right: 0;
      border-radius: 8px;
      overflow: inherit;
    }
  }

  .-empty {
    height: 300px;
  }

  table {
    min-width: 988px;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    #{$root} {
      &__empty {
        color: rgba($g-color-secondary, 0.5);
      }

      &__table-cell {
        white-space: nowrap;
        border-left: 1px solid rgba($g-color-gray, 0.6);

        &:first-child {
          border-left: none;
        }

        &.-header {
          border-left: 1px solid rgba($g-color-gray, 0.6);
          background: $g-color-light-blue;
          color: $g-color-secondary;
          font-weight: bold;
          font-size: 13px;

          &:first-child {
            border-left: none;
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }

        &.-textRight {
          text-align: right;
        }
      }
    }

    &.-adaptive-width {
      min-width: initial;
    }

    thead {
      th {
        font-size: 13px;
        white-space: nowrap;
        font-weight: bold;
        color: rgba($g-color-secondary, 0.5);
        border-bottom: 1px solid $g-color-gray;
        padding: 8px 10px;

        .order-icon {
          font-size: 8px;
          margin-left: 4px;
          line-height: 1;
          display: none;
        }

        &.order {
          position: relative;
          display: flex;
          align-items: baseline;
          color: $g-color-secondary;

          .order-icon {
            display: block;
          }
        }
      }
    }

    tr {
      &:nth-child(even) {
        background-color: rgba($g-color-secondary, 0.02);
      }

      &:hover {
        background-color: #e2ecfe;
      }

      td {
        @include h-typo--body-2;
        border-bottom: 1px solid rgba($g-color-gray, 0.4);
        padding: 8px 10px;

        &.text-top {
          vertical-align: top;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
