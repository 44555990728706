.c-mn-settings-workflow {
  $root: &;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 445px;
  height: 34px;
  border-radius: 50px;
  padding: 0;

  background-color: $g-color-light-gray-2;

  &__checkboxes {
    display: flex;
    justify-content: space-around;

    width: auto;
    align-items: center;
    margin-left: 10px;

    &-item {
      margin-left: 10px;
    }
  }

  &__separator {
    width: 1px;
    height: 26px;
    background-color: $g-color-gray;
    margin: 15px 0 15px 0;
  }

  &__btns-block {
    display: flex;
    width: auto;
    justify-content: space-around;
    align-items: center;
  }

  &__btn {
    padding-right: 8px;
  }
}
.mobile-template {
  .c-mn-settings-workflow {
    $root: &;
    width: auto;

    background-color: transparent;
  }
}
