.c-repush-settings {
  display: flex;

  &__sidebar {
    background-color: $g-color-light-blue;
    border-radius: 8px;
    text-align: right;

    &-head {
      @include h-typo--body-3;
      white-space: nowrap;
      font-weight: bold;
      width: 100%;
      padding: 10px 14px;
      border-bottom: 1px solid rgba($g-color-secondary, 0.1);
    }

    &-body {
      padding: 15px 14px;
    }
  }

  &__field-name {
    white-space: nowrap;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__body {
    padding-top: 10px;
    flex-basis: 100%;
    margin-left: 20px;

    &-head {
      @include h-typo--body-3;
      font-weight: bold;
      margin-bottom: 21px;

      &:after {
        margin-left: 4px;
        content: '*';
        color: $g-color-red;
      }
    }
  }

  &__select {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__unique-title {
    font-weight: bold;
    font-size: 15px;
  }
}
