.c-rm-header {
  $root: &;

  #{$root} {
    &__inner {
      box-shadow: 0px 0px 20px rgba($g-color-secondary, 0.05);
    }

    &__body {
      background: rgba($g-color-primary, 0.1);
      padding: 22px 32px;
    }

    &__footer {
      background-color: $g-color-white;
      padding: 12px 32px;
    }

    &__row {
      display: flex;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
      display: inline-flex;

      img {
        width: 20px;
      }
    }

    &__back {
      font-size: 10px;
      cursor: pointer;
      padding-right: 10px;
      padding-left: 10px;
      margin-left: -10px;
      color: $g-color-primary;
      display: inline-flex;
      align-self: stretch;
      align-items: center;
    }

    &__info {
      margin-left: auto;
      display: inline-flex;
    }

    &__row {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
    }

    &__right-section {
      margin-left: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__desc {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
      line-height: 16px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    &__actions {
      flex-shrink: 0;
      margin-right: 10px;
      display: inline-flex;
    }

    &__action {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__search {
      margin-left: auto;
    }
  }
}
