.c-line {
  $root: &;
  position: relative;

  &__line {
    background-color: $g-color-gray;
    border-radius: 1px;
  }

  &__label {
    font-size: 15px;
    font-weight: bold;
  }

  @import 'c-line--horizontal';
  @import 'c-line--split-label';
}
