.c-modal {
  $root: &;
  display: inline-block;
  text-align: left;
  width: 100%;

  &__inner {
    position: relative;
    height: 100%;
  }

  @import 'c-modal--tool';
  @import 'c-modal--help';
  @import 'c-modal--deal-info';
  @import 'c-modal--new-design';
  @import 'c-modal--changelog';
  @import 'c-modal--funnel-import';
  @import 'c-modal--image';
  @import 'c-modal--term';
  @import 'c-modal--report-builder-settings';
  @import 'c-modal--story';
  @import 'c-modal--2fa';
  @import 'c-modal--tree-rule';
  @import 'c-modal--csv-unsecure';
  @import 'c-modal--info';
  @import 'c-modal--marketplace';
}
