&--tree-rule {
  #{$root} {
    &__body {
      padding-bottom: 30px;
    }

    &__card {
      display: flex;
      align-items: flex-start;
      padding: 12px;
      border-radius: $g-value-border-radius-xl;
      cursor: pointer;

      .icon {
        width: 28px;
        height: auto;
        margin-right: 10px;
      }

      .title {
        @include h-typo--body-1;
        font-weight: bold;
        margin-bottom: 2px;

        &.-small {
          @include h-typo--body-2;
          font-weight: bold;
        }
      }

      .description {
        @include h-typo--body-3;
        color: rgba($g-color-secondary, 0.6);
      }

      .info {
        flex: 1;
      }

      &:hover {
        background-color: $g-color-light-blue;

        .title {
          color: $g-color-primary;
        }
      }
    }

    &__types-grid {
      display: flex;
      flex-wrap: wrap;
      margin: -10px 0;

      #{$root}__type-container {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($g-color-gray, 0.4);
        flex-basis: 50%;
        position: relative;

        &:nth-child(2n + 1) {
          padding-right: 22px;
        }

        &:nth-child(2n) {
          padding-left: 22px;

          &:before {
            content: '';
            display: block;
            height: calc(100% - 20px);
            width: 1px;
            background-color: rgba($g-color-gray, 0.4);
            position: absolute;
            left: 0px;
            top: 10px;
          }
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
          border-bottom-width: 0;
        }
      }

      &.-selected {
        #{$root}__type-container {
          flex-basis: unset;
          flex: 1;
        }

        #{$root}__card {
          padding: 0;
          pointer-events: none;
        }
      }

      &.-no-lines {
        margin: -8px 0;
        @include h-typo--body-2;

        #{$root}__type-container {
          border-bottom-width: 0;
          padding-top: 8px;
          padding-bottom: 8px;

          &:nth-child(2n + 1) {
            padding-right: 10px;
          }

          &:nth-child(2n) {
            padding-left: 10px;
          }

          &:before {
            display: none;
          }
        }
      }
    }

    &__divider {
      border-top: 1px solid $g-color-gray;
      position: relative;

      .text {
        font-size: 11px;
        line-height: 13px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 0 8px;
        background-color: $g-color-white;
        color: rgba($g-color-secondary, 0.3);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -8px;
      }

      &.-semi-transparent {
        opacity: 0.4;
      }
    }

    &__prev {
      padding: 4px 0;
    }

    &__toggle-action {
      display: flex;
      align-items: center;
      margin-right: auto;
      font-weight: bold;
    }

    &__rules-tabs {
      display: flex;
      padding: 0 8px;
      margin-bottom: 8px;
    }

    &__rules-tab {
      flex: 1;
      padding: 8px;
      margin-right: 10px;
      text-align: center;
      background-color: rgba($g-color-secondary, 0.03);
      border: 1px solid rgba($g-color-secondary, 0.1);
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 13px;
      font-weight: 800;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $g-color-primary;
        border-color: rgba($g-color-primary, 0.3);
      }

      &.-active {
        color: $g-color-primary;
        background-color: $g-color-white;
        border-color: $g-color-primary;
        box-shadow: 0px 5px 20px rgba($g-color-secondary, 0.08);
        pointer-events: none;
      }
    }
  }
}
