ngx-guided-tour .guided-tour-spotlight-overlay {
  border-radius: 5px;
  box-shadow: none !important;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid $g-color-purple;
    border-radius: 50%;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.2), 0 0 1.5rem rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
  }
}

ngx-guided-tour .guided-tour-user-input-mask {
  width: calc(100% - 360px) !important;
}

ngx-guided-tour .tour-step {
  display: block;
  opacity: 0;
}

ngx-guided-tour .tour-step h3.tour-title,
ngx-guided-tour .tour-step .tour-content,
ngx-guided-tour .tour-step .tour-buttons button.skip-button.link-button,
ngx-guided-tour .tour-step .tour-buttons button.link-button,
ngx-guided-tour .tour-arrow {
  display: none;
}

ngx-guided-tour .tour-step .tour-block {
  padding: 0 !important;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  box-shadow: none;
}

ngx-guided-tour .tour-step .tour-buttons .next-button {
  display: inline-block;
  @include h-typo--body-2;
  font-size: 13px !important;
  color: $g-color-white;
  background-color: $g-color-purple;
  border-radius: 18px !important;
  border: 2px solid $g-color-white !important;
  padding: 6px 36px 6px 12px !important;
  position: relative;

  &:hover {
    background-color: $g-color-purple;
  }

  &:after {
    content: '\e90a';
    font-family: 'media500';
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba($g-color-white, 0.1);
    border-radius: 12px;
    color: $g-color-white;
  }
}

ngx-guided-tour .tour-top .tour-block {
  top: -11px;
}

ngx-guided-tour .tour-bottom .tour-block {
  top: 42px;
}
