.l-rotation-trees {
  $root: &;

  position: absolute;
  top: 0;
  bottom: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  overflow-y: auto;

  @include media-breakpoint-up(lg) {
    flex-flow: row wrap;
    overflow-y: initial;
  }

  &__sidebar {
    position: relative;
    background-color: $g-color-white;
    flex: 1;
    min-height: 350px;
    max-height: 500px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex: none;
      max-height: 100%;
      width: 300px;
      border-right: 1px solid $g-color-gray;
    }

    @include media-breakpoint-up(xl) {
      width: 380px;
    }
  }

  &__header {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background-color: $g-color-white;
    padding: 10px 20px 15px;

    @include media-breakpoint-up(xl) {
      padding: 22px 32px 15px;
    }
  }

  &__navigation {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 10px;
  }

  &__main {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 350px;
    height: 100%;
  }
}
