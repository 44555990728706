&--list {
  #{$root} {
    &__item {
      white-space: nowrap;
      cursor: pointer;

      &:after {
        margin: 4px 0;
        display: block;
        height: 1px;
        width: 100%;
        background-color: $g-color-gray;
        opacity: 0.4;
        content: '';
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__item-text {
      display: block;
      padding: 4px 8px;
      border-radius: $g-value-border-radius-xl;
      text-align: left;
      font-weight: bold;
      font-size: 13px;
      min-width: 120px;
      @include h-general-transition;

      &:hover {
        color: $g-color-primary;
        background-color: #ebf8ff;
      }
    }

    &__item-button {
      padding: 5px 10px;
      border-radius: $g-value-border-radius-xl;
      display: block;
      text-align: left;
      color: $g-color-secondary;
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;

      &.is-active,
      &:hover {
        color: $g-color-primary;
      }

      &:hover {
        background-color: rgba(15, 94, 247, 0.07);
      }
    }

    &__icon {
      font-size: 7px;
      align-items: center;
      margin-left: 4px;
      display: flex;
    }

    &__header-value {
      color: $g-color-primary;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      font-size: 13px;
    }
  }
}
