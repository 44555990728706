.c-mn-node {
  $root: &;
  width: 160px;
  height: 32px;

  &__inner {
    width: 160px;
    height: 32px;
  }

  &__backdrop {
    fill: $g-color-map-notification-backdrop;
    width: 162px;
    height: 34px;
  }

  &.-start-end {
    width: 40px;
    height: 20px;

    #{$root} {
      &__inner {
        width: 40px;
        height: 20px;
        background-color: $g-color-gray;
        border-radius: 5px;
      }

      &__title {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
      }

      &__backdrop {
        fill: $g-color-map-notification-backdrop;
        width: 44px;
        height: 24px;
      }
    }
  }

  &.-simple-mode {
    width: 220px;
    height: 76px;

    #{$root} {
      &__inner {
        width: 220px;
        height: 76px;
        background-color: unset;
        border-radius: 5px;
      }
    }
  }
}
