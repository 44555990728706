.c-funnel-card {
  $root: &;

  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(15, 94, 247, 0.1);
  background-color: $g-color-white;
  cursor: pointer;

  &__image-container {
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px;

    &:before {
      content: '';
      display: block;
      padding-top: 56%; // ratio of 1 : 1.8
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__checkbox {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border-radius: 7px;
    background-color: $g-color-white;
    color: $g-color-white;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      right: 2px;
      width: 18px;
      height: 18px;
      border-radius: 5px;
      border: 1px solid $g-color-gray;
    }
  }

  &__title {
    @include h-typo--body-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  &__url {
    @include h-typo--additional;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }

  &__language {
    font-size: 11px;
    line-height: 1;

    img {
      width: 14px !important;
      height: 14px !important;
      margin-right: 4px !important;
    }

    span {
      vertical-align: middle;
    }
  }

  &:hover {
    background-color: $g-color-light-blue;
    border: 1px solid $g-color-primary;

    #{$root} {
      &__checkbox {
        &:before {
          border: 1px solid $g-color-primary;
        }
      }
    }
  }

  &.is-selected {
    background-color: $g-color-primary;
    color: $g-color-white;

    #{$root} {
      &__checkbox {
        &:before {
          content: '\e90e';
          font-size: 10px;
          background-color: $g-color-primary;
          border: 1px solid $g-color-primary;
          font-family: 'media500';
          text-align: center;
          line-height: 18px;
        }
      }

      &__url {
        color: $g-color-white;
      }
    }
  }
}
