.c-page-header {
  margin-bottom: 12px;
  $root: &;

  @include media-breakpoint-up(sm) {
    margin-bottom: 18px;
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  &__back {
    font-size: 12px;
    margin-right: 10px;
    a {
      text-decoration: none;
    }

    @include media-breakpoint-up(md) {
      font-size: 20px;
      margin-right: 14px;
    }
  }

  &__page-title {
    display: flex;
    align-items: center;
  }

  &__hint-button {
    position: relative;
    left: 5px;
    bottom: 8px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__title {
    @include h-typo--headline-1;
    display: flex;

    @include media-breakpoint-down(xs) {
      font-size: 22px;
    }

    span {
      color: rgba($g-color-secondary, 0.5);
      display: none;

      @include media-breakpoint-up(md) {
        display: initial;
      }
    }

    &.-uppercase {
      @include h-typo--body-2;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  &__subtitle {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
  }

  &__page-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__action {
    display: flex;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__add {
    @media (min-width: 600px) {
      margin-left: 14px;
    }
  }

  &__refresh,
  &__action-title {
    display: none;
    margin-left: 14px;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__filters {
    .is-active {
      color: $g-color-secondary;
    }
  }

  &__refresh--dashboard {
    margin-bottom: 2px;

    @media screen and (min-width: 770px) {
      margin-bottom: 5px;
    }
  }

  &__content-right {
    margin-left: auto;
    display: flex;
    align-items: center;

    &--sm-center {
      margin-right: auto;
      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }
    }
  }

  &--tool {
    padding-bottom: 22px;
    margin-bottom: 0;
    border-bottom: 1px solid $g-color-gray;
  }

  @import 'c-page-header--changelog';
}
