&--lead-flow {
  #{$root} {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
    }

    &__preview {
      border-radius: $g-value-border-radius-xl;
      padding: 10px 20px;
      text-align: center;
      margin-bottom: 14px;
      background-color: #f7f8fa;

      img {
        max-width: 100%;
      }
    }

    &__data {
      @include h-typo--body-2;
      list-style: none;
      margin: 0;
      padding: 0;

      .key {
        color: rgba($g-color-secondary, 0.3);
        margin-right: 8px;
      }

      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__id {
      @include h-typo--body-1;
      font-weight: bold;
    }

    &__title {
      @include h-typo--body-1;
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      margin-right: 10px;
    }

    &__separator {
      display: inline-block;
      width: 3px;
      height: 3px;
      margin: 0 6px;
      border-radius: 50%;
      background-color: rgba($g-color-secondary, 0.2);
    }

    &__actions {
      display: inline-flex;
      opacity: 0.2;
      margin-left: auto;
      @include h-general-transition(opacity);

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &:hover {
    #{$root}__actions {
      opacity: 1;
    }
  }
}
