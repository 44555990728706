.c-settings-control {
  display: none;

  @include media-breakpoint-up(mx) {
    position: absolute;
    top: -4px;
    right: 0;

    display: block;
    padding: 5px 10px;

    @include h-typo--body-1;
    color: $g-color-secondary;

    background-color: $g-color-white;
    border-radius: 20px;
    box-shadow: 10px 30px 60px rgba(0, 14, 75, 0.15);
    white-space: nowrap;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;

    display: block;
    padding: 4px 3px;
    min-width: 85px;

    @include h-typo--additional;
    line-height: 12px;
    color: $g-color-white;

    background-color: $g-color-secondary;
    border-radius: 3px;
    box-shadow: none;

    transform: translate(25px, -4px);

    &::after {
      content: '';

      position: absolute;
      top: 6px;
      left: -10px;

      width: 10px;
      height: 4px;

      border: 4px solid transparent;
      border-right: 4px solid $g-color-secondary;
    }
  }
}
