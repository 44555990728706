&--info-widgets {
  @mixin wrapped-item($size) {
    flex: 100%;
    margin: $grid-gutter-width 0 0 0;

    @include media-breakpoint-up($size) {
      margin: 0;
      margin-left: $grid-gutter-width;
      flex: 1;
    }
  }

  .c-widget__body {
    border: 0;
    margin: 0;
  }

  .c-widget--info--white {
    padding: 0;
    flex-wrap: wrap;

    &.--marketplace {
      word-break: break-all;
    }

    ul {
      overflow-x: auto;
      background-color: $g-color-white;
      margin: 0;
      padding: 18px;
      margin-left: $grid-gutter-width;
      border: 1px solid $g-color-gray;
      border-radius: $g-value-border-radius-xl;
      flex: 1;

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(2n) {
        @include wrapped-item(md);
      }

      &:nth-child(3n) {
        @include wrapped-item(lg);
      }

      &:nth-child(4n) {
        @include wrapped-item(xl);
      }

      @include media-breakpoint-up(sm) {
        overflow-x: visible;
      }
    }

    &.-table-view {
      p {
        display: flex;
        justify-content: space-between;
        padding: 10px 9px;
        border-radius: 4px;
      }

      .value {
        white-space: nowrap;
        margin-left: 10px;
      }

      li {
        &:not(:first-child) {
          margin-bottom: 0;
        }

        &:nth-child(2n) > p {
          background-color: rgba($g-natural-light-gray, 0.5);
        }
      }
    }

    &.-quick-view {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

      ul {
        margin: 0;

        &:first-child {
          margin: 0;
        }

        &:nth-child(2n) {
          margin: 0;
        }

        &:nth-child(3n) {
          margin: 0;
          @include media-breakpoint-up(lg) {
            &:last-child {
              grid-area: 2 / 1 / 3 / 3;
            }
          }
        }

        &:nth-child(4n) {
          margin: 0;
        }
      }
    }
  }

  .c-widget--info-column {
    flex-direction: column;
    flex-wrap: nowrap;
    ul {
      &:not(:first-child) {
        margin-top: $grid-gutter-width;
        margin-left: 0;
      }
    }
  }
}
