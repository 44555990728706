&--accordion {
  background-color: $g-color-white;
  border-bottom: none;
  text-align: left;
  overflow-x: auto;
  line-height: 17px;
  position: relative;
  font-size: 13px !important;
  min-width: 100%;
  width: max-content;

  .thead {
    min-width: 100%;
    border-bottom: 1px solid $g-color-gray;

    .item {
      display: flex;
      .th {
        flex: 1;
        font-weight: 300;
        min-width: 120px;

        &:first-child {
          flex: 2.5;
          min-width: 250px;
        }

        &--content-width {
          flex: 0;
          min-width: auto;
        }

        > .inner {
          padding: 14px 8px;
          word-wrap: break-word;
        }

        &.-flex-small {
          flex: 0;
          min-width: 100px;
        }

        &.-flex-medium {
          flex: 0;
          min-width: 185px;
        }

        &.-flex-normal {
          flex: 1;
          min-width: 100px;
        }

        // &:last-child {
        //   > .inner {
        //     padding-right: 20px;
        //   }
        // }
      }
    }
  }

  .tbody {
    min-width: 100%;
    > .tr {
      &:nth-child(even) {
        background-color: rgba($g-color-secondary, 0.02);
      }

      &:hover {
        background-color: #e2ecfe;
      }

      .item {
        border-bottom: 1px solid rgba($g-color-gray, 0.5);

        @include media-breakpoint-up(sm) {
          padding-left: 0;
        }

        > .td:first-child {
          position: relative;
        }

        &:hover {
          .p500-ui-circled-button {
            background-color: $g-color-primary;
            opacity: 1;

            &.p500-ui-circled-button--warn {
              background-color: $g-color-red;
            }
          }
        }
      }
    }

    .child {
      position: relative;
      display: none;
    }

    .item {
      display: flex;
      position: relative;

      .td {
        flex: 1;
        box-sizing: content-box;
        display: block;
        position: relative;
        z-index: 0;
        min-width: 120px;

        &:first-child {
          flex: 2.5;
          min-width: 250px;
        }

        &--content-width {
          flex: 0;
          min-width: auto;
        }

        > .inner {
          padding: 0 8px; //(10px + 2) 8px;

          height: 34px;
          display: flex;
          align-items: center;
          padding-bottom: 1px;

          > * {
            width: 100%;
          }

          &.actions {
            justify-content: flex-end;
          }
        }

        .inner-content {
          width: max-content;
          padding-right: 10px;
        }

        &:last-child {
          > .inner {
            padding-right: 20px;
          }
        }

        &.-flex-small {
          flex: 0;
          min-width: 100px;
        }

        &.-flex-medium {
          flex: 0;
          min-width: 185px;
        }

        &.-flex-normal {
          flex: 1;
          min-width: 100px;
        }
      }
    }

    .tr {
      position: relative;
      &:hover {
        background-color: rgba($g-color-primary, 0.12) !important;
      }
      &.has-child {
        > .item {
          position: relative;
          cursor: pointer;

          > .td {
            position: relative;

            &:before {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              height: 100%;
              background-color: rgba($g-color-secondary, 0.05);
              z-index: -1;
              opacity: 0;
              @include h-general-transition;
            }

            &:first-child {
              > .inner {
                padding-left: 26px;
              }
            }
          }

          &:hover {
            > .td {
              &:before {
                opacity: 1;
              }
            }
          }

          > .td {
            &:first-child {
              > .inner {
                &:after {
                  display: inline-block;
                  width: 12px;
                  text-align: center;
                  font-size: 12px;
                  left: 8px;
                  position: absolute;
                  content: '\e935';
                  font-family: 'media500';
                  color: $g-color-primary;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }

        &.is-opened {
          > .item {
            > .td {
              .inner {
                background-color: $g-color-secondary;
                color: $g-color-white;
              }
              &:first-child {
                .inner::after {
                  color: $g-color-white;
                  font-size: 6px;
                  content: '\e904';
                }
              }
            }
          }

          > .child {
            display: block;
            background-color: rgba($color: $g-color-primary, $alpha: 0.05);
          }
        }
      }

      @for $i from 1 through 7 {
        &.level-#{$i} {
          > .item {
            > .td:first-child {
              &:before {
                right: 0;
                left: 18px * $i;
              }

              > .inner {
                padding-left: 26px + 18px * $i;

                &:after {
                  left: 8px + 18px * $i;
                }
              }
            }
          }
        }
      }
    }
  }

  .tfoot {
    .item {
      display: flex;

      .th {
        flex: 1;
        font-size: 14px;
        font-weight: 700;

        min-width: 120px;

        &:first-child {
          flex: 2.5;
          min-width: 250px;
        }

        > .inner {
          padding: 14px 8px;
        }

        // &:first-child {
        //   //flex: 2;
        // }

        &:last-child {
          > .inner {
            padding-right: 20px;
          }
        }

        &.-flex-small {
          flex: 0;
          min-width: 100px;
        }

        &.-flex-medium {
          flex: 0;
          min-width: 185px;
        }

        &.-flex-normal {
          flex: 1;
          min-width: 100px;
        }

        // &:not(:first-child) {
        //   > .inner {
        //     text-align: right;
        //   }
        // }
      }
    }
  }

  &-alt {
    .tbody {
      .tr {
        &.is-opened {
          &.level-0 {
            &.is-opened {
              > .item {
                background-color: $g-color-secondary;

                @include media-breakpoint-up(sm) {
                  border-radius: 5px;
                }

                > .td {
                  color: $g-color-white;

                  .c-link,
                  .h-icon {
                    color: $g-color-white;
                  }

                  .c-label {
                    color: $g-color-secondary;
                    background-color: $g-color-white;
                  }

                  &:after {
                    display: none;
                  }

                  &:before {
                    background-color: transparent;
                    opacity: 1;
                  }
                }
              }
            }

            > .child {
              margin-left: 12px;
              border-left: 1px solid rgba($g-color-primary, 0.1);
              border-right: 1px solid rgba($g-color-primary, 0.1);
              background-color: rgba($g-color-primary, 0.03);

              &:before {
                display: none;
              }

              &:after {
                display: none;
              }
            }
          }
        }

        &:nth-child(2n) {
          > .item {
            background-color: rgba($g-color-secondary, 0.02);
          }
        }

        &.has-child {
          background-color: initial;
          &.is-opened {
            &:after {
              display: none;
            }

            > .item {
              > .td {
                border-bottom-width: 0;
              }
            }
          }

          .child {
            padding: 14px;

            &:before {
              width: 100%;
              left: 0;
            }

            &--padding-zero {
              padding: 0;
            }
          }
        }

        &:last-child {
          &.has-child {
            &.is-opened {
              > .child {
                margin-bottom: 14px;
              }
            }
          }
        }
      }
    }
  }

  &-alt-2 {
    background: none;

    .thead {
      .tr {
        > .item {
          border-bottom: none;
        }

        .th {
          font-weight: bold;
          color: rgba($g-color-secondary, 0.3);
          .inner {
            padding: 10px 18px;
          }
        }
      }
    }

    .tbody {
      .tr {
        > .item {
          .inner {
            padding-left: 18px;
            padding-right: 18px;
          }

          > .td:first-child {
            > .inner {
              padding-left: 18px;
            }
          }
        }

        &.has-child {
          background-color: initial;

          &.is-opened {
            padding-bottom: 10px;
            & > .item {
              border-bottom: none;
              background-color: rgba($g-color-light-blue, 0.8);

              > .td {
                .inner {
                  background-color: initial;
                  color: initial;
                }

                &:first-child {
                  .inner::after {
                    color: $g-color-primary;
                  }
                }
              }
            }
          }

          > .item {
            > .td:first-child {
              > .inner {
                padding-left: 30px + 14px;

                &:after {
                  left: 18px;
                }
              }
            }
          }

          > .child {
            background-color: rgba($g-color-light-blue, 0.8);
            border-radius: 0 0 10px 10px;

            .thead {
              border-bottom: none;
              background-color: $g-color-secondary;

              > .tr > .item {
                .th:first-child > .inner {
                  padding-left: 46px;
                }
              }

              .inner {
                color: $g-color-white;
              }
            }

            .tbody {
              > .tr > .item {
                .td:first-child > .inner {
                  padding-left: 46px;
                }
              }
            }

            .th {
              color: $g-color-secondary;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  &-alt-3 {
    background-color: rgba(2, 161, 255, 0.05);
    border-radius: 5px;
    border: 1px solid $g-color-light-gray;
    padding: 0 10px;

    .thead {
      .tr {
        .th {
          font-weight: bold;

          .inner {
            padding: 14px;
          }
        }
      }
    }

    .tbody {
      .tr {
        > .item {
          > .td {
            > .inner {
              padding-left: 14px;
              padding-left: 14px;
            }
          }

          > .td:first-child {
            > .inner {
              padding-left: 18px;
            }
          }
        }

        &.has-child {
          > .item {
            > .td:first-child {
              > .inner {
                padding-left: 33px;

                &:after {
                  left: 14px;
                }
              }
            }
          }

          .child {
            padding: 0;

            &:before {
              background-color: $g-color-white;
              border: 1px solid $g-color-light-gray;
            }

            > .table {
              background-color: $g-color-white;
              border-radius: 5px;
              border: 1px solid $g-color-light-gray;
              padding: 0 10px;

              .td:first-child,
              .th:first-child {
                > .inner {
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .-white {
    background-color: $g-color-white;
  }

  .-border {
    border: 1px solid $g-color-gray;
    border-radius: $g-value-border-radius-xl;
  }
  .tbody {
    .report-row {
      &:nth-child(2n) {
        > .tr {
          background-color: rgba($g-color-secondary, 0.02);
        }
      }
    }
  }
}
