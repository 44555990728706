.c-notifications-sidebar {
  position: absolute;
  background: #fff;
  z-index: 15;
  top: 0;
  right: 0;
  min-height: 100%;
  max-height: 100%;
  width: 100vw;
  box-shadow: 0px 10px 30px rgba(0, 14, 75, 0.15);
  display: flex;
  flex-direction: column;
  $root: &;

  @include media-breakpoint-up(md) {
    width: 400px;
  }

  .p500-ui-pagination__section-mob {
    display: none;
  }

  &__button {
    @include h-general-transition;
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
    color: $g-color-secondary;
  }

  &__close {
    @include h-general-transition(opacity);
    position: absolute;
    font-size: 14px;
    line-height: 1em;
    display: block;
    right: 8px;
    top: 8px;
    z-index: 21;
    cursor: pointer;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
  &__top-wrapper {
    position: sticky;
    top: 0;
    background-color: $g-color-white;
  }
  &__top {
    display: flex;
    align-items: center;
    padding: 26px 0px 16px 20px;
    box-shadow: 0px 5px 20px rgba(0, 14, 75, 0.08);
  }
  &__tabs {
    display: flex;
    border-top: 1px solid rgba($g-color-gray, 0.4);
    border-bottom: 1px solid rgba($g-color-gray, 0.4);
    background-color: $g-color-white;

    &-button {
      $buttonRoot: &;
      @include h-typo--body-2;
      font-weight: bold;
      padding: 12px 0;
      min-width: 50%;

      &__title {
        position: relative;
      }

      &.--active {
        color: $g-color-primary;
        background-color: rgba($g-color-primary, 0.05);

        #{$buttonRoot}__title::before {
          content: '';
          position: absolute;
          top: 0;
          right: -6px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $g-color-green;
        }
      }
    }
  }
  &__dropdown {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  &__sub-tabs {
    padding-left: 20px;
    padding-right: 10px;
    position: relative;

    &-wrapper {
      display: flex;
      overflow-y: auto;
    }

    &-button {
      $buttonRoot: &;
      @include h-typo--additional;
      font-weight: bold;
      padding: 12px 0;
      margin-right: 20px;
      white-space: nowrap;
      border-bottom: 2px solid transparent;

      &__title {
        opacity: 0.3;
      }

      &.--active {
        border-bottom: 2px solid $g-color-secondary;

        #{$buttonRoot}__title {
          opacity: 1;
        }
      }

      &-more {
        color: $g-color-primary;
        background-color: $g-color-light-blue;
        font-size: 23px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;

        &:hover {
          color: $g-color-white;
          background-color: $g-color-primary;
        }
      }
    }
  }
  &__title {
    color: $g-color-secondary;
    font-size: 20px;
    display: flex;
    align-items: center;
    &::after {
      content: close-quote;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #e6e7ed;
      margin: 0px 8px;
    }
  }
  &__slack {
    color: #fff;
    background: #5345fa;
    border-radius: 16px;
    font-weight: bold;
    font-size: 11px;
    padding: 5px 12px;
    margin-top: -8px;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
  &__list {
    list-style: none;
    padding-left: 0;
    overflow-y: auto;
  }

  &__info {
    @include h-general-transition(opacity);
    @include h-typo--body-2;
    font-weight: bold;
    color: $g-color-primary;
    margin-left: auto;
    margin-right: 14px;
    opacity: 0;
  }
  &__item {
    @include h-general-transition(background-color);
    @include h-typo--body-2;
    border-top: 1px solid $g-color-gray;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: rgba($g-color-primary, 0.05);
      #{$root}__info {
        opacity: 1;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__id {
    font-size: 13px;
    color: $g-color-primary;
    font-weight: bold;
  }
  &__new span {
    display: block;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid rgba(15, 94, 247, 0.1);
    border-radius: 5px;
    padding: 4px;
    line-height: 1;
    text-transform: lowercase;
    &.text-color--yellow {
      border: 1px solid $g-color-yellow;
    }
    &.text-color--orange {
      border: 1px solid $g-color-orange;
    }
    &.text-color--red {
      border: 1px solid $g-color-red;
    }
  }
  &__message {
    font-size: 13px;
    margin: 5px 0;
  }
  &__date {
    color: rgba(0, 14, 75, 0.3);
    font-size: 12px;
  }
  &__bottom {
    position: sticky;
    bottom: 0;
    background-color: $g-color-white;
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    align-items: center;
    width: 100%;
    padding: 6px 16px 16px;
    border-top: 1px solid $g-color-gray;
    .p500-ui-pagination__section-desk {
      display: none;
    }
  }
  &__search,
  &__pagination {
    margin: auto;
    margin-top: 10px;
  }
  &__search {
    width: 176px;
  }
}
