&--reports-creator {
  z-index: $z-widget-report-builder;

  #{$root} {
    &__body {
      padding: 14px 18px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      align-self: center;
      display: flex;
      align-items: center;
      padding: 15px 14px;
      border-radius: 10px;
      border: 1px solid $g-color-gray;
      margin-right: 14px;
      margin-bottom: 14px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: $g-color-primary;

        .title {
          color: $g-color-primary;
        }
      }

      &.-active {
        background-color: $g-color-primary;
        border-color: $g-color-primary;
        pointer-events: none;

        .title,
        .p500-ui-icon {
          color: $g-color-white;
        }
      }

      .title {
        @include h-typo--body-2;
        margin-left: 10px;
      }
    }

    &__form-field {
      width: 100%;
      margin-right: 0;
      margin-bottom: 14px;

      @include media-breakpoint-up(sm) {
        margin-right: 20px;
        max-width: 220px;
      }
    }

    &__settings {
      padding: 18px;
      background-color: rgba($g-color-primary, 0.05);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &__divider {
      width: 0;
      border-left: 1px solid $g-color-gray;
      margin-right: 20px;
      margin-bottom: 14px;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }
}
