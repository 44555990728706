&--template {
  cursor: pointer;
  #{$root} {
    // position: relative;

    &__title {
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 20px;
      padding: 18px;
      width: 90px;
      height: 74px;
      border: 1px transparent solid;
    }

    &__tooltip {
      display: none;
      // position: absolute;
    }
  }

  &#{$root}--color {
    &--purple {
      #{$root} {
        &__title {
          color: $g-color-purple;
        }

        &__body {
          background-color: rgba($g-color-purple, 0.15);

          &.is-selected {
            border: 1px rgba($g-color-purple, 0.3) solid;
          }
        }
      }
    }

    &--cerise {
      #{$root} {
        &__title {
          color: $g-color-cerise;
        }
        &__body {
          background-color: rgba($g-color-cerise, 0.15);

          &.is-selected {
            border: 1px rgba($g-color-cerise, 0.3) solid;
          }
        }
      }
    }
    &--blue {
      #{$root} {
        &__title {
          color: #09bcfa;
        }

        &__body {
          background-color: rgba(#09bcfa, 0.15);

          &.is-selected {
            border: 1px rgba(#09bcfa, 0.3) solid;
          }
        }
      }
    }
    &--dark-blue {
      #{$root} {
        &__title {
          color: $g-color-secondary;
        }

        &__body {
          background-color: rgba($g-color-secondary, 0.06);

          &.is-selected {
            border: 1px rgba($g-color-secondary, 0.12) solid;
          }
        }
      }
    }

    &--dashed {
      #{$root} {
        &__body {
          box-sizing: border-box;
          border: 1px dashed rgba($g-color-secondary, 0.5);
        }
      }
    }

    &--solid {
      #{$root} {
        &__body {
          box-sizing: border-box;
          border: 1px solid $g-color-gray;
        }
      }
    }
  }

  &.-map-notification {
    #{$root} {
      &__body {
        width: 100%;
        @include h-general-transition(border);
      }

      &__actions {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
      }

      &__action:not(:last-child) {
        margin-right: 6px;
      }

      &__subtitle {
        color: rgba($g-color-secondary, 0.3);
      }
    }

    &#{$root}--color {
      &--purple:hover {
        #{$root} {
          &__body {
            border: 1px solid $g-color-purple;
          }
        }
      }

      &--cerise:hover {
        #{$root} {
          &__body {
            border: 1px solid $g-color-cerise;
          }
        }
      }

      &--blue:hover {
        #{$root} {
          &__body {
            border: 1px solid $g-color-dark-cyan-2;
          }
        }
      }

      &--dashed:hover,
      &--dark-blue:hover {
        #{$root} {
          &__body {
            border: 1px solid $g-color-secondary;
          }
        }
      }
    }
  }
}
