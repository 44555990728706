.pre {
  white-space: pre-line;
}

.ws-nowrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-800 {
  font-weight: 800;
}

.h-typo--body-2 {
  @include h-typo--body-2;
}
