.c-knowledge-base {
  $root: &;
  position: relative;

  &__open-button {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 28px;
    height: 28px;
    color: $g-color-white;
    border-top-left-radius: 28px;
    background-color: $g-color-primary;
    padding-right: 8px;
    @include h-typo--body-1;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;
    z-index: 12;
  }

  &__inner {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    z-index: 100;
    background-color: $g-color-primary;
    color: $g-color-white;
  }

  &.is-opened {
    #{$root} {
      &__open-button {
        display: none;
      }

      &__inner {
        display: block;

        @include media-breakpoint-up(md) {
          left: auto;
          width: 360px;
        }
      }
    }
  }

  &__body {
    height: calc(100% - 62px);
    padding: 32px;
    overflow-y: auto;
  }

  &__title {
    @include h-typo--headline-1;
    text-align: center;
    position: relative;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  &__subtitle {
    @include h-typo--headline-2;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  &__relevant-posts {
    margin-bottom: 30px;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: rgba($g-color-white, 0.3);
    cursor: pointer;
    display: inline-block;
    padding: 10px;

    &:hover {
      color: rgba($g-color-white, 0.6);
    }
  }

  &__search {
    background-color: $g-color-primary-hover;
    display: flex;
    align-items: center;
    height: 62px;
    padding: 0 20px;
    color: rgba($g-color-white, 0.3);
    border-top: 1px solid rgba($g-color-white, 0.2);

    .h-icon {
      font-size: 20px;
      display: inline-block;
      margin-right: 12px;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 62px;
    left: 0;
    right: 0;
    height: 53px;
    background-image: linear-gradient(to bottom, rgba(15, 94, 247, 0), $g-color-primary);
    pointer-events: none;
  }

  &__back-btn {
    color: $g-color-white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  &__post {
    @include h-typo--body-1;
    margin-bottom: 24px;

    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    p {
      margin-bottom: 10px;
    }

    ul,
    ol {
      padding-left: 25px;
    }

    a {
      color: rgba($g-color-white, 0.5);
      text-decoration: underline;

      &:hover {
        color: $g-color-white;
      }
    }
  }

  &__post-link {
    display: inline-block;
    @include h-typo--body-1;
    font-weight: bold;
    color: $g-color-white;
    text-decoration: none;
    background-color: rgba($g-color-white, 0.2);
    border-radius: 20px;
    padding: 9px 40px 9px 16px;
    position: relative;
    text-decoration: underline;

    &:hover {
      color: $g-color-white;
      background: rgba($g-color-white, 0.3);
      text-decoration: none;
    }

    &:after {
      content: '\e90a';
      font-family: 'media500';
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: $g-color-white;
      border-radius: 12px;
      color: $g-color-primary;
    }
  }

  &__tour-button {
    padding: 8px 12px;
    border: 1px solid $g-color-white;
    border-radius: 18px;
    @include h-typo--body-2;
    color: $g-color-white;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @include media-breakpoint-down(xl) {
      &.-desktop-show {
        display: none;
      }
    }
  }
}
