.c-mn-menu {
  $root: &;

  &__inner {
    min-width: 200px;
    max-width: 250px;
    background-color: $g-color-light-blue;
    border: 1px solid rgba($g-color-primary, 0.1);
    padding: 14px;
    border-radius: 5px;
  }

  &__head {
    display: flex;
    margin-bottom: 8px;
  }

  &__icon {
    align-self: center;
    margin-right: 6px;
  }

  &__title {
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 8px;
    text-transform: uppercase;

    &.-gray {
      color: rgba($g-color-secondary, 0.3);
    }
  }

  &__path {
    &-block:not(:last-child) {
      margin-bottom: 14px;
    }

    &-title {
      font-size: 12px;
      line-height: 18px;
      color: rgba($g-color-secondary, 0.3);
    }

    &-node {
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__subtitle {
    font-weight: bold;
    line-height: 22px;
  }

  &__separator {
    width: 100%;
    height: 1px;
    background: rgba($g-color-secondary, 0.1);
    margin-top: 14px;
    margin-bottom: 14px;
  }

  &__buttons {
    display: flex;
  }

  &__btn {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.-gray {
    background: $g-color-map-notification-backdrop;

    #{$root} {
      &__inner {
        background: rgba($g-color-gray, 0.4);
        border: none;
      }
    }
  }

  &.-buttons {
    #{$root} {
      &__buttons {
        justify-content: flex-end;
      }

      &__inner {
        min-width: initial;
        padding: 0;
        background: none;
        border: none;
        max-width: initial;
      }

      &__text {
        color: rgba($g-color-secondary, 0.3);
        width: 100px;
        margin-left: auto;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        padding-top: 10px;
      }
    }
  }

  &.-selector {
    #{$root} {
      &__inner {
        border: none;
        padding: 0;
        background-color: unset;
        min-width: 80px;
      }

      &__el-title {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
      }

      &__el {
        box-sizing: border-box;
        padding: 14px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;

        &:not(:first-child) {
          border-top: 1px solid rgba($g-color-secondary, 0.1);
        }

        .c-button {
          color: $g-color-primary;
        }

        &.-active {
          border-radius: 5px;
          color: $g-color-primary;
          background: $g-color-light-blue;
          border: 1px solid rgba($g-color-primary, 0.1);
          padding: 14px 13px;

          + div {
            border-top: 1px solid transparent;
          }
        }
      }
    }
  }

  &.-workflow {
    #{$root} {
      &__subtitle {
        &.-gray {
          color: rgba($g-color-secondary, 0.3);
        }
      }
    }
  }
}

.mobile-template {
  .c-mn-menu {
    border: 1px solid $g-color-light-gray-2;

    &__btn button {
      width: 140px;

      background-color: $g-color-light-blue;
      color: $g-color-primary;
      font-weight: bold;

      padding: 5px;
      border-radius: 10px;
      border: none;
    }

    &__btn {
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 5px;

        &:after {
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          background-color: $g-color-light-gray-2;
          margin-top: 5px;
        }
      }
    }
  }
}
