&--settings {
  #{$root} {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
    }

    &__title {
      @include h-typo--body-1;
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      margin-right: 10px;
    }

    &__separator {
      display: inline-block;
      width: 3px;
      height: 3px;
      margin: 0 6px;
      border-radius: 50%;
      background-color: rgba($g-color-secondary, 0.2);
    }

    &__actions {
      display: inline-flex;
      opacity: 0.2;
      margin-left: auto;
      @include h-general-transition(opacity);

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      width: 100%;
      height: 70px;
      background: rgba($g-color-secondary, 0.03);
      border-radius: $g-value-border-radius-xl;
      padding-right: 14px;
      padding-left: 14px;
      margin-bottom: 14px;

      &-title {
        text-transform: uppercase;
        color: rgba($g-color-secondary, 0.3);
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    &__data {
      display: flex;

      &-updated {
        color: rgba($g-color-secondary, 0.3);
        margin-right: 4px;
      }

      &-author,
      &-at {
        color: $g-color-secondary;
        margin-right: 4px;
      }

      &-date {
        color: $g-color-secondary;
      }
    }

    &__data {
      @include h-typo--body-2;
      list-style: none;
      margin: 0;
      padding: 0;

      .key {
        color: rgba($g-color-secondary, 0.3);
        margin-right: 8px;
      }

      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__id {
      @include h-typo--body-1;
      font-weight: bold;
    }
  }

  &:hover {
    #{$root}__actions {
      opacity: 1;
    }
  }
}
