ngb-datepicker {
  background-color: $g-color-white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  width: 260px;
  z-index: $z-datepicker;
  margin-top: 5px;
}

ngb-datepicker-navigation {
  width: 100%;
}

.right {
  .ngb-dp-navigation-chevron {
    margin-left: 0;
    margin-right: 0;
    transform: scale(-1);
  }
}
.ngb-dp {
  &-header {
    display: flex;
    padding: 5px 7px !important;
    border-radius: initial !important;
    border-bottom: 1px solid rgba($g-color-secondary, 0.2) !important;
    margin-bottom: 12px !important;
  }

  &-arrow {
    &.right {
      .ngb-dp-navigation-chevron {
        transform: scale(-1) translateY(-2px) !important;
      }
    }
    &-btn {
      margin: 0 !important;
      padding: 0 9px !important;
    }
  }

  &-navigation-chevron {
    position: relative;
    visibility: hidden;
    width: initial !important;
    height: initial !important;
    transform: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    &:after {
      font-family: 'media500';
      font-size: 12px;
      content: '\e905';
      visibility: initial;
    }
  }

  &-navigation-select {
    flex: 1 1 auto;
    flex-direction: row-reverse;
    > .custom-select {
      padding: 5px 10px;
      height: 26px;
      border-radius: 13px;
      font-size: 11px;
      line-height: 16px;
      font-weight: bold;

      &[aria-label='Select year'] {
        background-color: $g-color-light-gray;
        border: none;
        margin-right: 4px;
        width: 60px;
      }
    }
  }

  &-month {
    width: 100%;

    &:first-child {
      .ngb-dp-week {
        padding-left: 12px !important;
      }
    }

    &:last-child {
      .ngb-dp-week {
        padding-right: 12px !important;
      }
    }

    .ngb-dp-week {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-weekday,
  &-week-number {
    font-style: normal !important;
    color: rgba($g-color-secondary, 0.5) !important;
    line-height: 20px !important;
  }
  &-day,
  &-week-number,
  &-weekday {
    width: 24px !important;
    height: 24px !important;
  }

  &-custom-day {
    color: $g-color-secondary;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.-not-current-month {
      color: rgba($g-color-secondary, 0.05);
    }

    &.-range {
      background-color: $g-color-primary;
      color: $g-color-white;
    }

    &.-faded {
      background-color: rgba($g-color-primary, 0.15);
      color: $g-color-primary;

      &.-not-current-month {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: $g-color-primary;
      color: $g-color-white;
    }
  }

  &-custom-footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    & > * {
      &:not(:last-child) {
        border-bottom: 1px solid rgba($g-color-secondary, 0.2);
      }
    }

    &-choose {
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 12px 0;

      &-btn {
        font-size: 11px;
        line-height: 16px;
        padding: 5px 10px;
        margin: 0 auto;
        border-radius: 13px;
        background-color: $g-color-primary;
        color: $g-color-white;
      }
    }

    &-time {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: rgba($g-color-secondary, 0.5);
      font-size: 11px;
    }

    button {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
    }
  }

  &-week {
    display: flex !important;
    justify-content: space-between !important;
    font-size: 13px !important;
    line-height: 20px !important;

    &.ngb-dp-weekdays {
      border-bottom: none;
    }
  }
}

.ngb-tp {
  justify-content: center;
  &-input-container {
    width: 20px !important;
  }
  &-input {
    width: 15px !important;
    height: 20px !important;
    font-size: 13px !important;
    border: none !important;
  }

  &-meridian {
    font-size: 12px !important;
    padding: 0 8px !important;
    background-color: $g-color-light-gray;
    border-radius: 11.5px;
  }

  &-chevron {
    &:before {
      content: '\e936' !important;
      font-family: media500 !important;
      font-size: 6px !important;
      transform: none !important;
      left: -4px !important;
      height: auto !important;
      border: none !important;
      top: initial !important;
    }

    &.bottom {
      &:before {
        transform: none !important;
        content: '\e933' !important;
      }
    }
  }

  .sr-only {
    display: none !important;
  }
}
