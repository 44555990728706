&--changelog {
  #{$root} {
    &__body {
      > * {
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    &__img {
      width: 100%;
      border: 1px solid rgba($g-color-secondary, 0.1);
      border-radius: $g-value-border-radius-xl;
      box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
