&--leads-repusher {
  #{$root} {
    &__body {
      left: 50%;
      min-width: 140px;
      top: calc(100% + 1px);
      transform: translate(-50%, 0);
    }

    &__item {
      position: relative;

      span {
        margin-bottom: 9px;
        position: relative;
        min-width: 100%;

        &::before {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          border-bottom: $g-color-gray 1px solid;
          opacity: 0.4;
          bottom: -5px;
          left: 0;
        }

        &:last-child {
          margin-bottom: 0;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}
