.с-pql-field {
  $root: &;

  position: relative;
  background-color: transparent;
  z-index: 1;
  white-space: nowrap;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -2px;
    right: -2px;
    z-index: -1;
  }

  &.-cyan {
    &:before {
      background-color: #97ffec;
    }
  }

  &.-violet {
    &:before {
      background-color: #d3afff;
    }
  }

  &.-orange {
    &:before {
      background-color: #ffd19c;
    }
  }

  &.-yellow {
    &:before {
      background-color: #f5ff82;
    }
  }

  &.-pink {
    &:before {
      background-color: #ffbcdc;
    }
  }

  &.-green {
    &:before {
      background-color: #abffb3;
    }
  }

  &.-darkturquoise {
    &:before {
      background-color: #bbcaff;
    }
  }

  &.-coral {
    &:before {
      background-color: #ffc4c4;
    }
  }

  &.-aqua {
    &:before {
      background-color: #b4f6ff;
    }
  }

  &.-greenyellow {
    &:before {
      background-color: #d2ff98;
    }
  }

  @import 'c-pql-field--typed';
}
