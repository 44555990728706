// .highcharts-areaspline-series {
//   .highcharts-point {
//     y: 7;
//   }
// }

highcharts-chart {
  width: 100%;
  display: block;
}

.highcharts-tooltip {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}
