&--help-card {
  height: 100%;

  #{$root} {
    &__bg-image {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 45%;
        background-image: url('../../../../assets/new-img/bg/bg-help-widget.svg');
        background-repeat: no-repeat;
        background-position-x: 0;
        background-size: cover;
      }
    }

    &__body {
      padding: 31px 55px;
      background-color: #f7f8fa;
      border-radius: $g-value-border-radius-xl;
      border-width: 0;
      position: relative;
      background-image: url('../../../../assets/new-img/icons/diamond.svg');
      background-repeat: no-repeat;
      background-position: 20px 20px;
      &,
      &-container {
        height: 100%;
      }
    }

    &__card-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      align-self: center;
      margin-right: 20px;
      border-radius: 2px;
      background-color: rgba($g-color-secondary, 0.2);
      @media (max-width: 1480px) and (min-width: 1440px) {
        display: none;
      }
      @media (max-width: 1570px) and (min-width: 1439px) {
        margin-right: 10px;
      }
      @media (max-width: 320px) {
        display: none;
      }
    }

    &__text {
      @include h-typo--body-2;
      margin-bottom: 15px;
      min-width: 288px;
      @media (max-width: 350px) {
        min-width: unset;
      }
    }

    &__link {
      position: relative;
      display: flex;
      @include h-typo--body-2;
      font-weight: bold;

      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 1570px) and (min-width: 1439px) {
          margin-right: 10px;
        }
      }
    }

    &__icon {
      margin-right: 10px;
      font-size: 18px;
      vertical-align: sub;
    }
  }
}
