.l-changelog {
  $root: &;
  $width-help: 180px;

  #{$root} {
    &__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;

      @include media-breakpoint-up(mx) {
        justify-content: space-between;
        max-width: 1040px;
        flex-direction: row;
      }
    }

    &__changes {
      width: 100%;

      @include media-breakpoint-up(mx) {
        width: calc(100% - #{$width-help});
      }
    }

    &__help {
      width: 100%;
      order: -1;
      @include media-breakpoint-up(mx) {
        order: initial;
        width: $width-help;
      }

      > ul {
        margin-left: 0;
        padding-left: 0;
        display: flex;

        @include media-breakpoint-up(mx) {
          display: initial;
        }

        > li {
          text-align: right;
          list-style-type: none;
          font-size: 13px;
          line-height: 20px;
          color: $g-color-primary;
          margin-right: 8px;

          @include media-breakpoint-up(mx) {
            margin-right: 0;
          }

          &:not(:last-child) {
            @include media-breakpoint-up(mx) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    &__change {
      &:not(:last-child) {
        padding-bottom: 34px;
        margin-bottom: 34px;
        border-bottom: 1px solid $g-color-gray;
      }
    }
  }
}
