&--help-card-2 {
  #{$root} {
    &__body {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__footer {
      position: relative;
      display: flex;
      overflow: hidden;
      padding: 16px 40px;
      padding-bottom: 6px;
      background-color: #e7efff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      align-items: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-top: none;
      flex-flow: column;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      @media (min-width: 795px) {
        margin: 0;
        flex-flow: row nowrap;
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        padding: 16px 60px;
        padding-bottom: 6px;
        @media (min-width: 1025px) and (max-width: 1121px) {
          padding: 16px 40px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        width: 15px;
        height: 15px;
        background-color: #0061ff;
        border-bottom-right-radius: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;
        background-color: #000e4b;
        border-bottom-left-radius: 15px;
      }
    }

    &__help {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      @media (min-width: 795px) {
        flex-flow: row nowrap;
        align-items: center;
      }
    }

    &__help-card-title {
      @include h-typo--body-1;
      font-weight: bold;
      text-align: center;
      margin: 5px 0;
      @media (min-width: 795px) {
        margin: 0;
        margin-right: 40px;
      }
      @media (min-width: 1025px) and (max-width: 1121px) {
        white-space: nowrap;
        margin-right: 8px;
      }
    }

    &__help-links {
      display: flex;
      margin: 5px 0;
      @media (min-width: 795px) {
        margin: 0;
      }
    }

    &__help-link {
      position: relative;
      display: inline-flex;
      @include h-typo--body-2;
      font-weight: bold;
      white-space: nowrap;
      color: $g-color-primary;

      &:not(:last-child) {
        margin-right: 20px;
        @media (min-width: 1025px) and (max-width: 1121px) {
          margin-right: 8px;
        }
      }
    }

    &__links {
      align-self: center;
      margin: 5px 0;

      @media (min-width: 795px) {
        margin: 0;
        margin-right: auto;
      }
      @media (min-width: 1025px) and (max-width: 1121px) {
        white-space: nowrap;
        margin-right: 15px;
      }
    }

    &__icon {
      margin-right: 6px;
      font-size: 12px;
    }
  }
}
