.c-pql-query {
  font-size: 12px;
  line-height: 20px;

  @include media-breakpoint-up(xs) {
    display: inline-flex;
    padding: 2px 8px;
    background-color: $g-color-white;
    border-radius: calc($g-value-border-radius-xl / 2);
  }

  &__icon {
    flex: none;
    margin-right: 8px;
    margin-bottom: 2px;
  }

  &__hide-button {
    margin-left: 0.5rem;
    cursor: pointer;
    opacity: 0.9;
    color: $g-color-primary;
  }

  &__separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    opacity: 0.9;
    color: $g-color-primary;
    &.-first {
      margin-left: -0.5rem;
    }
  }

  &__first-path {
    margin-left: 14px;
    display: inline-flex;
  }

  &__inner {
    font-family: monospace;
    word-break: break-word;

    .is-opacity {
      opacity: 0.3;
    }
  }
}
