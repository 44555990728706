.c-rotation-row {
  $root: &;

  box-shadow: 0px 3px 10px rgba($g-color-secondary, 0.03);
  @include h-typo--body-2;
  cursor: pointer;

  &.cdk-drag-preview {
    display: table;

    td {
      vertical-align: middle;
    }
  }

  td {
    padding: 11px 14px;
    background-color: $g-color-white;
    border: 1px solid transparent;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.-inactive {
    box-shadow: none;
    opacity: 0.5;

    td {
      background-color: transparent;
    }
  }

  &:hover {
    td {
      border-top-color: rgba($g-color-primary, 0.2);
      border-bottom-color: rgba($g-color-primary, 0.2);
      background-color: $g-color-light-blue;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left-color: rgba($g-color-primary, 0.2);
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right-color: rgba($g-color-primary, 0.2);
      }
    }
  }

  &__text-gray {
    color: rgba($g-color-secondary, 0.3);
  }

  &__text-blue {
    color: $g-color-primary;
  }

  &__name {
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
      object-fit: contain;
      margin-right: 8px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
