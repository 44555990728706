* {
  outline: none !important;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    @include h-general-transition;
    cursor: pointer;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba($g-color-secondary, 0.1);
    border-radius: $g-value-border-radius-xl;

    &:hover {
      background-color: rgba($g-color-secondary, 0.5);
    }
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }
}

html {
  height: 100%;
}
body {
  height: 100%;
  font-family: $g-font-base;
  color: $g-color-secondary;
  background-color: $g-color-white;
  -webkit-font-smoothing: antialiased;
  @include h-typo--body-1;

  &.account-mode {
    --g-font-table: #{$g-font-monospace};
  }
}

p {
  margin-bottom: 0;
}

a,
label {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
}

textarea,
input {
  padding: 0;
  -webkit-appearance: none !important;
  border-radius: 5px;
}
