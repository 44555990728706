.c-navigation-trees {
  $root: &;
  $padding-xl-layout: 32px;
  $padding-layout: 20px;
  $icon-size: 20px;

  font-size: 13px;
  line-height: 1.4;
  margin-bottom: -6px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }

  &__items {
    height: 100%;

    .empty {
      margin-top: 145px;
      text-align: center;
    }
  }

  &__inner {
    text-overflow: ellipsis;
    line-height: 20px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
    align-items: center;
  }

  &__title {
    position: relative;
    overflow: hidden;
    padding: 5px 14px 5px ($padding-layout + $padding-layout);
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    @include media-breakpoint-up(xl) {
      padding: 5px 14px 5px ($padding-layout + $padding-xl-layout);
    }

    &:hover {
      background-color: rgba($g-color-primary, 0.1);

      #{$root}__create,
      #{$root}__remove {
        display: block;
      }
    }
  }

  &__create {
    display: none;
    padding-left: 10px;
  }

  &__remove {
    display: none;
    padding-left: 10px;
  }

  &__toggle {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: 28px;
    height: 30px;
    left: $padding-layout - 8px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: rgba($g-color-secondary, 0.3);
    @include h-general-transition(transform);

    @include media-breakpoint-up(xl) {
      left: $padding-xl-layout - 8px;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-top: 2px;
    flex: none;
    position: relative;
  }

  &__info {
    margin-left: 14px;
    position: relative;
    display: -webkit-inline-box;
    vertical-align: middle;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: rgba($g-color-secondary, 0.2);
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: -8px;
    }
  }

  &__fill-first {
    width: 10px;
    height: 12px;
    background-image: url('../../../../assets/img/rotation-trees/ico/fill-first.svg');
    margin-right: 6px;
  }

  &__percentage {
    font-size: 11px;
    line-height: 16px;
    color: rgba($g-color-secondary, 0.3);
  }

  &__item {
    display: block;
    position: relative;
    $offset: 20px;

    &.is-opened {
      > #{$root} {
        &__toggle {
          transform: rotate(90deg);
        }
      }
    }

    &.is-active {
      > #{$root} {
        &__title {
          background-color: $g-color-gray;
        }

        &__child {
          background-color: rgba($g-color-secondary, 0.02);
        }
      }
    }

    &.is-disabled {
      pointer-events: initial;

      > #{$root} {
        &__title {
          opacity: 0.2;
        }
      }
    }

    #{$root} {
      &__child {
        #{$root} {
          &__toggle {
            left: $padding-layout + $offset - 8px;

            @include media-breakpoint-up(xl) {
              left: $padding-xl-layout + $offset - 8px;
            }
          }

          &__title {
            padding-left: $padding-layout + $offset + $icon-size;

            @include media-breakpoint-up(xl) {
              padding-left: $padding-xl-layout + $offset + $icon-size;
            }
          }

          &__child {
            #{$root} {
              &__toggle {
                left: $padding-layout + $offset * 2 - 8px;

                @include media-breakpoint-up(xl) {
                  left: $padding-xl-layout + $offset * 2 - 8px;
                }
              }

              &__title {
                padding-left: $padding-layout + $offset * 2 + $icon-size;

                @include media-breakpoint-up(xl) {
                  padding-left: $padding-xl-layout + $offset * 2 + $icon-size;
                }
              }

              &__child {
                #{$root} {
                  &__toggle {
                    left: $padding-layout + $offset * 3 - 8px;

                    @include media-breakpoint-up(xl) {
                      left: $padding-xl-layout + $offset * 3 - 8px;
                    }
                  }

                  &__title {
                    padding-left: $padding-layout + $offset * 3 + $icon-size;

                    @include media-breakpoint-up(xl) {
                      padding-left: $padding-xl-layout + $offset * 3 + $icon-size;
                    }
                  }

                  &__child {
                    #{$root} {
                      &__toggle {
                        left: $padding-layout + $offset * 4 - 8px;

                        @include media-breakpoint-up(xl) {
                          left: $padding-xl-layout + $offset * 4 - 8px;
                        }
                      }

                      &__title {
                        padding-left: $padding-layout + $offset * 4 + $icon-size;

                        @include media-breakpoint-up(xl) {
                          padding-left: $padding-xl-layout + $offset * 4 + $icon-size;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
