.c-mn-link {
  $root: &;

  #{$root} {
    &__line {
      stroke-width: 3;
      stroke: rgba($g-color-secondary, 0.2);
      @include h-general-transition(stroke);
    }

    &__midpoint {
      rect {
        fill: $g-color-map-notification-backdrop;
        stroke-width: 1;
        height: 18px;
        width: 70px;
      }

      text {
        alignment-baseline: center;
        stroke: transparent;
        fill: rgba($g-color-secondary, 0.2);
        text-anchor: middle;
        font-size: 12px;
        @include h-general-transition(fill);
      }
    }
  }

  &.active {
    #{$root} {
      &__line {
        stroke: $g-color-secondary;
      }

      &__midpoint {
        text {
          fill: $g-color-secondary;
        }
      }
    }
  }

  &.-simple-mode {
    #{$root} {
      &__midpoint {
        rect {
          width: 100px;
          stroke-width: 1px;
          stroke: $g-color-gray;
        }
      }
    }
  }

  &.-workflow {
    text {
      fill: $g-color-secondary;
      font-size: 15px;
    }
  }
}
