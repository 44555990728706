&--typed {
  display: flex;
  align-items: baseline;

  #{$root} {
    &__type {
      margin-right: 6px;
      padding: 0 4px;
      min-width: 30px;

      @include h-typo--additional;
      color: $g-color-white;
      font-weight: 700;

      border-radius: 2px;
    }

    &__name {
      @include h-typo--body-3;
      color: $g-color-secondary;
      font-weight: 700;
    }
  }

  &.-affiliate {
    #{$root} {
      &__type {
        background-color: $g-color-yellow;
      }
    }
  }

  &.-offer {
    #{$root} {
      &__type {
        background-color: $g-color-orange;
      }
    }
  }

  &.-funnel {
    #{$root} {
      &__type {
        background-color: $g-color-indigo-blue;
      }
    }
  }

  &.-day {
    #{$root} {
      &__type {
        background-color: $g-color-cerise;
      }
    }
  }

  &.-hour {
    #{$root} {
      &__type {
        background-color: $g-color-cerise;
      }
    }
  }

  &.-advertiser {
    #{$root} {
      &__type {
        background-color: $g-color-purple;
      }
    }
  }

  &.-fsm-state {
    #{$root} {
      &__type {
        background-color: $g-color-cyan;
      }
    }
  }

  &.-free {
    #{$root} {
      &__type {
        font-weight: 700;
        text-transform: uppercase;
        background-color: $g-color-secondary;
      }

      &__body {
        font-weight: 700;
        color: $g-color-secondary;
      }
    }
  }
}
