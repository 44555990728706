&.c-form-field--settings {
  width: 100%;

  #{$root} {
    &__inner {
      background: rgba(0, 14, 75, 0.02);
      border-radius: 5px;
      padding: 12px;
    }

    &__label {
      font-size: 12px;
      line-height: 18px;
      margin-right: 4px;
    }

    &__label-checkbox {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 14, 75, 0.5);
      padding-left: 22px;
      display: flex;
      @include h-general-transition(color);

      &:hover {
        color: rgba(0, 14, 75, 1);
      }

      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 5px;
        background: white;
        border: 1px solid #e6e6e6;
        margin-right: 6px;
      }

      &.checked:before {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #0f5ef7;
        background-color: #0f5ef7;
        font-size: 8px;
        line-height: 1em;
        color: white;
        content: '\e915';
        font-family: 'platform500' !important;
      }
    }
  }

  .c-dropdown__item-inner:hover {
    color: #0f5ef7;
    font-weight: bold;
    background: rgba(15, 94, 247, 0.07);
  }
}
