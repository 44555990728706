&--help {
  height: 100%;

  #{$root} {
    &__footer {
      padding: 0 38px 30px;
      justify-content: flex-start;
    }

    &__hint-icon {
      order: 2;
      font-size: 14px;
      margin: 0 0 10px 6px;
    }
  }
}
