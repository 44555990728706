&--pagination {
  #{$root} {
    &__inner {
      align-items: center;
    }

    &__prev,
    &__next {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 10px;
      color: $g-color-primary;
      @include h-general-transition(color);

      &:hover {
        color: $g-color-primary-hover;
      }
    }

    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }

    &__input {
      text-align: center;
      width: 100%;
      color: $g-color-secondary;
      @include h-typo--body-2;
      border-radius: 5px;
      border: solid 1px $g-color-gray;
      background-color: rgba($g-color-light-gray, 0.5);
      padding: 5px 26px;
      @include h-general-transition(box-shadow, border-color);

      &:focus,
      &:active {
        background-color: $g-color-white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        border-color: rgba($g-color-secondary, 0.2);

        & + #{$root}__label {
          opacity: 1;
        }
      }
    }
  }
}
