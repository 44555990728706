.text-color {
  &--red {
    color: $g-color-red;
  }

  &--green {
    color: $g-color-green;
  }

  &--green-2 {
    color: $g-color-green-hover;
  }

  &--gray {
    color: rgba($g-color-secondary, 0.5);
  }

  &--yellow {
    color: $g-color-yellow;
  }

  &--orange {
    color: $g-color-orange;
  }

  &--primary {
    color: $g-color-primary;
  }

  &--light-blue {
    color: rgba($g-color-primary, 0.2);
  }

  &--white {
    color: $g-color-white;
  }
}
