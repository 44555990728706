&--stories {
  #{$root} {
    &__body {
      padding: 22px 32px;
      border-radius: 0;
      border-width: 0;
      position: relative;
      background-color: $g-color-white;
      overflow-x: unset;
      margin: (-$y-main-content-mobile-padding) (-$x-main-content-mobile-padding);
      margin-bottom: 0;

      @include media-breakpoint-down(mx) {
        padding: 18px;
      }

      @include media-breakpoint-down(md) {
        background: url('../../../../assets/img/stories/figure.svg') no-repeat right top -60px, $g-color-white;
      }

      @include media-breakpoint-down(sm) {
        padding: 15px;
      }

      @include media-breakpoint-up(md) {
        margin: -$x-main-content-table-padding;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin: (-$y-main-content-padding + 6) (-$x-main-content-padding);
        margin-bottom: 0;
      }
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      margin-bottom: -30px;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap-reverse;
      }
    }

    &__profile {
      margin-bottom: 18px;
      margin-right: 20px;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      margin-bottom: 24px;
      overflow-x: auto;

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
      }
    }

    &__collapse-button {
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: rgba($g-color-primary, 0.1);
      color: $g-color-primary;
      border-radius: 8px;
      position: relative;
      font-size: 12px;
      font-weight: bold;
      @include h-general-transition(background-color, color);
      padding: 9px;
      gap: 6px;
      max-width: 100%;

      &:hover {
        background-color: $g-color-primary;
        color: $g-color-white;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__stories {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 25px;
      overflow-x: auto;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }

    &__story {
      width: 110px;
      height: 110px;
      flex-shrink: 0;
      box-sizing: content-box;
      border-radius: 20px;
      position: relative;
      padding: 3px;
      border: 2px solid transparent;
      margin-right: 15px;
      padding: 9px;
      cursor: pointer;
      @include h-general-transition(color, background-color);

      @include media-breakpoint-down(md) {
        width: 80px;
        height: 80px;
        padding: 2px;
        border-width: 2px;
        margin-bottom: 5px;
      }

      &:hover {
        background: rgba($g-color-primary, 0.1);
        border: 2px solid rgba($g-color-primary, 0.1);

        @include media-breakpoint-down(md) {
          transform: none;
        }
      }

      &--img-wrapper {
        position: relative;
        height: 100%;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 15px;
          background: linear-gradient(180deg, rgba(69, 188, 255, 0.4) 0%, #07224b 100%);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.-read {
        border-color: transparent;
      }

      .preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
      }

      .title {
        position: absolute;
        left: 21px;
        bottom: 17px;
        @include h-typo--body-2;
        font-weight: bold;
        color: $g-color-white;
        max-width: 60px;

        @include media-breakpoint-down(md) {
          @include h-typo--additional;
          font-weight: bold;
          left: 17px;
          bottom: 13px;
        }
      }
    }

    &__hello-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $g-color-secondary;
      border-radius: 7px;
      margin-right: 12px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 7px;
      }
    }

    &__welcome-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__welcome {
      @include h-typo--headline-1;
      font-weight: normal;
      white-space: nowrap;

      #{$root}__name {
        font-weight: 800;
      }
    }

    &__description {
      @include h-typo--body-1;
    }

    &__info-row {
      display: inline-flex;
      align-items: center;
      padding: 8px;
      border-radius: 5px;
      white-space: nowrap;
      @include h-typo--body-2;
      background: rgba($g-color-secondary, 0.03);
      border: 1px solid rgba($g-color-secondary, 0.03);

      .key {
        margin-right: 8px;
      }

      .value {
        color: rgba($g-color-secondary, 0.5);
        font-weight: bold;
        &.--primary {
          color: $g-color-primary;
        }
      }

      .field {
        display: flex;
        align-items: center;
      }

      .separator {
        margin: 0 18px;
        width: 1px;
        height: 20px;
        background-color: rgba($g-color-secondary, 0.1);
      }

      @include media-breakpoint-down(md) {
        display: inline-block;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border: none;

        .separator {
          display: none;
        }

        .field:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }

    &__collapsed-text {
      display: none;
      color: rgba($g-color-secondary, 0.5);
    }
  }

  @include media-breakpoint-up(mx) {
    &.-collapsed {
      #{$root} {
        &__body {
          padding-top: 19px;
          padding-bottom: 19px;
        }

        &__info-row,
        &__description,
        &__stories {
          display: none;
        }

        &__welcome-row {
          margin-bottom: 0;
        }

        &__collapsed-text {
          display: block;
          margin-left: 30px;
        }
      }
      p500-ui-icon {
        transform: rotate(180deg);
      }
    }
  }
}
