.c-button {
  $root: &;
  user-select: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  color: $g-color-secondary;
  @include h-general-transition;

  &__action {
    display: flex;
    // justify-content: center;
  }

  @import 'c-button--big-link';
  @import 'c-button--download';
  @import 'c-button--changelog';
}
