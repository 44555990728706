&--new-design {
  background-color: hsla(0, 0%, 94.5%, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;

  .c-modal {
    max-width: 890px;
    max-width: 890px;

    #{$root} {
      &__inner {
        height: 100%;
        position: relative;
        padding: $y-main-content-mobile-padding;
        box-sizing: border-box;
        background-color: $g-color-white;
        overflow-y: auto;
        border-radius: 0;

        @include media-breakpoint-up(sm) {
          overflow-y: unset;
          margin: 100px 0;
          border-radius: 20px;
          height: auto;
        }
      }

      &__body {
        padding: 0 15px 10px;
        margin-bottom: 65px;
        @include h-typo--body-2;
        @include h-scrolled;

        @include media-breakpoint-up(md) {
          padding: 0 38px 20px;
          margin-bottom: 0;
        }
      }

      &__date {
        color: $g-color-secondary;
        opacity: 0.3;
        font-size: 15px;
      }

      &__border {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed rgba($g-color-secondary, 0.05);
        border-radius: $g-value-border-radius-xl;
        padding: 10px;

        @include media-breakpoint-up(mx) {
          flex-direction: row;
        }
      }

      &__title {
        @include h-typo--headline-1;
        font-weight: 800;
        margin-bottom: 22px;
        margin-top: 30px;
      }

      &__subtitle {
        margin-top: 6px;
        margin-bottom: 14px;
        color: rgba($g-color-secondary, 0.5);
      }

      &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
        width: 40px;
        height: 40px;
        background-color: $g-color-white;
        opacity: 1;
        font-size: 14px;
        cursor: pointer;

        &-btn {
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.2;

          &:hover {
            opacity: 1;
            transition: opacity $g-value-transition;
          }
        }
      }

      &__button-continue {
        margin-top: 10px;
        @include h-typo--body-3;
        font-weight: 700;
      }

      &__message {
        max-width: 340px;
        margin: 0 auto 12px;
        margin-bottom: 36px;
      }

      &__buttons {
        display: flex;
      }

      &__svg {
        padding: 15px;
        max-width: 400px;
        min-height: 320px;
        width: auto;
        height: auto;
        display: block;
        margin: 0 auto;
        pointer-events: none;
        img {
          object-fit: contain;
          max-width: 100%;
        }
      }
    }
  }
}
