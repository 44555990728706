.c-logs {
  overflow: auto;

  &__inner {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
  }

  &__block {
    @include h-typo--body-2;
    display: flex;
    min-height: 156px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__date {
    flex-shrink: 0;
    display: block;
    padding-right: 48px;
    position: relative;
    white-space: nowrap;
    @include h-typo--body-3;

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: rgba($g-color-secondary, 0.3);
      right: 20px;
      top: 9px;
    }

    &-icon {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      font-size: 14px;
      color: rgba($g-color-secondary, 0.3);
    }
  }

  &__separator {
    margin-right: 50px;
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 5px solid rgba($g-color-secondary, 0.2);
      background-color: $g-color-white;
      margin-bottom: 10px;
      flex-shrink: 0;
      flex-grow: 1;
      &.-green {
        border-color: $g-color-green;
      }
      &.-red {
        border-color: $g-color-red;
      }
      &.-gray {
        border-color: $g-color-gray;
      }
    }

    &-line {
      height: 100%;
      width: 0px;
      border-left: 2px dashed rgba($g-color-secondary, 0.2);
    }
  }

  &__state {
    position: relative;
    margin-bottom: 10px;
    padding-left: 13px;

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid $g-color-primary;
      background-color: $g-color-white;
      left: 0px;
      top: 6px;
    }

    &.-orange {
      &:before {
        border-color: $g-color-yellow;
      }
    }

    &.-red {
      &:before {
        border-color: $g-color-red;
      }
    }

    &.-green {
      &:before {
        border-color: $g-color-green;
      }
    }
  }

  &__message {
    white-space: pre-wrap;

    &.-orange {
      padding: 8px 16px;
      background-color: rgba($g-color-yellow, 0.2);
      border-radius: $g-value-border-radius-xl;
    }

    &.-red {
      padding: 8px 16px;
      background-color: rgba($g-color-red, 0.08);
      border-radius: $g-value-border-radius-xl;
    }

    &.-green {
      padding: 8px 16px;
      background-color: rgba($g-color-green, 0.08);
      border-radius: $g-value-border-radius-xl;
    }
  }

  &__field-key {
    @include h-typo--additional;
    font-weight: bold;
    color: rgba($g-color-secondary, 0.3);
    margin-bottom: 2px;
  }

  &__field-value {
    @include h-typo--additional;
    color: rgba($g-color-secondary, 0.3);

    &.-bright {
      color: $g-color-secondary;
    }
  }

  &__tag {
    margin: 0 2px;
    padding: 0 5px;
    border-radius: 4px;
    font-weight: bold;

    &.-light {
      color: rgba($g-color-secondary, 0.5);
      background-color: rgba($g-color-secondary, 0.1);
    }

    &.-dark {
      color: $g-color-white;
      background-color: $g-color-secondary;
    }
  }

  &__row {
    display: flex;
    padding-bottom: 20px;

    &-block {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
