&--rotation-select {
  #{$root} {
    &__header {
      padding-left: 12px;
      padding-right: 30px + 12px;
      background: rgba($g-color-secondary, 0.05);
      border-radius: 5px;
      border: 1px solid $g-color-gray;
      color: $g-color-secondary;
      height: 30px;
      min-width: 83px;
      position: relative;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;

      &:before {
        font-family: 'media500' !important;
        content: '\e940';
        font-size: 4px;
        color: rgba($g-color-secondary, 0.3);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }
  }

  .p500-ui-dropdown--opened {
    #{$root}__header:before {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
