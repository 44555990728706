&--changelog {
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid $g-color-gray;

  #{$root} {
    &__inner {
      flex-direction: column;
      align-items: start;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__content-right {
      display: block;
      min-width: 100px;
      margin-top: 4px;
      margin-left: 0;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}
