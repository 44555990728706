&--story {
  height: 100%;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    display: block;
  }

  #{$root} {
    &__inner {
      background-color: #f2f3f6;
      box-shadow: none;
      border-radius: 20px;
      position: relative;
      height: 574px;
      width: 356px;
      overflow: hidden;
      box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.15);

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        background: linear-gradient(180deg, $g-color-secondary 0%, rgba($g-color-secondary, 0) 100%);
        opacity: 0.15;
      }

      &.-paused {
        #{$root}__bar {
          .item .fill {
            animation-play-state: paused;
          }
        }
      }

      @include media-breakpoint-down(xs) {
        height: 100%;
        width: 100vw;
        border-radius: 0;
      }
    }

    &__prev,
    &__next {
      margin: 0 30px;

      .icon {
        font-size: 30px;
        color: rgba($g-color-secondary, 0.5);
        @include h-general-transition();

        &:hover {
          color: $g-color-secondary;
        }
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &__prev-mobile {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
    }

    &__next-mobile {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
    }

    &__bar {
      position: absolute;
      top: 22px;
      left: 18px;
      right: 52px;
      height: 4px;
      display: flex;
      z-index: $z-overlay + 1;

      .item {
        background-color: rgba($g-color-white, 0.2);
        border-radius: 2px;
        overflow: hidden;
        flex: 1;

        &:not(:last-child) {
          margin-right: 14px;
        }

        .fill {
          background-color: $g-color-white;
          animation: progress 1 linear;
          height: 4px;
          width: 100%;
          transform-origin: left;
          display: none;
        }

        &.-current {
          .fill {
            display: block;
          }
        }

        &.-finished {
          .fill {
            display: block;
            transform: scaleX(100%);
            animation: none;
          }
        }
      }

      @keyframes progress {
        from {
          transform: scaleX(0);
        }
        to {
          transform: scaleX(100%);
        }
      }
    }

    &__close {
      padding: 10px;
      color: $g-color-white;
      position: absolute;
      font-size: 14px;
      display: block;
      right: 8px;
      top: 6px;
      z-index: $z-overlay + 1;
      cursor: pointer;
      @include h-general-transition(opacity);

      &:hover {
        opacity: 0.5;
      }
    }

    &__preloader {
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.-hidden {
        opacity: 0;
      }
    }

    &__content {
      padding: 30px 38px;
      background-color: $g-color-white;
      box-shadow: 0px -10px 150px rgba($g-color-secondary, 0.2);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__title {
      @include h-typo--headline-2;
      font-weight: bold;
      margin-bottom: 22px;
    }

    &__text {
      @include h-typo--body-2;
    }
  }
}
