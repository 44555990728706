.c-dropdown {
  $root: &;
  position: relative;

  @import 'c-dropdown--list';
  @import 'c-dropdown--leads-repusher';
  @import 'c-dropdown--list-table';
  @import 'c-dropdown--rotation-select';
  @import 'c-dropdown--tables';
  @import 'c-dropdown--menu';
}

.mobile-template {
  @include media-breakpoint-up(mx) {
    display: none;
  }
}

.desktop-template {
  display: none;
  @include media-breakpoint-up(mx) {
    display: block;
  }
}
