&.c-dropdown--menu {
  background: $g-color-white;
  box-shadow: 0px 10px 30px rgba(0, 14, 75, 0.15);
  border-radius: 10px;
  padding: 16px 32px;
  min-width: 210px;

  #{$root} {
    &__title {
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      color: rgba($g-color-secondary, 0.3);
      margin-bottom: 21px;
      text-transform: uppercase;
    }

    &__item {
      font-size: 15px;
      line-height: 22px;
      cursor: pointer;

      &:hover {
        color: $g-color-primary;
      }

      &.-active {
        color: $g-color-primary;
        font-weight: bold;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
