&--download {
  border-radius: $g-value-border-radius-xl;
  padding: 14px;
  display: block;

  #{$root} {
    &__title {
      margin-right: auto;
    }

    &__platform {
      margin-left: 10px;
      font-size: 18px;
      margin-top: -2px;
      @include h-general-transition(color);
    }

    &__head {
      display: flex;
      margin-bottom: 6px;
      font-weight: bold;
    }

    &__body {
      display: flex;
      align-items: center;
      @include h-typo--body-2;
    }

    &__icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  &.-blue {
    background-color: $g-color-light-blue;
    min-width: 194px;

    #{$root} {
      &__body {
        @include h-general-transition(color);
        color: $g-color-primary;
      }
    }

    &:hover {
      background-color: $g-color-primary;
      color: $g-color-white;

      #{$root} {
        &__body {
          color: $g-color-white;
        }
      }
    }
  }

  &.-white {
    color: $g-color-white;
    background-color: rgba($g-color-white, 0.1);
    min-width: 202px;

    #{$root} {
      &__platform {
        color: rgba($g-color-white, 0.3);
      }
    }

    &:hover {
      color: $g-color-primary;
      background-color: $g-color-white;

      #{$root} {
        &__platform {
          color: $g-color-primary;
        }
      }
    }
  }
}
