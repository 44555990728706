&--d {
  #{$root} {
    &__section {
      margin-bottom: 14px;

      &-checkbox {
        margin-top: 0;

        @include media-breakpoint-up(sm) {
          margin-top: 27px;
        }
      }
    }
  }
}
