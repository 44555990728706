&--status-logs {
  #{$root} {
    &__block {
      display: flex;
      flex-direction: column;
      border: 1px solid $g-color-gray;
      padding: 18px;
      max-width: 350px;
      border-radius: $g-value-border-radius-xl;
      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.-first {
        background-color: $g-color-primary;
        color: $g-color-white;

        #{$root} {
          &__date {
            color: $g-color-white;

            &-icon {
              color: $g-color-white;
            }
          }
          &__separator {
            &-line {
              border: 1px dashed rgba($g-color-white, 0.2);
            }
          }
          &__header {
            color: $g-color-white;
          }

          &__content {
            background-color: rgba($g-color-white, 0.1);
            &-state {
              border-left: 1px solid rgba($g-color-white, 0.1);
            }
          }
        }
      }
    }
    &__separator {
      display: flex;
      flex: 1;
      margin-right: 50px;
      align-items: center;
      position: relative;

      &-icon {
        width: 20px;
        position: absolute;
        height: 20px;
        left: 45%;

        &.-first {
          left: 0;
        }

        &.-last {
          left: 100%;
        }
        border-radius: 50%;
        border: 5px solid $g-color-green;
        background-color: $g-color-white;
        flex-shrink: 0;
        flex-grow: 1;
      }

      &-line {
        width: 100%;
        height: 1px;
        border: 1px dashed $g-color-gray;
      }
    }

    &__date {
      display: flex;
      white-space: nowrap;
      padding-right: 23px;

      &-icon {
        color: rgba($g-color-secondary, 0.3);

        &:before {
          content: '\e93c';
        }

        margin-top: 3px;
        margin-right: 12px;
        width: 15px;
        height: 16px;
      }

      &-body {
        font-size: 13px;
        line-height: 20px;
      }
    }

    &__box {
      display: flex;
    }

    &__header {
      width: 100%;
      display: flex;
      color: rgba($g-color-secondary, 0.3);
      padding-bottom: 15px;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-self: flex-end;

      &-text {
        font-size: 12px;
        white-space: nowrap;
      }
    }

    &__tooltip {
      margin-left: 4px;
      display: flex;
      align-items: center;
      .h-icon {
        &:hover {
          color: $g-color-secondary;
        }
      }
    }

    &__content {
      padding: 8px 10px;
      display: flex;
      background-color: rgba($g-color-secondary, 0.05);
      overflow: hidden;
      border-radius: $g-value-border-radius-xl;
      align-items: center;

      &-state {
        font-size: 22px;
        padding: 4px;
        line-height: 30px;
        white-space: nowrap;
        font-weight: 800;
        border-left: 1px solid rgba($g-color-secondary, 0.1);
        padding-left: 20px;
        padding-right: 106px;
      }
    }
    @media (min-width: 481px) and (max-width: 600px) {
      &__block {
        &:first-child {
          margin-left: 15px;
        }
      }
    }

    @media (max-width: 480px) {
      &__box {
        flex-direction: column-reverse;
        align-items: center;
        padding: 0 15px;

        padding-right: 5px;
      }

      &__block {
        flex-direction: row;
        align-items: unset;
        border-radius: $g-value-border-radius-xl;
        position: relative;
        margin: 0;
        padding: 15px 5px;
        width: 100%;
        max-width: 550px;
        margin-top: 34px;
        &:last-child {
          margin-right: 0;
          margin-top: 0;
        }
        &.-first {
          .c-widget__content {
            background: unset;
            &-state {
              border-left: none;
              background: rgba($g-color-white, 0.1);
            }
          }
        }
      }

      &__header {
        width: 30px;
        padding-bottom: 0;
        display: flex;
        padding: 10px 0;
        justify-content: center;
        margin-bottom: 0;
      }

      &__separator {
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        position: relative;
        flex-direction: column;
        &-line {
          width: 1px;
          border-width: 1px;
          height: 43px;
        }
        &-icon {
          position: absolute;
          left: -5px;
          &.-first {
            left: -5px;
            bottom: -5px;
          }
          &.-last {
            left: -5px;
            top: -5px;
          }
        }
      }

      &__tooltip {
        position: absolute;
        right: 18px;
        top: 25px;
      }

      &__action-text {
        display: none;
      }

      &__date {
        margin-bottom: 10px;
        &-body {
          span {
            .c-logs__date-time {
              margin-left: 10px;
            }
            display: flex;
            flex-direction: row;
          }
        }
      }

      &__content {
        align-items: unset;
        background: none;
        flex-direction: column;
        width: 100%;
        &-state {
          width: 100%;
          font-size: 20px;
          font-weight: bold;
          border-left: none;
          background: rgba($g-color-secondary, 0.05);
          border-radius: $g-value-border-radius-xl;
        }
      }
    }
  }
}
