.tooltip {
  $root: &;
  position: absolute;
  display: block;
  visibility: hidden;
  z-index: 2000;
  @include h-general-transition;
  @include fadeOut($duration: $g-value-animation-duration, $function: $g-value-animation-function);

  &--d {
    font-size: 11px;
    line-height: 12px;
    color: $g-color-white;
    background-color: $g-color-secondary;
    border-radius: 3px;
    padding: 4px;

    &:after {
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3px 3px 3px;
      border-color: transparent transparent $g-color-secondary transparent;
    }
  }

  &--full-content {
    word-wrap: break-word;
    word-break: break-all;
    max-width: 400px;
  }

  &--shown {
    visibility: visible;
    @include fadeIn($duration: $g-value-animation-duration, $function: $g-value-animation-function);
  }

  @import 'tooltip--hint';
}
