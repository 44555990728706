&--changelog {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: $g-color-white;
  overflow: hidden;
  margin-top: 10px;
  background-image: url('../../../../assets/new-img/changelog/changelog-button.svg');
  background-repeat: no-repeat;
  background-size: contain;

  #{$root} {
    &__text {
      @include h-typo--body-2;
      font-weight: bold;
    }
  }
}
