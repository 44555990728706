&--wiki {
  background-color: rgba($g-color-white, 0.2);
  padding: 20px;
  border-radius: $g-value-border-radius-xl;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba($g-color-white, 0.3);
  }

  #{$root} {
    &__title {
      @include h-typo--body-1;
      font-weight: bold;
      color: $g-color-white;
    }

    &__text {
      @include h-typo--body-2;
      color: rgba($g-color-white, 0.5);
      margin-top: 10px;
    }
  }

  &:after {
    content: '\e906';
    font-family: 'media500';
    display: inline-block;
    font-size: 14px;
    position: absolute;
    top: 20px;
    right: 17px;
  }
}
