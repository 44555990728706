iframe#jsd-widget {
  bottom: -29px !important;
  right: -19px !important;
  z-index: $z-helpdesk !important;
  display: none;

  @media (hover: hover) and (min-width: 340px) and (min-height: 530px) {
    display: block;
  }
}
