&--funnel-help-card {
  #{$root} {
    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px 60px;
      background-color: #f7f8fa;
      border-radius: $g-value-border-radius-xl;
      border-width: 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        width: 15px;
        height: 15px;
        background-color: $g-color-pattern-blue;
        border-bottom-right-radius: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;
        background-color: $g-color-secondary;
        border-bottom-left-radius: 15px;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    &__card-title {
      display: flex;
      margin-bottom: 10px;

      @include h-typo--body-1;
      font-weight: bold;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &__card-text {
      margin-right: 5px;
    }

    &__dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      align-self: center;
      margin-right: 20px;
      border-radius: 2px;
      background-color: rgba($g-color-secondary, 0.2);
      @media (max-width: 1480px) and (min-width: 1440px) {
        display: none;
      }
      @media (max-width: 1570px) and (min-width: 1439px) {
        margin-right: 10px;
      }
      @media (max-width: 320px) {
        display: none;
      }
    }

    &__text {
      @include h-typo--body-2;
      margin-bottom: 15px;
      min-width: 288px;
      @media (max-width: 350px) {
        min-width: unset;
      }
    }

    &__link {
      position: relative;
      display: flex;
      @include h-typo--body-2;
      font-weight: bold;

      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 1570px) and (min-width: 1439px) {
          margin-right: 10px;
        }
      }
    }

    &__icon {
      margin-right: 10px;
      font-size: 18px;
      vertical-align: sub;
    }
  }
}
