&--hr {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
  position: relative;
  white-space: nowrap;
  z-index: 1;

  #{$root} {
    &__progress {
      width: 40px;
      height: 6px;
      background-color: $g-color-yellow;
      display: block;
      border-radius: 5px;
    }

    &__title {
      color: $g-color-secondary;
      margin-right: 10px;
    }

    &__container {
      width: 40px;
      height: 6px;
      display: flex;
      background-color: rgba($g-color-gray, 0.5);
      border-radius: 5px;
      overflow: hidden;
    }
  }
}
