.c-top-bar {
  width: 100%;
  height: $height-top-bar;
  padding: 12px 15px;
  display: flex;
  top: 0;
  background-color: $g-natural-light-gray;
  border-bottom: 1px solid $g-color-gray;

  @include media-breakpoint-up(sm) {
    padding: 18px;
    border-bottom: none;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &__inner {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 22px;
    font-weight: 800;
    padding-top: 2px;
    line-height: 30px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__id {
    opacity: 0.3;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
    }
  }

  &__separator {
    margin: 0 5px 0 10px;
    width: 4px;
    height: 4px;
    background-color: $g-color-secondary;
    opacity: 0.3;
    border-radius: 50%;
  }

  &__toggle {
    flex: none;
    margin-left: auto;

    &--rects {
      width: 10px;
      height: 10px;
      position: relative;

      .top {
        top: 0;
      }

      .bottom {
        top: 8px;
      }
    }

    &--rect {
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: white;
      border-radius: 2px;
      border: none;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__action {
    margin-right: 28px;
    display: none;

    &--profile {
      margin-right: 21px;
    }

    @include media-breakpoint-up(sm) {
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__mobile {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-left: auto;
    }
  }

  &__mobile-stub {
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      width: 28px;
      height: 28px;
      margin-left: 20px;
      background-color: #000e4b10;
      justify-content: center;
      transition: all 0.3s;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      position: relative;
      border-radius: 10px;
    }
  }
}
