&--filters {
  #{$root} {
    &__body {
      background-color: rgba($g-color-primary, 0.05);
      border: none;
      display: flex;
    }

    &__body-container {
      overflow: visible;
    }
  }

  .c-line--horizontal {
    margin-bottom: 14px;
  }

  .c-line--split-label {
    margin-left: 20px;
    margin-right: 20px;
  }
}
