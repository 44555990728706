.c-tags {
  $root: &;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__tag {
    display: inline-block;
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold;
    margin-right: 4px;
    padding: 0 6px;
  }

  &--primary {
    #{$root} {
      &__tag {
        color: $g-color-primary;
        background-color: $g-color-light-blue;
      }
    }
  }

  &--green {
    #{$root} {
      &__tag {
        color: $g-color-white;
        background-color: $g-color-green-hover;
      }
    }
  }

  &--yellow {
    #{$root} {
      &__tag {
        color: $g-color-white;
        background-color: $g-color-yellow;
      }
    }
  }

  &--red {
    #{$root} {
      &__tag {
        color: $g-color-white;
        background-color: $g-color-red;
      }
    }
  }

  &--gray {
    #{$root} {
      &__tag {
        color: rgba($g-color-secondary, 0.5);
        background-color: rgba($g-color-secondary, 0.05);
      }
    }
  }

  &--secondary {
    #{$root} {
      &__tag {
        margin-bottom: 5px;
        color: $g-color-secondary;
        background-color: rgba($g-color-secondary, 0.08);
      }
    }
  }

  &--orange {
    #{$root} {
      &__tag {
        background-color: $g-color-orange;
        color: $g-color-white;
      }
    }
  }

  &--sale-status {
    #{$root} {
      &__tag {
        margin-right: 6px;
        margin-bottom: 2px;
        color: $g-color-secondary;
        background-color: $g-natural-light-gray;
        font-weight: normal;
      }
    }

    &.-unassigned {
      #{$root} {
        &__tag {
          font-weight: bold;
          color: rgba($g-color-secondary, 0.5);
          background-color: rgba($g-color-secondary, 0.05);
        }
      }
    }
  }
}
