.c-rotation-trees-item {
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  &__inner {
    width: 100%;
    padding: 18px;
    display: flex;
  }

  &__left {
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__body {
  }

  &__right {
    flex-shrink: 0;
    padding-left: 20px;
    display: flex;
    margin-left: auto;
  }

  &__header {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  &__footer {
  }

  &__title {
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
  }

  &__icon {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  &__fill-first {
    margin: 0 auto;
    width: 12px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/img/rotation-trees/ico/fill-first.svg');
  }

  &__info {
    margin-left: 20px;
    position: relative;
    display: flex;
    color: rgba($g-color-secondary, 0.5);
    z-index: 1;
  }

  &__actions {
    margin-left: auto;

    @include media-breakpoint-up(xl) {
      margin-left: 0;
    }
  }

  &__action {
    margin-right: 8px;

    .h-icon-edit {
      &:before {
        padding: 1px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__info {
    margin-right: 30px;
  }

  &:hover {
    background: rgba($g-color-primary, 0.1);

    button[p500-ui-circled-button] {
      background-color: $g-color-primary;
      opacity: 1;
    }

    .c-rotation-trees-item__info {
      .c-progress-bar__container {
        background: $g-color-white;
        @include h-general-transition();
      }
    }
  }

  &.is-inactive {
    opacity: 0.2;
  }
}
