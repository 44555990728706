.c-drilldown-data {
  $root: &;
  &__table {
    position: relative;

    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;

    text-align: left;
    font-size: 13px;
    line-height: 17px;

    background-color: $g-color-white;

    overscroll-behavior: contain;
  }

  &__empty {
    border: 1px solid $g-color-gray;
    border-radius: 8px;
  }

  &__header {
    position: sticky;
    top: -24px;
    z-index: 1;

    border-radius: 8px;

    #{$root} {
      &__scroll {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        &.-scrolled {
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          #{$root} {
            &__th {
              &:first-child {
                border-top-left-radius: 0;
              }

              &:last-child {
                border-top-right-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &__scroll {
    display: flex;

    overflow: hidden;
  }

  &__body {
    padding-bottom: 37px;

    overflow: auto;

    .report-row:nth-child(2n) > .c-drilldown-data__tr-level-0 {
      #{$root} {
        &__td {
          background-color: rgba($color: $g-color-secondary, $alpha: 0.02);
        }
      }
    }

    .has-child.is-opened {
      #{$root} {
        &__tr-child {
          display: block;

          .has-child.is-opened {
            .report-row:nth-of-type(2n) {
              #{$root} {
                &__tr {
                  #{$root} {
                    &__td {
                      background-color: rgba($color: $g-color-secondary, $alpha: 0.03);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .has-child.is-opened > {
      #{$root} {
        &__tr {
          #{$root} {
            &__td {
              color: $g-color-white;

              background-color: $g-color-secondary;

              &:first-child {
                &::before {
                  top: 5px;

                  color: $g-color-white;

                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }

    .has-child > {
      #{$root} {
        &__tr > {
          #{$root} {
            &__td:first-child {
              &::before {
                content: '\e935';

                position: absolute;
                top: 9px;
                left: 8px;

                width: 12px;

                font-family: media500;
                font-size: 12px;
                color: $g-color-primary;
              }
            }
          }
        }
      }
    }
  }

  &__tr {
    position: relative;

    display: flex;

    cursor: pointer;

    &-level-* {
      position: relative;
    }

    &-level-1 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 240px;
          min-width: 240px;
          padding-left: 34px;

          &::before {
            left: 17px !important;
          }
        }
      }
    }

    &-level-2 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 230px;
          min-width: 230px;
          padding-left: 44px;

          &::before {
            left: 27px !important;
          }
        }
      }
    }

    &-level-3 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 220px;
          min-width: 220px;
          padding-left: 54px;

          &::before {
            left: 37px !important;
          }
        }
      }
    }

    &-level-4 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 210px;
          min-width: 210px;
          padding-left: 64px;

          &::before {
            left: 47px !important;
          }
        }
      }
    }

    &-level-5 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 200px;
          min-width: 200px;
          padding-left: 74px;

          &::before {
            left: 57px !important;
          }
        }
      }
    }

    &-child {
      position: relative;
      display: none;
    }
  }

  &__th {
    box-sizing: content-box;
    flex-grow: 1;
    flex-basis: 250px;
    min-width: 120px;
    padding: 14px 8px;
    padding-left: 24px;

    font-weight: 300;
    word-break: break-word;
    text-align: right;
    color: $g-color-secondary;

    border: 1px solid $g-color-gray;
    border-left: none;
    background-color: $g-color-light-blue;

    &:first-child {
      flex-grow: 2.5;
      min-width: 250px;

      text-align: left;

      border-left: 1px solid $g-color-gray;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  &__td {
    position: relative;
    z-index: 0;

    box-sizing: content-box;
    flex-grow: 1;
    flex-basis: 250px;
    display: block;
    min-width: 120px;
    padding: 9px 8px;
    padding-left: 24px;

    word-break: break-word;
    text-align: right;

    border-right: 1px solid $g-color-gray;

    &:first-child {
      flex-grow: 2.5;
      min-width: 250px;

      text-align: left;

      border-left: 1px solid $g-color-gray;
    }
  }

  &__footer {
    position: sticky;
    bottom: -24px;
    z-index: 1;

    border-radius: 8px;

    #{$root} {
      &__scroll {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &.-scrolled {
          box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.15);
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          #{$root} {
            &__td {
              &:first-child {
                border-bottom-left-radius: 0;
              }

              &:last-child {
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }

      &__td {
        font-weight: 700;

        background-color: $g-color-white;
        border-top: 1px solid $g-color-gray;
        border-bottom: 1px solid $g-color-gray;

        &:first-child {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
