.c-rotation-manager {
  $root: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
  }

  &__body {
    padding-top: 20px;
    position: relative;
    flex: 1 1 100%;
    overflow: scroll;
    background-color: rgba($g-color-secondary, 0.03);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      padding: 20px 32px 0;
    }

    &.is-empty {
      padding: 0;
      display: flex;
      align-items: center;

      #{$root} {
        &__items {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__icon {
          display: none;
        }
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    padding-top: 16px;
  }

  &__items {
    flex: 1 1 100%;
  }

  &__item {
    border-bottom: 1px solid rgba($g-color-gray, 0.4);

    &:last-child {
      border-bottom: 1px solid transparent;
    }

    &.cdk-drag-placeholder {
      opacity: 0.1;
      border: 1px dashed rgba($g-color-gray, 0.7);
    }

    &.cdk-drag-preview {
      border: none;
    }

    &:hover {
      border-color: transparent;

      + #{$root}__item {
        border-top-color: transparent;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    th {
      padding: 0 14px 9px;
      @include h-typo--additional;
      font-weight: 800;
      text-transform: uppercase;
      color: rgba($g-color-secondary, 0.3);
    }
  }
}
