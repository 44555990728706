&--key-value {
  #{$root} {
    &__icon {
      margin-right: 6px;
      font-size: 12px;
    }
  }

  &--tags {
    &__actions {
      display: inline-flex;
      float: right;
      padding-right: 5px;
    }

    &__action {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    table {
      .empty {
        color: rgba($g-color-secondary, 0.3) !important;
      }
    }
  }

  table {
    background-color: rgba($g-color-secondary, 0.02);
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    th,
    td {
      padding: 0;
    }

    .empty {
      color: rgba($g-color-secondary, 0.1);
    }

    thead {
      th {
        padding: 0 20px;
        font-size: 13px;
        font-weight: bold;
        color: rgba($g-color-secondary, 0.5);
        position: relative;
        background-color: transparent;
        border-right: 1px solid $g-color-gray;
        border-top: none;

        .content-wrapper {
          padding: 16px 0 9px;
          border-bottom: none;
          display: flex;
          align-items: baseline;

          @include media-breakpoint-up(sm) {
            align-items: center;
          }
        }

        &:not(:last-of-type) {
          width: 180px;
        }

        &:last-of-type {
          padding-right: 18px;
          background-color: $g-color-white;
          border-right: none;
          border-top: none;
          border-top-right-radius: 10px;

          .content-wrapper {
            border-top: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          @include h-typo--body-2;

          padding: 0 20px;
          background-color: transparent;
          border-right: 1px solid $g-color-gray;
          border-top: 1px solid rgba($g-color-gray, 0.4);
          &:last-child {
            padding-right: 0;
          }

          .content-wrapper {
            padding: 8px 0;
            border-bottom: none;
          }

          &:nth-last-of-type(3) {
            padding: 0 20px;
            text-align: left;
            vertical-align: top;
          }

          &:last-of-type {
            padding-left: 0;
            background-color: $g-color-white;
            border-right: none;
            border-top: none;

            .content-wrapper {
              padding-left: 20px;
              border-top: 1px solid rgba($g-color-gray, 0.4);
            }
          }

          &[rowspan] {
            font-weight: bold;
          }
        }

        &:first-of-type {
          td {
            border-top: 1px solid $g-color-gray;

            &:last-of-type {
              border-top: none;

              .content-wrapper {
                border-top: 1px solid $g-color-gray;
                white-space: normal;
              }
            }
          }
        }

        &:last-of-type {
          td:last-of-type {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    font-size: 13px;

    .key {
      font-weight: bold;
      width: 200px;
      padding: 16px 28px;
      padding-right: 0;
      flex-shrink: 0;

      @include media-breakpoint-down(sm) {
        flex-shrink: unset;
      }
    }

    .value {
      padding: 16px 38px;
    }
  }
}
