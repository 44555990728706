.c-table {
  $root: &;
  position: relative;
  z-index: $z-table;
  display: flex;
  flex-direction: column;

  .ui-table-limit-sting-length {
    display: block;
    max-width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__border {
    border: 1px solid rgba($g-color-gray, 0.6);
  }

  &__header {
    @include h-typo--body-2;
    top: -$y-main-content-mobile-padding;
    padding-top: $y-main-content-mobile-padding;
    margin-top: -$y-main-content-mobile-padding;
    left: 0;
    background-color: $g-color-white;
    z-index: 31;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      padding-top: $y-main-content-padding;
      margin-top: -$y-main-content-padding;
      top: -$y-main-content-padding;
      position: sticky;
      position: -webkit-sticky;
    }

    #{$root} {
      &__content-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;

        @include media-breakpoint-up(sm) {
          padding-bottom: 18px;
        }
      }

      &__content-left {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        width: auto;
        min-height: 43px;

        .p500-ui-circled-button .p500-ui-icon-controls-refresh {
          display: none;
        }

        @include media-breakpoint-up(sm) {
          margin-right: 20px;
          padding-bottom: 18px;

          .p500-ui-circled-button .p500-ui-icon-controls-refresh {
            display: inherit;
          }
        }
      }
    }
    @include media-breakpoint-down(mx) {
      min-width: 0;
    }
  }

  &__hint-button {
    position: relative;
    top: -8px;
    left: -10px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__body {
    position: relative;
    overflow-x: scroll;
    margin-left: -$x-main-content-mobile-padding;
    margin-right: -$x-main-content-mobile-padding;
    @include h-scrolled;

    &--empty {
      flex-grow: 1;

      &-item {
        padding-top: 60px;
      }

      table {
        min-width: 988px;
        height: 100%;
      }
    }

    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }

  &__empty-block {
    border-bottom: 1px solid $g-color-gray;
    border-left: 1px solid $g-color-gray;
    border-right: 1px solid $g-color-gray;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40%;
    margin-top: calc(1% - 33px);
    padding-top: 33px;
  }

  &__empty-title {
    display: none;
  }

  &__empty {
    display: flex;
    justify-content: center;

    #{$root} {
      &__empty-item {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__add-button {
        height: 26px;
        display: block;
      }

      &__empty-title {
        display: initial;
      }
    }

    table {
      height: 100%;
    }

    .c-button__action {
      justify-content: center;
    }
  }

  &__icon {
    display: inline-block;
    margin-bottom: 20px;
  }

  &__description {
    line-height: 1.54;
    max-width: 226px;
    margin-bottom: 14px;
    text-align: center;
    white-space: pre-wrap;
  }

  &__title {
    @include h-typo--headline-1;
    margin-right: 15px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__add-button {
    margin-right: 12px;
  }

  &__counter {
    margin-right: 28px;

    @include media-breakpoint-down(md) {
      display: none;

      &--mobile {
        display: block;
        margin-right: 28px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &__reset-filters {
    margin-right: 28px;
  }

  &__settings {
    &-inner {
      right: 32px;
      z-index: 2;
      display: none !important;

      &.is-open {
        display: block !important;
      }
    }
  }

  &__pagination {
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }
  }

  &__filters {
    margin-right: 10px;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
  }

  &__search {
    display: block;
    margin-bottom: $y-main-content-mobile-padding;
    width: 100%;
    order: 1;

    @include media-breakpoint-up(sm) {
      display: none;
      margin-bottom: 0;
      order: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 230px;
      margin-right: 10px;
      display: block;
    }

    &-mobile {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  table {
    white-space: nowrap;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;

    &.-full-height {
      height: 100%;
    }

    thead {
      background-color: $g-color-white;
      z-index: 10;

      th {
        font-size: 13px;
        font-weight: bold;
        color: rgba($g-color-secondary, 0.3);
        border-bottom: 1px solid $g-color-gray;
        position: relative;
        padding: 8px 10px;

        @include media-breakpoint-up(sm) {
          padding: 0 10px 8px 10px;
        }

        &:first-child {
          padding-left: 5px;
          @include media-breakpoint-up(sm) {
            padding-left: 0;
          }
          padding-right: 15px;
        }

        .th__title {
          font-family: var(--g-font-table, #{$g-font-base});
        }

        .th__content {
          display: flex;
        }

        .th__hint-header {
          padding-left: 4px;
          display: flex;
          align-items: center;
        }

        .th__hint-question {
          border-radius: 50%;
          width: 16px;
          height: 16px;
          font-size: 12px;
          line-height: 15px;
          color: $g-color-secondary;
          background-color: rgba($g-color-secondary, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          user-select: none;

          @include h-general-transition(color, background-color);

          &:hover {
            background-color: $g-color-secondary;
            color: $g-natural-light-gray;
          }
        }

        .th__hint-body {
          display: none;
          position: absolute;
          top: calc(100% + 2px);
          z-index: 20;
          left: 50%;
          transform: translateX(-50%);
        }

        .th__hint {
          position: relative;
          display: flex;
          align-items: center;
        }

        .th__hint:hover {
          > .th__hint-body {
            display: flex;
          }
        }

        &.order {
          display: flex;

          .th__content {
            position: relative;

            &::before {
              position: absolute;
              right: -18px;
              font-size: 6px;
              content: '\e941';
              font-family: 'media500';
            }
          }

          &--asc {
            .th__content {
              color: $g-color-secondary;
            }
          }

          &--desc {
            .th__content {
              color: $g-color-secondary;
              &::before {
                content: '\e940';
              }
            }
          }
        }
      }
    }

    tr {
      td {
        font-family: var(--g-font-table, #{$g-font-base});
        position: relative;
        @include h-typo--body-2;
        border-bottom: 1px solid rgba($g-color-gray, 0.4);
        padding: 7px 10px;

        &:first-child {
          padding-left: 15px;

          @include media-breakpoint-up(sm) {
            padding-left: 10px;
          }
        }

        &:last-child {
          border-right: none;
        }

        &.td--first {
          padding-left: 16px;
        }

        .status {
          position: absolute;
          top: 0;
          left: 0;

          width: 4px;
          height: 100%;

          background-color: rgba($color: $g-color-secondary, $alpha: 0.2);

          &.--green {
            background-color: $g-color-green;
          }

          &.--red {
            background-color: $g-color-red;
          }

          &.--orange {
            background-color: $g-color-orange;
          }

          &.--yellow {
            background-color: $g-color-yellow;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      &:nth-child(even) {
        background-color: rgba($g-color-secondary, 0.02);
      }

      &:hover {
        .p500-ui-circled-button {
          background-color: $g-color-primary;
          opacity: 1;

          &.p500-ui-circled-button--warn {
            background-color: $g-color-red;
          }
        }
      }
    }
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($g-color-white, 0.7);
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    .c-preloader {
      position: absolute;
      top: 50%;
      margin: 0;
    }
  }

  @import 'c-table--accordion';
  @import 'c-table--tool';
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
