&--big-link {
  color: $g-color-primary;
  @include h-typo--headline-2;
  font-weight: bold;

  #{$root} {
    &__icon {
      width: 24px;
      height: 24px;
      border: 1px solid $g-color-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 10px;
      margin-left: 8px;
      @include h-general-transition(border-color);
    }
  }

  &:hover {
    color: $g-color-primary-hover;

    #{$root} {
      &__icon {
        border-color: $g-color-primary-hover;
      }
    }
  }
}
