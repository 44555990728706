&--statistic {
  overflow-x: scroll;

  &.--no-scroll {
    overflow: hidden;
  }

  &--is--loading {
    display: none;
  }

  &.-primary {
    background: linear-gradient(90deg, #000932 0%, #17286f 100%);

    #{$root} {
      &__item {
        padding: 18px 24px 11px;
        border-color: rgba($g-color-white, 0.2);
        min-width: 160px;
      }

      &__key {
        color: rgba($g-color-white, 0.7);
        margin-bottom: 6px;
        display: flex;
        align-items: center;

        .icon {
          font-size: 14px;
          color: rgba($g-color-white, 0.7);
          margin-right: 8px;
        }
      }

      &__value {
        font-size: 22px;
        line-height: 30px;
        font-weight: 800;
        color: $g-color-white;

        .c-field-value__text {
          color: $g-color-white;
        }
      }

      &__icon {
        display: none;
      }

      &__button {
        color: $g-color-white;
      }
    }
  }

  &__circles {
    display: none;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
      margin-top: 14px;
    }
  }

  &__circle {
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(15, 94, 247, 0.07);

    @include media-breakpoint-down(md) {
      display: inline-block;
    }

    &:nth-child(1) {
      margin-left: 8px;
    }

    &--selected {
      background-color: $g-color-secondary;
    }
  }

  #{$root} {
    &__inner {
      display: flex;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        height: 94px;
      }
    }

    &__main {
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__content-left {
      display: flex;
      align-items: center;
    }

    &__item {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      position: relative;
      padding: 20px 24px 12px;
      min-width: 200px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 20%;
        bottom: 20%;
        border-right: 1px solid rgba($g-color-primary, 0.1);
      }

      &:last-child {
        border: none;
        padding-right: 60px;
      }

      &:hover {
        #{$root}__tooltip {
          opacity: 1;
        }
      }

      @include media-breakpoint-down(md) {
        position: static;
        display: none;
        border-right: none;
        margin: 0 auto;
        padding: 0;
        min-width: auto;

        &:last-child {
          padding-right: 0;
        }

        &--opened {
          display: flex;
        }

        #{$root}__content-right {
          display: none;
        }
      }

      &.-link {
        &:hover {
          cursor: pointer;

          #{$root}__arrow {
            opacity: 1;
          }
        }
      }
    }

    &__arrow {
      opacity: 0;
      font-size: 8px;
      margin-left: 10px;
      @include h-general-transition(opacity);
    }

    &__key {
      @include h-typo--body-1;
      white-space: nowrap;
      font-weight: bold;
      color: rgba($g-color-secondary, 0.5);
      @include h-general-transition(color);

      @include media-breakpoint-down(md) {
        max-width: 90px;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
    }

    &__value {
      @include h-typo--headline-1;
      .c-field-value__text {
        color: $g-color-secondary;
      }
    }

    &__recent {
      font-size: 20px;
      opacity: 0.3;
    }

    &__icon {
      width: 50px;
      height: 50px;
      border-radius: $g-value-border-radius-xl;
      background-color: rgba($g-color-secondary, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 18px;
      font-size: 26px;

      @media (max-width: 365px) {
        margin-right: 10px;
      }
    }

    &__tooltip {
      height: 20px;
      width: 20px;
      position: absolute;
      top: 17px;
      right: 24px;
      opacity: 0;
      background-color: $g-natural-light-gray;
      color: $g-color-secondary;
      @include h-general-transition;

      &:hover {
        background-color: $g-color-secondary;
        color: $g-color-white;
      }

      @include media-breakpoint-down(md) {
        opacity: 1;
        top: 64px !important;
        right: 19px;
      }
    }

    &__button {
      &.-prev {
        margin-left: 19px;
      }

      &.-next {
        margin-right: 19px;
      }
    }
  }
}
