&--deal-info {
  .deal-info-row {
    display: flex;
    align-items: flex-start;

    .info {
      .data-row {
        display: block;
        margin-bottom: 6px;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0;
        color: rgba($g-color-secondary, 0.5);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 6px;
      }

      .data {
        // @include text-main;
      }
    }

    .count {
      color: $g-color-primary !important;
      margin-left: auto;
      padding: 28px 30px;
      background-color: rgba($g-color-secondary, 0.02);
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      font-weight: bold;
      border-radius: 5px;

      span {
        font-size: 28px;
      }
    }
  }

  #{$root} {
    &__body {
      margin-bottom: 20px;
    }
  }
}
