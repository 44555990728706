.l-map-notification {
  $root: &;
  width: 100%;

  &__inner {
    background: rgba(0, 14, 75, 0.02);
    width: 100%;
    position: relative;
    min-height: 700px;
    height: calc(100vh - 120px);
    display: block;
    border-radius: 5px;
    overflow: hidden;
  }

  &__top {
    position: absolute;
    top: 14px;
    left: 14px;
    right: 14px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__menu {
    &:not(:last-child) {
      margin-bottom: 14px;
      z-index: 1;
    }
  }

  &__settings {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }

  &__banner {
    z-index: 1;
    width: 100%;
  }

  &__info {
    z-index: 1;
  }

  &__menu-section {
    z-index: 1;
  }

  .mobile-template {
    .l-map-notification {
      &__inner {
        display: flex;
        flex-direction: column;
      }

      &__top {
        position: static;
        top: 0;
        left: 0;
        right: 0;
        margin-bottom: 5px;
        padding: 15px;

        height: 65px;

        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        background-color: $g-color-light-blue;
        z-index: 1;
      }

      &__settings {
        position: static;
      }
    }
    .c-mn-menu-mobile {
      position: absolute;
      top: 25%;
      left: 50%;

      &__inner {
        position: relative;
        left: -50%;
        min-width: 230px;
        max-width: 250px;
        border: 1px solid rgba($g-color-primary, 0.1);
        padding: 18px;
        border-radius: 5px;
        background-color: $g-color-white;
        box-shadow: 0px 0px 39px 0px rgba(34, 60, 80, 0.2);
      }

      &__title {
        line-height: 20px;
        font-weight: 800;
        margin-bottom: 8px;
        text-transform: uppercase;
      }

      &__subtitle {
        margin-bottom: 8px;
        font-weight: bold;
        line-height: 22px;
      }

      &__buttons {
        display: flex;
      }

      &__btn {
        align-items: center;
        display: flex;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      &__separator {
        width: 100%;
        height: 1px;
        background: rgba($g-color-secondary, 0.1);
        margin-top: 14px;
        margin-bottom: 14px;
      }

      &__path {
        &-block {
          display: flex;
        }

        &-block:not(:last-child) {
          margin-bottom: 14px;
        }

        &-title {
          font-size: 13px;
          color: rgba($g-color-secondary, 0.3);
          margin-right: 10px;
        }

        &-node {
          font-weight: bold;
          font-size: 13px;
          line-height: 22px;

          &.-primary {
            color: $g-color-primary;
          }
        }
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 15px;
        color: $g-color-gray;
      }
    }
  }
}
