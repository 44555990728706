.c-pagination {
  display: flex;
  align-items: center;
  font-size: 13px;

  &__value {
    background: none;
    padding: 0;
    border: none;
    text-align: center;
    width: 25px;
    margin-right: 12px;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__title {
    margin-right: 10px;
  }

  &__toggle {
    margin-right: 10px;
    width: 83px;
  }
}
