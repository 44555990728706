&--d {
  background-color: $g-color-secondary;
  color: $g-color-white;
  display: inline-flex;
  border-radius: 5px;
  padding: 0px 6px;
  @include h-typo--additional;

  &#{$root}--color {
    &--red,
    &--danger,
    &--default {
      background-color: $g-color-red;
    }

    &--green,
    &--success {
      background-color: $g-color-green;
    }
    &--primary,
    &--info {
      background-color: $g-color-primary;
    }

    &--yellow,
    &--offer-country,
    &--warning {
      background-color: $g-color-yellow;
    }

    &--black {
      background-color: $g-color-black;
    }

    &--cyan,
    &--warning-3,
    &--country {
      background-color: $g-color-cyan;
    }

    &--cerise,
    &--affiliate-aff-sub {
      background-color: $g-color-cerise;
    }

    &--purple,
    &--affiliate-country {
      background-color: $g-color-purple;
    }

    &--indigo-blue,
    &--affiliate {
      background-color: $g-color-indigo-blue;
    }

    &--offer {
      background-color: $g-color-dark-cyan-2;
    }

    &--tooltip-header {
      background-color: $g-color-secondary;
      color: $g-color-white;

      &:hover {
        background-color: $g-color-white;
        color: $g-color-secondary;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }

    &--orange {
      background-color: $g-color-orange;
    }

    &--gray {
      background-color: $g-color-gray;
      color: rgba($g-color-secondary, 0.5);
    }
  }
}
