@mixin h-typo--headline-1 {
  font-size: 28px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
}

@mixin h-typo--headline-2 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
}

@mixin h-typo--body-1 {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
}

@mixin h-typo--body-2 {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
}

@mixin h-typo--body-3 {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
}

@mixin h-typo--additional {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
}
