.c-formula {
  &-field {
    margin: 0 5px;
    position: relative;
    background-color: transparent;
    z-index: 1;
    border-radius: 4px;
    padding: 1px;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      border-radius: 4px;
      left: -2px;
      right: -2px;
      z-index: -1;
    }

    &.-dark {
      &:before {
        box-shadow: -5px 0px 30px rgba(0, 0, 0, 0.05);
        background: rgba(0, 14, 75, 0.1);
      }
    }

    &.-cyan {
      &:before {
        background-color: #97ffec;
      }
    }

    &.-violet {
      &:before {
        background-color: rgba(38, 23, 216, 0.15);
      }
    }

    &.-orange {
      &:before {
        background-color: rgba(240, 86, 14, 0.2);
      }
    }

    &.-yellow {
      &:before {
        background-color: #f5ff82;
      }
    }

    &.-pink {
      &:before {
        background-color: rgba(214, 19, 142, 0.2);
      }
    }

    &.-green {
      &:before {
        background-color: rgba(15, 216, 187, 0.4);
      }
    }

    &.-darkturquoise {
      &:before {
        background-color: #bbcaff;
      }
    }

    &.-coral {
      &:before {
        background-color: #ffc4c4;
      }
    }

    &.-aqua {
      &:before {
        background-color: #b4f6ff;
      }
    }

    &.-greenyellow {
      &:before {
        background-color: rgba(32, 181, 6, 0.3);
      }
    }
  }
}
