/**
 * Variables
 */

// Global Colors
$g-color-primary: #0f5ef7;
$g-color-primary-hover: #0d54dd;
$g-color-secondary: #000932;
$g-color-white: #ffffff;
$g-color-black: #000000;
$g-color-bg: #fcfcfc;
$g-color-sidebar-bg: #f7f9fa;
$g-color-gray: #e6e6e6;
$g-color-light-gray: #f1f1f1;
$g-color-light-gray-2: #f2f2f3;
$g-color-light-blue: #eef5ff;
$g-color-pale-blue: #f7f8fa;
$g-color-red: #f70f0f;
$g-color-red-no-opacity: #ffecec;
$g-color-red-hover: #dd0d0d;
$g-color-orange: #f0560e;
$g-color-yellow: #f8ae6a;
$g-color-green: #20b506;
$g-color-green-no-opacity: #eef9ec;
$g-color-green-hover: #1ca205;
$g-color-cyan: #0fd8bb;
$g-color-dark-cyan: #37c0e5;
$g-color-dark-cyan-2: #09bcfa;
$g-color-indigo-blue: #2617d8;
$g-color-purple: #c94ffd;
$g-color-cerise: #d6138e;
$g-color-hotpink: #e782f0;
$g-natural-light-gray: #f4f5f7;

$g-color-pattern-gold: #ffdda6;
$g-color-pattern-blue: #0061ff;
$g-color-pattern-red: #fc5151;

$g-color-map-notification-backdrop: #fafafb;

// Global Fonts
$g-font-base: 'Circe', sans-serif;
$g-font-monospace: 'Inconsolata', serif;

// Global Values
$g-value-border-radius: 4px;
$g-value-border-radius-xl: 10px;
$g-value-animation-duration: 0.15s;
$g-value-animation-function: ease;
$g-value-transition: $g-value-animation-duration $g-value-animation-function;

$y-main-content-mobile-padding: 20px;
$y-main-content-padding: 22px;
$x-main-content-mobile-padding: 15px;
$x-main-content-table-padding: 18px;
$x-main-content-padding: 32px;
$height-top-bar: 64px;

// Z-index
$z-table: 10;
$z-widget-report-builder: 11;
$z-sidebar: 13;
$z-top-bar: 14;
$z-helpdesk: 15;
$z-settings: 20;
$z-filters: 20;
$z-tree-filters: 20;
$z-datepicker: 30;
$z-copyright: 999;
$z-widget-actions: 1000;
$z-overlay: 1010;
$z-float-notification: 1020;
$z-index-header-footer: 1002;

// Bootstrap Settings
$grid-breakpoints: (
  xs: 0,
  sm: 480px + 1px,
  md: 600px + 1px,
  mx: 794px + 1px,
  lg: 1024px + 1px,
  xl: 1280px + 1px,
  xxl: 1440px + 1px,
);

$grid-gutter-width: 28px;
