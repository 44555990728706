&--comparing-analytics {
  display: inline-flex;
  align-items: center;

  #{$root} {
    &__icon {
      display: inline-flex;
      align-items: center;
      font-size: 4px;
      color: $g-color-white;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: $g-color-secondary;
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--green {
        background: $g-color-green;
      }

      &--red {
        background-color: $g-color-red;
      }
    }
  }
}

&__dot {
  position: relative;
  margin: 0 10px;
  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -2px;
    width: 3px;
    height: 3px;
    background-color: $g-color-primary;
    border-radius: 50%;
  }

  &--hide {
    display: none;
  }
}
