&--csv-unsecure {
  #{$root} {
    &__body-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 22px;
      padding: 27px 0;

      background-image: url('../../../../assets/new-img/bg/csv-bg.svg');
      background-repeat: no-repeat;
      background-position: -16px -16px;

      border: 1px solid rgba($color: $g-color-primary, $alpha: 0.1);
      border-radius: 10px;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
    }
  }
}
