&--horizontal {
  flex-basis: 100%;
  display: flex;
  align-items: center;

  #{$root} {
    &__label {
      margin-right: 14px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .c-button {
        margin-left: 8px;
      }
    }

    &__line {
      width: 100%;
      height: 1px;
    }

    &__btn {
      margin-left: 14px;
      display: flex;
      align-items: center;

      &-icon {
        font-size: 11px;
      }
    }

    &__count {
      font-size: 12px;
      line-height: 18px;
      margin-left: 8px;
      color: rgba($g-color-secondary, 0.5);
    }

    &__btn-add {
      flex-shrink: 0;
    }
  }
}
