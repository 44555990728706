.c-widget {
  $root: &;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  position: relative;

  .-border {
    border: 1px solid $g-color-gray;
  }

  &__body-container {
    overflow: auto;
  }

  table {
    border-radius: $g-value-border-radius-xl;
  }

  .data-empty {
    font-size: 13px;
    line-height: 20px;
    color: rgba($g-color-secondary, 0.5);
    text-align: center;
    &.-no-body {
      padding: 18px;
    }
  }

  &--d {
    #{$root} {
      &__body {
        padding: 18px;
      }
    }
  }

  &__overview {
    background: rgba($g-natural-light-gray, 0.5);
    border-top: 1px solid $g-color-gray;
    border-left: 1px solid $g-color-gray;
    border-right: 1px solid $g-color-gray;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    min-height: 36px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: -1px;
      top: 33px;
      border-left: 1px solid $g-color-gray;
      background-color: rgba($g-natural-light-gray, 0.5);

      @media (max-width: 315px) {
        top: 42px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: -1px;
      top: 33px;
      border-right: 1px solid $g-color-gray;
      background-color: rgba($g-natural-light-gray, 0.5);

      @media (max-width: 315px) {
        top: 42px;
      }
    }

    &-title {
      font-weight: 800;
      font-size: 11px;
      line-height: 16px;
      text-transform: uppercase;
      color: rgba($g-color-secondary, 0.3);
      margin-left: 24px;
      margin-right: 4px;
      align-items: center;
      display: flex;
    }

    &-settings {
      margin-right: 24px;
      margin-left: 4px;
      display: inline-block;
      font-style: normal;
      font-weight: 800;
      font-size: 11px;
      line-height: 16px;
      text-transform: uppercase;
      color: $g-color-primary;
      border: none;
      background-color: transparent;
      &:hover {
        color: $g-color-primary-hover;
      }
    }
  }

  &__hint-button {
    position: relative;
    top: -8px;
    left: -10px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__body {
    position: relative;
    border: 1px solid $g-color-gray;
    padding-left: 0;
    padding-right: 0;

    margin: 0;
    background-color: $g-color-white;
    border-radius: $g-value-border-radius-xl;

    @include h-scrolled;

    &.-bottom {
      border-radius: $g-value-border-radius-xl;
      &-blue {
        border-bottom: 4px solid $g-color-pattern-blue;
      }
      &-red {
        border-bottom: 4px solid $g-color-pattern-red;
      }
      &-gray {
        border-bottom: 4px solid $g-natural-light-gray;
      }
    }

    &--info {
      margin: 0;
      border-radius: $g-value-border-radius-xl;
      background-color: transparent;
    }

    &--mobile-widget {
      overflow-x: auto;
      position: relative;

      #{$root}__settings {
        cursor: pointer;
        position: absolute;
        top: 17px;
        right: 24px;
        z-index: 2;
        height: 20px;
        width: 20px;

        &,
        &:hover {
          background-color: $g-color-black;
        }

        @include media-breakpoint-down(md) {
          top: 10px;
          right: 19px;
        }
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: $g-value-border-radius-xl;
        border: 1px solid $g-color-gray;
      }
    }

    &.-no-overflow-x {
      overflow-x: initial;
    }

    &.-no-bd {
      border: none;
    }

    &.-no-bg {
      background: none;
    }

    &.-no-bd-r {
      border-radius: 0;
    }
  }

  .-align-right {
    text-align: right;
    justify-content: flex-end;
  }

  &__footer {
    overflow: scroll;
    @include h-scrolled;
  }

  &__actions-button {
    width: 30px;
    cursor: pointer;
    height: 30px;
    font-size: 6px;
    display: none;
    text-align: center;
    line-height: 28px;
    color: $g-color-primary;
    border-radius: 8px;
    background-color: $g-color-light-blue;

    &.is-active {
      color: $g-color-white;
      background-color: $g-color-primary;
    }

    &.is-active + #{$root}__actions {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      z-index: $z-widget-actions;
      position: absolute;
      display: none;
      min-width: 130px;
      padding: 9px 5px;
      flex-direction: column;
      background-color: $g-color-white;
      top: 36px;
      right: 0px;
      border-radius: $g-value-border-radius-xl;
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba($g-color-secondary, 0.15);

      #{$root} {
        &__action {
          width: 100%;
          position: relative;
          padding: 5px 11px !important;
          font-size: 13px !important;
          justify-content: left;
          background-color: $g-color-white !important;
          margin-right: 0 !important;
          border: none !important;
          border-radius: $g-value-border-radius-xl;
          color: $g-color-secondary !important;
          white-space: nowrap;

          &::after {
            content: '';
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: -2px;
            height: 1px;
            width: 100%;
            background-color: rgba($g-color-gray, 0.4);
          }

          &:last-child {
            margin-bottom: 0 !important;

            &::after {
              display: none;
            }
          }

          &.is-active {
            color: $g-color-primary;
            background-color: rgba($g-color-primary, 0.07);
          }
        }
      }
    }

    gtd-action-button {
      margin-right: 8px;

      &.is-disabled {
        pointer-events: none;
      }

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 4px !important;
        margin-right: 0px !important;
      }
    }

    &--right {
      margin-left: auto;
    }
  }

  &__action {
    display: flex;
    margin-right: 30px;

    &--section {
      & > .c-widget__action {
        margin-right: 10px;
      }
    }

    &--counter {
      font-size: 13px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &--is--loading {
    .c-widget__header {
      z-index: 21;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 18px;

    #{$root} {
      &__content-right {
        position: relative;
        display: flex;
        align-items: center;
      }

      &__content-left {
        white-space: nowrap;
        display: flex;
        align-items: center;

        .header__groups {
          padding: 0 12px;
          border-left: 1px solid $g-color-gray;
          @include h-typo--additional;
          font-weight: bold;
        }

        .c-button--menu-section {
          margin-left: 6px;
          height: 26px;
          width: 130px;
          @include h-typo--additional;
          font-weight: bold;
          padding: 5px 10px;
          justify-content: space-between;

          .-color-secondary:after {
            opacity: 1;
            font-weight: normal;
          }
        }
      }
    }

    &--rotation {
      background-color: rgba($g-color-light-blue, 0.8);
      margin-bottom: initial;
      padding: 18px 18px 10px 18px;
      border-bottom: 1px solid $g-color-gray;
      border-top-right-radius: $g-value-border-radius-xl;
      border-top-left-radius: $g-value-border-radius-xl;

      #{$root} {
        &__title {
          font-size: 15px;
        }
      }
    }
  }

  &__title {
    @include h-typo--headline-2;
    font-weight: bold;
    margin-right: 12px;
    white-space: pre-wrap;

    &.-big {
      @include h-typo--headline-1;
      margin-right: 14px;
      display: none;

      @include media-breakpoint-up(md) {
        display: initial;
      }
    }
  }

  &__add-block {
    display: flex;
    cursor: pointer;
  }

  &__add-icon {
    margin-right: 12px;
  }

  &__add-description {
    align-self: center;
    font-weight: 700;
    color: $g-color-primary;
    font-size: 13px;
  }

  &__actions-icon {
    display: flex;

    gtd-action-button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    color: $g-color-primary;
    font-size: 8px;
    @include h-general-transition(color);

    &:hover {
      cursor: pointer;
      color: $g-color-primary-hover;
    }
  }

  &__content-right {
    margin-left: auto;
    padding-left: 15px;
  }

  &__header-btn {
    margin-right: 8px;
    display: flex;
    justify-items: center;

    &:last-child {
      margin-right: 0;
    }

    &--separator {
      width: 1px;
      height: 20px;
      background-color: $g-color-gray;
    }

    &.-big {
      margin-right: 12px;
    }
  }

  &__data-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    color: rgba($g-color-secondary, 0.5);
    width: 100%;
  }

  &.-overflow-x {
    overflow-x: scroll;
    @include h-scrolled;
  }

  &__links {
    display: flex;
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($g-color-white, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    .c-preloader {
      margin: 0;
    }
  }

  &.-content-width {
    #{$root} {
      &__table {
        table {
          min-width: auto;
          white-space: nowrap;
        }
      }
    }
  }

  &__empty {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__empty-inner {
    width: 240px;
    max-width: 100%;
    text-align: center;
  }

  &__empty-icon {
    font-size: 30px;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;
      background-size: contain;
    }

    &.-circled {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($g-color-secondary, 0.1);
      font-size: 14px;
    }
  }

  &__empty-text {
    @include h-typo--body-2;
    margin-bottom: 14px;
  }

  &__empty-link {
    position: relative;
    display: inline-block;
    color: $g-color-primary;
    @include h-typo--body-2;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: 28px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        right: -16px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: rgba($g-color-secondary, 0.2);
      }
    }

    &:hover {
      text-decoration: none;
      color: $g-color-primary-hover;
    }
  }

  &__empty-link-icon {
    margin-right: 8px;
    font-size: 20px;
    vertical-align: sub;
  }

  &__tab {
    padding: 0 !important;
    @include h-typo--body-2;
    font-weight: bold;

    .text {
      color: rgba($g-color-secondary, 0.3);
    }

    &.-active .text {
      color: $g-color-primary;
    }

    &:not(.-active):hover .text {
      color: rgba($g-color-secondary, 0.5);
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @import 'c-widget--filters';
  @import 'c-widget__table';
  @import 'c-widget--statistic';
  @import 'c-widget--info';
  @import 'c-widget--key-value';
  @import 'c-widget--lead-profile';
  @import 'c-widget--chart';
  @import 'c-widget--body-simple';
  @import 'c-widget--statistic-ext';
  @import 'c-widget--custom-range';
  @import 'c-widget--permissions';
  @import 'c-widget--map-notifications';
  @import 'c-widget--info-widgets';
  @import 'c-widget--gradient';
  @import 'c-widget--help-card';
  @import 'c-widget--top-lined';
  @import 'c-widget--help-card-2';
  @import 'c-widget--status-logs';
  @import 'c-widget--reports-creator';
  @import 'c-widget--stories';
  @import 'c-widget--groups';
  @import 'c-widget--funnel-help-card';
}
