&--gradient {
  #{$root} {
    &__inner {
      background-image: url('../../../../assets/new-img/bg/pattern.svg');
      background-color: $g-color-light-blue;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      padding: 18px;
      border: none;
      border-radius: $g-value-border-radius-xl;

      &.widget__body_half-box {
        background-color: rgba($g-color-primary, 0.05);
        background-image: none;
      }
    }
  }

  .presets {
    min-width: 100%;
    @include media-breakpoint-up(md) {
      min-width: 484px;
    }
  }
}
