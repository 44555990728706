.c-nav-panel {
  $root: &;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;

  @include media-breakpoint-up(lg) {
    background-color: $g-color-secondary;
    background-image: url('../../../../assets/new-img/bg/sidebar-bg.svg');
    background-repeat: no-repeat;
    background-position-y: center;
  }

  &__item {
    position: relative;
    &--setings {
      margin-bottom: auto;

      .c-nav-panel {
        &__logo {
          border: none;
          background-color: #273265;
        }

        &__subtitle {
          top: 80%;
        }
      }
    }
  }

  &__header {
    display: block;
    @include media-breakpoint-down(mx) {
      display: none;
    }
  }

  .c-nav-panel__switch-arrow {
    display: none;
  }

  .c-nav-panel__arrow-down--show {
    @media (min-width: map_get($grid-breakpoints, lg)) {
      font-size: 10px;
      color: $g-color-white;
      display: flex;
      justify-content: center;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__bell {
    @media screen and (max-width: 480px) {
      display: none !important;
    }
  }

  &__separator {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      width: 40px;
      height: 2px;
      background: $g-color-white;
      opacity: 0.2;
      margin: 25px 0;
      padding: 1px;
      cursor: default;
    }
  }

  &__subtitle {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      font-size: 11px;
      line-height: 16px;
      font-weight: 800;
      text-transform: uppercase;
      position: absolute;
      top: 76%;
      left: 50%;
      transform: translateX(-50%);
      color: $g-color-white;
      margin: 0px 0px;
      background: $g-color-secondary;
      border-radius: 3px;
      width: 21px;
      height: 16px;
      padding: 1px 0px 0px 0px;
      text-align: center;
    }
  }

  &__top {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
    @include media-breakpoint-down(md) {
      width: 30;
      flex-direction: row;
    }
    @include media-breakpoint-down(xs) {
      width: 0;
    }
  }

  &__popup {
    display: none;
    position: absolute;
    z-index: 14;
    height: 0;
    width: 0;

    @include media-breakpoint-up(lg) {
      bottom: 26px;
      left: 26px;
    }

    @include media-breakpoint-down(mx) {
      bottom: 0;
      right: 0;
    }
  }

  &__logo {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
    width: 40px;
    height: 40px;
    background: $g-color-white;
    border: 3px solid #54a6fe;
    box-shadow: 0px 5px 30px rgba($g-color-secondary, 0.4);
    border-radius: $g-value-border-radius-xl;
    box-sizing: content-box;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  &__switch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    font-size: 10px;
    color: $g-color-white;
    width: 40px;
    height: 40px;
  }

  &__button {
    width: 40px;
    height: 40px;
    border-radius: $g-value-border-radius-xl;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    color: $g-color-white;
    font-size: 20px;
    cursor: pointer;
    position: relative;

    a {
      text-decoration: none;
    }

    .p500-ui-nav-button {
      .h-icon-double-left {
        @include h-general-transition();
      }
    }

    &__rotated {
      .p500-ui-nav-button {
        .h-icon-double-left {
          transform: rotateY(180deg);
        }
      }
    }

    &.is-active {
      #{$root} {
        &__popup {
          display: block;

          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
    }

    &.-black {
      @include media-breakpoint-up(lg) {
        background: $g-color-secondary;

        &:hover {
          background: $g-color-secondary;
        }

        .p500-ui-nav-button {
          &:hover {
            background: initial;
          }
        }
      }
    }

    &:hover,
    &.is-active {
      background-color: rgba($g-color-white, 0.1);
    }

    &.-incidents {
      position: relative;
      background-color: $g-color-red;
      box-shadow: 0px 5px 30px rgba($g-color-secondary, 0.15);

      &:hover,
      &.is-active {
        background-color: $g-color-red-hover;
      }

      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 6px;
        right: 6px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $g-color-white;
      }
    }

    &.-profile {
      background-color: rgba($g-color-white, 0.1);
      border-radius: 20px;
    }

    &.-menu {
      font-size: 6px;
    }

    &:hover {
      text-decoration: none;
      color: $g-color-white;
    }

    @include media-breakpoint-up(sm) {
      &:hover {
        #{$root} {
          &__popup {
            display: block;
          }
        }
      }
    }

    &.-eye {
      font-size: 21px;
      background-color: rgba(0, 14, 75, 0.3);

      @include media-breakpoint-between(sm, mx) {
        background-color: transparent;

        &:hover {
          background-color: $g-color-white;
          box-shadow: 0px 5px 30px rgba($g-color-secondary, 0.15);
        }
      }

      @include media-breakpoint-down(sm) {
        &:hover {
          color: $g-color-white;
        }
      }
    }

    &--notifications {
      &.is-active {
        z-index: 14;
        background: $g-color-secondary;

        @include media-breakpoint-up(sm) {
          &:hover {
            background-color: rgba($color: $g-color-secondary, $alpha: 0.2);
          }

          .c-menu-list--notifications {
            display: block;
            top: 26px;
            right: 0;
          }
        }

        @include media-breakpoint-up(lg) {
          .c-menu-list--notifications {
            bottom: 26px;
            left: 26px;
            top: auto;
            right: auto;
          }
        }
      }

      .c-menu-list--notifications {
        display: none;
      }

      &.is-notification::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;

        width: 9px;
        height: 9px;

        background-color: $g-color-white;
        border-radius: 50%;
      }

      &.is-critical-incident::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;

        width: 9px;
        height: 9px;

        background-color: $g-color-yellow;
        border-radius: 50%;
      }

      &.is-danger-incident {
        color: $g-color-white;
        background-color: $g-color-red;
        box-shadow: 0px 5px 30px rgba($color: $g-color-secondary, $alpha: 0.15);

        &:hover,
        &.is-active {
          color: $g-color-white;
          background-color: $g-color-red-hover;
        }
      }
    }
  }

  &__bottom {
    #{$root} {
      &__button {
        margin-top: 22px;
      }
    }
  }

  @include media-breakpoint-down(mx) {
    flex-direction: row;
    padding: 0;

    &__top,
    &__bottom {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &__button {
      margin-top: 0 !important;
      margin-left: 20px;
      width: 28px;
      height: 28px;
      color: $g-color-primary;
      border-radius: 6px;

      &:hover,
      &.is-active {
        color: $g-color-primary;
        background-color: $g-color-white;
        box-shadow: 0px 5px 30px rgba($g-color-secondary, 0.15);
      }

      &.-incidents {
        font-size: 16px;
        color: $g-color-white;

        &:after {
          top: 4px;
          right: 4px;
          width: 3px;
          height: 3px;
        }
      }

      &.-profile {
        font-size: 14px;
        background-color: rgba($g-color-primary, 0.1);
        box-shadow: none;
      }
    }

    &__logo,
    &__switch-button {
      display: none;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 0 15px;
    background-color: $g-color-secondary;

    &__button {
      width: 36px;
      height: 34px;
      margin-left: 0;
      color: $g-color-white;
      border-radius: $g-value-border-radius-xl;

      &:hover,
      &.is-active {
        color: $g-color-primary;
        background-color: $g-color-white;
      }

      &.-incidents {
        &:hover,
        &.is-active {
          color: $g-color-white !important;
          background-color: $g-color-red-hover;
        }
        &:after {
          width: 4px;
          height: 4px;
          top: 6px;
          right: 6px;
        }
      }

      &.-profile {
        border-radius: $g-value-border-radius-xl;
        font-size: 20px;

        &:hover,
        &.is-active {
          background-color: $g-color-white;
        }
      }
    }
    &__bottom {
      flex-grow: 1;
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__stub-btn {
    margin-top: 22px;
    cursor: pointer;
    position: relative;
    background-color: #273265;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__stub-btn-header {
    background-color: #273265;
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  &__switch-btn {
    position: relative;
    font-size: 5px;
    color: #fff;
    width: auto;
    height: 40px;
    margin: 0 !important;
  }

  &__switch-btn:after {
    content: '';
    position: absolute;
    top: 40%;
    left: 36%;
    display: inline-block;
    opacity: 1;
    width: 10px;
    height: 5px;
    background: no-repeat
      url('data:image/svg+xml;utf8, <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.76222 0.570268L9.28092 0.188342C9.11414 0.0627925 8.91934 0 8.69698 0C8.47003 0 8.27755 0.0627925 8.11921 0.188342L4.99978 2.66298L1.88037 0.188413C1.72206 0.0628638 1.52953 7.13348e-05 1.30267 7.13348e-05C1.0802 7.13348e-05 0.885444 0.0628638 0.718634 0.188413L0.243653 0.57034C0.081025 0.69926 -0.000244141 0.853753 -0.000244141 1.03368C-0.000244141 1.21695 0.081115 1.3697 0.243631 1.49188L4.42206 4.80654C4.57617 4.93548 4.76863 5 4.99976 5C5.22655 5 5.42128 4.9355 5.58375 4.80654L9.7622 1.49188C9.92053 1.36626 9.99976 1.21353 9.99976 1.03368C9.99978 0.857106 9.92056 0.702701 9.76222 0.570268Z" fill="white"/></svg>');
  }
}
