&--tool {
  font-size: 13px;
  padding-top: 22px;
  padding-right: 0;

  @include media-breakpoint-up(xl) {
    padding-right: 32px;
  }

  #{$root} {
    &__content-right {
      margin-top: 15px;

      @media screen and (min-width: 1550px) {
        margin-top: 0;
      }
    }

    &__inner {
      &.-opacity-table {
        opacity: 0;
      }
    }

    &__rules-section {
      &:first-child {
        margin-top: 12px;
      }
    }

    &__head {
      margin-bottom: 18px;

      &.-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 1550px) {
          margin-top: 0;
          flex-direction: row;
        }
      }

      &__with-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__title {
      font-size: 15px;
      line-height: 22px;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__result {
      .input {
        width: 100%;
        color: $g-color-secondary;
        padding: 0 12px;
        border-radius: 5px;
        min-height: 34px;
        line-height: 32px;
        display: flex;
        align-items: center;
        background-color: rgba($g-color-secondary, 0.02);
        font-weight: bold;

        .right {
          margin-left: auto;
        }
      }
    }

    &__delete-selector {
      position: absolute;
      right: -32px;
      top: 27px;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 18px;
      color: rgba($g-color-secondary, 0.5);
    }

    &__body {
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        margin-bottom: 28px;
      }
    }

    &__body-title {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 18px;
      display: flex;

      .title {
        margin-right: 8px;
      }

      .actions {
        font-size: 20px;
        display: flex;
        align-items: center;
      }

      .action {
        display: flex;
      }

      .subtitle {
        @include h-typo--body-2;
        margin-top: 6px;
        color: rgba($g-color-black, 0.3);
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }
    }

    &__action {
      margin-right: 10.5px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__form-section {
      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &.-radio {
        .c-form-field__body {
          margin: 0 -15px;
          padding: 20px 15px;
          background: rgba($g-color-secondary, 0.02);

          @include media-breakpoint-up(md) {
            margin: initial;
            padding: initial;
            background: initial;
          }
        }
      }

      &.-view-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .c-view-card {
          max-width: 100%;
          flex: 0 0 100%;

          &:not(:last-child) {
            margin-bottom: 18px;
          }

          @include media-breakpoint-up(md) {
            max-width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);

            &:not(:last-child) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__form-info {
      margin-bottom: 0;
      padding-left: 0;

      &:not(:last-child) {
        padding-bottom: 18px;
      }

      > li {
        list-style-type: none;
        position: relative;
        padding-left: 14px;
        font-size: 13px;
        line-height: 20px;

        &::before {
          content: '';
          display: block;
          background: $g-color-secondary;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }

    &__row {
      &.-company {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          border-top: 1px solid rgba($g-color-gray, 0.4);
          border-bottom: 1px solid rgba($g-color-gray, 0.4);
        }

        #{$root} {
          &__col {
            display: flex;
            flex-direction: column;
            flex: 0 0 100%;
            max-width: 100%;

            &:first-child {
              margin-bottom: 20px;
            }

            @include media-breakpoint-up(md) {
              padding: 20px 30px;
              flex: 0 0 50%;
              max-width: 50%;

              &:first-child {
                padding-left: 0;
                border-right: 1px solid rgba($g-color-gray, 0.4);
                margin-bottom: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }

          &__form-section {
            order: 1;

            &:last-child:not(:first-child) {
              margin-bottom: 18px;
            }

            @include media-breakpoint-up(md) {
              order: initial;

              &:last-child:not(:first-child) {
                margin-bottom: 0;
              }
            }
          }

          &__form-info {
            order: 2;
            background: rgba($g-color-secondary, 0.02);
            padding-left: 15px;
            padding-right: 15px;
            margin-right: -15px;
            margin-left: -15px;

            &.-first {
              padding-top: 20px;
              @include media-breakpoint-up(md) {
                padding-top: 0;
              }
            }

            &.-second {
              padding-bottom: 20px;
              @include media-breakpoint-up(md) {
                padding-bottom: 0;
              }
            }

            @include media-breakpoint-up(md) {
              order: initial;
              background: initial;
              padding-left: initial;
              padding-right: initial;
              margin-right: initial;
              margin-left: initial;
            }
          }
        }
      }
    }

    &__rules {
      padding: 20px 0;
      border-top: 1px solid $g-color-gray;

      &.-last {
        border-bottom: 1px solid $g-color-gray;
        margin-bottom: 18px;
      }

      &-head {
        display: flex;
      }

      &-title {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        margin-right: 10px;
      }

      &-section {
        width: calc(100% - 32px);

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
  }

  &.-table {
    padding-top: 0;
    height: 100%;

    @include media-breakpoint-up(xl) {
      padding-right: 0;
    }

    position: relative;
    #{$root} {
      &__bg,
      &__inner {
        margin: 0 -15px;

        @include media-breakpoint-up(md) {
          margin: initial;
        }
      }

      &__inner {
        height: 100%;
        padding-top: 18px;
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(md) {
          padding-left: 32px;
          padding-right: 32px;
        }
      }

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($g-color-secondary, 0.02);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .c-button {
          transform: rotate(90deg);

          @include media-breakpoint-up(md) {
            transform: initial;
          }
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      &__text {
        @include media-breakpoint-up(sm) {
          margin-left: 10px;
        }
      }
    }
  }

  &.-gray {
    #{$root} {
      &__inner {
        background-color: rgba($g-color-secondary, 0.02);
        color: $g-color-secondary;
      }
    }
  }
}
