&--info {
  #{$root} {
    &__message {
      position: relative;
      padding: 14px;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      border-radius: $g-value-border-radius-xl;
      background-color: rgba($g-color-secondary, 0.03);

      &.-danger {
        background-color: #ffecec;
      }
    }

    &__title {
      &.-danger {
        color: #fc5151;
      }
    }
  }

  .p500-ui-modal__body {
    .c-link {
      position: absolute;
      right: 48px;
      margin-top: 8px;
    }
  }
}
