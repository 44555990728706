@font-face {
  font-family: 'media500';
  src: url('../../../assets/new-fonts/media500/fonts/media500.eot?rc3kgy');
  src: url('../../../assets/new-fonts/media500/fonts/media500.eot?rc3kgy#iefix') format('embedded-opentype'),
    url('../../../assets/new-fonts/media500/fonts/media500.ttf?rc3kgy') format('truetype'),
    url('../../../assets/new-fonts/media500/fonts/media500.woff?rc3kgy') format('woff'),
    url('../../../assets/new-fonts/media500/fonts/media500.svg?rc3kgy#media500') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='h-icon-'],
[class*=' h-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'media500' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;

  /* Better Font Rendering =========== */
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h-icon-add:before {
  content: '\e901';
}
.h-icon-android:before {
  content: '\e902';
}
.h-icon-apple:before {
  content: '\e903';
}
.h-icon-big-arrow-down:before {
  content: '\e904';
}
.h-icon-big-arrow-left:before {
  content: '\e905';
}
.h-icon-big-arrow-right:before {
  content: '\e906';
}
.h-icon-bin:before {
  content: '\e907';
}
.h-icon-bold-arrow-down:before {
  content: '\e908';
}
.h-icon-bold-arrow-left:before {
  content: '\e909';
}
.h-icon-bold-arrow-right:before {
  content: '\e90a';
}
.h-icon-bold-arrow-up:before {
  content: '\e90b';
}
.h-icon-box:before {
  content: '\e90c';
}
.h-icon-buttons-compare:before {
  content: '\e90d';
}
.h-icon-check:before {
  content: '\e90e';
}
.h-icon-circle-arrow-down:before {
  content: '\e90f';
}
.h-icon-circle-arrow-left:before {
  content: '\e910';
}
.h-icon-circle-arrow-right:before {
  content: '\e911';
}
.h-icon-circle-arrow-up:before {
  content: '\e912';
}
.h-icon-circle-check:before {
  content: '\e913';
}
.h-icon-click:before {
  content: '\e914';
}
.h-icon-close:before {
  content: '\e915';
}
.h-icon-conversion:before {
  content: '\e916';
}
.h-icon-copy-big:before {
  content: '\e917';
}
.h-icon-copy:before {
  content: '\e918';
}
.h-icon-danger:before {
  content: '\e919';
}
.h-icon-date:before {
  content: '\e91a';
}
.h-icon-download:before {
  content: '\e91b';
}
.h-icon-edit:before {
  content: '\e91c';
}
.h-icon-event:before {
  content: '\e91d';
}
.h-icon-external:before {
  content: '\e91e';
}
.h-icon-filters:before {
  content: '\e91f';
}
.h-icon-finance:before {
  content: '\e920';
}
.h-icon-folder:before {
  content: '\e921';
}
.h-icon-funnel:before {
  content: '\e922';
}
.h-icon-home:before {
  content: '\e923';
}
.h-icon-key:before {
  content: '\e924';
}
.h-icon-landings:before {
  content: '\e925';
}
.h-icon-lead:before {
  content: '\e926';
}
.h-icon-link:before {
  content: '\e927';
}
.h-icon-logout:before {
  content: '\e928';
}
.h-icon-long-arrow-right:before {
  content: '\e929';
}
.h-icon-managment:before {
  content: '\e92a';
}
.h-icon-menu:before {
  content: '\e92b';
}
.h-icon-minus:before {
  content: '\e900';
}
.h-icon-optin:before {
  content: '\e92c';
}
.h-icon-pencil:before {
  content: '\e92d';
}
.h-icon-plus:before {
  content: '\e92e';
}
.h-icon-refresh:before {
  content: '\e92f';
}
.h-icon-remove:before {
  content: '\e930';
}
.h-icon-reporting:before {
  content: '\e931';
}
.h-icon-search:before {
  content: '\e932';
}
.h-icon-select-down:before {
  content: '\e933';
}
.h-icon-select-left:before {
  content: '\e934';
}
.h-icon-select-right:before {
  content: '\e935';
}
.h-icon-select-up:before {
  content: '\e936';
}
.h-icon-server:before {
  content: '\e937';
}
.h-icon-settings:before {
  content: '\e938';
}
.h-icon-small-arrow-right:before {
  content: '\e939';
}
.h-icon-start:before {
  content: '\e93a';
}
.h-icon-steps:before {
  content: '\e93b';
}
.h-icon-time:before {
  content: '\e93c';
}
.h-icon-tools:before {
  content: '\e93d';
}
.h-icon-traffic-control:before {
  content: '\e93e';
}
.h-icon-traffic-data:before {
  content: '\e93f';
}
.h-icon-triangle-down:before {
  content: '\e940';
}
.h-icon-triangle-up:before {
  content: '\e941';
}
.h-icon-upload:before {
  content: '\e942';
}
.h-icon-target:before {
  content: '\e943';
}
.h-icon-ellipsis:before {
  content: '\e944';
}
.h-icon-templates:before {
  content: '\e945';
}
.h-icon-bag:before {
  content: '\e946';
}
.h-icon-speaker:before {
  content: '\e947';
}
.h-icon-menu-notification:before {
  content: '\e948';
}
.h-icon-controls-stop:before {
  content: '\e949';
}
.h-icon-menu-folders:before {
  content: '\e94a';
}
.h-icon-reporting-ftd:before {
  content: '\e94b';
}
.h-icon-menu-communications:before {
  content: '\e94c';
}
.h-icon-controls-crown:before {
  content: '\e94d';
}
.h-icon-question-sign:before {
  content: '\e94e';
}
.h-icon-new-reporting-advertiser:before,
.h-icon-advertiser:before {
  content: '\e94f';
}
.h-icon-new-reporting-affiliates:before,
.h-icon-affiliates:before {
  content: '\e950';
}
.h-icon-load:before {
  content: '\e952';
}
.h-icon-new-reporting-brand:before {
  content: '\e952';
}
.h-icon-new-reporting-click:before {
  content: '\e953';
}
.h-icon-new-reporting-event:before {
  content: '\e954';
}
.h-icon-new-reporting-funnel:before {
  content: '\e955';
}
.h-icon-new-reporting-lead:before {
  content: '\e956';
}
.h-icon-new-reporting-optin:before {
  content: '\e957';
}
.h-icon-new-reporting-rejected-leads:before {
  content: '\e958';
}
.h-icon-new-reporting-conversion:before {
  content: '\e959';
}
.h-icon-email:before {
  content: '\e95a';
}
.h-icon-paper-plane:before {
  content: '\e95b';
}
.h-icon-chat-bubbles:before {
  content: '\e95c';
}
.h-icon-book:before {
  content: '\e95d';
}
.h-icon-buttons-load:before {
  content: '\e95e';
}
.h-icon-other-tickets:before {
  content: '\e95f';
}
.h-icon-other-user-1:before {
  content: '\e960';
}
.h-icon-other-user-2:before {
  content: '\e961';
}
.h-icon-other-warning:before {
  content: '\e962';
}
.h-icon-other-wiki:before {
  content: '\e963';
}
.h-icon-other-bell:before {
  content: '\e964';
}
.h-icon-other-learning:before {
  content: '\e965';
}
.h-icon-other-menu:before {
  content: '\e966';
}
.h-icon-other-pen:before {
  content: '\e967';
}
.h-icon-other-platform500:before {
  content: '\e968';
}
.h-icon-other-question:before {
  content: '\e969';
}
.h-icon-other-settings:before {
  content: '\e96a';
}
.h-icon-other-metrics:before {
  content: '\e96b';
}
.h-icon-other-pie-chart:before {
  content: '\e96c';
}
.h-icon-other-podium:before {
  content: '\e96d';
}
.h-icon-collapsed:before {
  content: '\e96e';
}
.h-icon-expend:before {
  content: '\e96f';
}
.h-icon-menu-eye:before {
  content: '\e970';
}
.h-icon-reporting-brief:before {
  content: '\e971';
}
.h-icon-reporting-diagram:before {
  content: '\e972';
}
.h-icon-reporting-graphic:before {
  content: '\e973';
}
.h-icon-reporting-list:before {
  content: '\e974';
}
.h-icon-reporting-diagram2:before {
  content: '\e975';
}
.h-icon-reporting-pencil:before {
  content: '\e976';
}
.h-icon-reporting-target:before {
  content: '\e977';
}
.h-icon-reporting-wallet:before {
  content: '\e978';
}
.h-icon-reporting-percents:before {
  content: '\e979';
}
.h-icon-menu-workflow:before {
  content: '\e97a';
}
.h-icon-other-typo:before {
  content: '\e97b';
}
.h-icon-otherBoolean:before {
  content: '\e97c';
}
.h-icon-otherDate:before {
  content: '\e97d';
}
.h-icon-otherEmail:before {
  content: '\e97e';
}
.h-icon-otherPassword:before {
  content: '\e97f';
}
.h-icon-otherPhone:before {
  content: '\e980';
}
.h-icon-otherPlanet:before {
  content: '\e981';
}
