@font-face {
  font-family: 'Inconsolata';
  src: url('../../../assets/new-fonts/Inconsolata/Inconsolata-Regular.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('../../../assets/new-fonts/Inconsolata/Inconsolata-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('../../../assets/new-fonts/Inconsolata/Inconsolata-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
