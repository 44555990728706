&--split-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  #{$root} {
    &__line {
      width: 1px;
      height: auto;
      flex-grow: 1;
      background-color: $g-color-primary;
      margin-bottom: 0;
    }

    &__label {
      font-size: 12px;
      line-height: 14px;
      max-width: 48px;
      text-align: center;
      margin-top: 4px;
      margin-bottom: 4px;
      color: $g-color-primary;
    }
  }
}
