.c-form-field-header {
  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    font-size: 12px;
    line-height: 18px;
    color: rgba($g-color-primary, 0.3);

    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $g-color-primary;
    }

    &.warn {
      color: rgba($g-color-red, 0.3);

      &:hover {
        color: $g-color-red;
      }
    }
  }
}
