.p-settings {
  $root: &;
  .c-page-header {
    display: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: 1090px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    margin-right: 14px;

    color: $g-color-white;

    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 14, 75, 0.05);

    &--parameters {
      background-color: $g-color-cerise;
    }

    &--customize {
      background-color: $g-color-yellow;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;

    list-style: none;
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 12px;

    border-bottom: 1px solid rgba($color: $g-color-gray, $alpha: 0.4);
  }

  &__item-info {
    max-width: 90%;
    padding-right: 10px;
  }

  &__item-name {
    @include h-typo--body-1;
    color: $g-color-primary;
    text-decoration: none !important;

    cursor: pointer;
  }

  &__item-description {
    @include h-typo--body-3;
    color: $g-color-secondary;
    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;
  }

  &__button {
    color: $g-color-primary !important;

    border-color: $g-color-primary !important;
    opacity: 0.2;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &:active {
      opacity: 0.7;
    }
  }

  @include media-breakpoint-up(sm) {
    .c-page-header {
      display: block;
      margin-bottom: 40px;
      padding-bottom: 24px;

      border-bottom: 1px solid $g-color-gray;
    }
  }

  @media (min-width: 686px) {
    &__item {
      width: 290px;

      &:nth-child(odd) {
        margin-right: 60px;
      }
    }
  }

  @include media-breakpoint-up(mx) {
    &__body {
      flex-direction: row;
    }

    &__content-main {
      border-right: 1px solid $g-color-gray;
    }

    &__content-aside {
      margin-left: 30px;

      #{$root} {
        &__item {
          margin-right: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__content-main {
      padding-right: 79px;
    }

    &__content-aside {
      margin-left: 80px;
    }
  }
}
