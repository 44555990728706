&--2fa {
  #{$root} {
    &__description {
      padding: 10px;
      margin-bottom: 14px;
      border-radius: 5px;
      background-color: rgba($g-color-secondary, 0.03);
    }

    &__send-button {
      .text {
        font-weight: normal;
      }
    }

    &__gdpr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;

      img {
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 10px;

        @include h-typo--headline-2;
        font-weight: 700;
      }

      p {
        min-width: 290px;
        margin-bottom: 20px;

        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        padding: 30px 38px;
      }
    }
  }
}
