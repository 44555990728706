.c-field-value {
  $root: &;
  display: inline-block;

  &--hidden {
    display: flex;
    align-items: center;

    #{$root} {
      display: flex;
      align-items: center;

      &__inner {
        font-size: 16px;
        font-weight: bold;
        top: 4px;
        position: relative;
      }

      &__text {
        display: block;
        max-width: 295px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__toggle {
        margin-left: 10px;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.is-shown {
      #{$root} {
        &__inner {
          top: 0;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }

  &__icon {
    &:first-child {
      margin-right: 0.5em;
    }
  }

  &__text {
    display: inline-flex;
    align-items: center;

    &.-light-gray {
      color: rgba($g-color-secondary, 0.2);
    }

    &.-gray {
      color: rgba($g-color-secondary, 0.5);
    }

    &.-white {
      color: $g-color-white;
    }

    &.-red {
      color: $g-color-red;
    }

    &.-green {
      color: $g-color-green;
    }

    &.-primary {
      color: $g-color-primary;
    }

    &.-link {
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;
    }

    &.-category {
      padding: 0 6px;
      border-radius: 5px;
      font-weight: bold;
    }
  }

  &--logs {
    #{$root} {
      &__key {
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: rgba(0, 14, 75, 0.3);
        margin-bottom: 2px;
      }
    }
  }

  &__deliver {
    margin: 0 0.5em;
  }

  &--key {
    display: flex;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;

    #{$root} {
      &__key {
        display: inline-block;
        color: rgba($g-color-secondary, 0.5);
        margin-right: 8px;
      }
    }
  }

  &__shape-circle {
    width: 16px;
    height: 16px;
    border: 1px solid $g-color-gray;
    border-radius: 50%;
    margin-left: 11px;

    &.-sale-status {
      width: 10px;
      height: 10px;
      border: none;
      display: inline-block;
      margin-left: 0;
    }
  }

  &__sale-status {
    border-radius: 20%;
  }

  &.-money {
    #{$root} {
      &__text {
        margin-left: auto;
      }
    }
  }

  &--key-protected {
    cursor: pointer;

    #{$root} {
      &__text {
        color: rgba($g-color-secondary, 0.3);
        font-weight: bold;
      }
    }

    &:hover {
      #{$root} {
        &__text {
          color: $g-color-primary;
        }
      }
    }
  }

  &--key-info {
    #{$root} {
      &__text {
        padding: 0 5px;
        display: inline-block;
        background-color: rgba($g-color-yellow, 0.1);
        color: $g-color-red;
      }
    }
  }

  &--view-mode {
    display: flex;
    align-items: center;

    #{$root} {
      &__icon {
        width: 22px;
        height: 22px;
        font-size: 14px;
        color: rgba($g-color-primary, 0.2);
        @include h-general-transition();
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: $g-color-primary;
          color: $g-color-white;
        }
      }
    }
  }
}
