&--map-notifications {
  $margin-label: 0 22px 14px 22px;

  #{$root} {
    &__body {
      display: flex;
      justify-content: space-between;
      overflow-x: initial;
      background-color: transparent;
    }

    &__map {
      display: flex;
      flex-direction: column;
      border: 1px solid $g-color-gray;
      border-radius: $g-value-border-radius-xl;
      position: relative;
      z-index: 0;
      flex: 0 1 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex: 0 1 calc(100% - 138px);
        width: calc(100% - 138px);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 118px;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($g-color-secondary, 0.02);
        z-index: -1;
      }
    }

    &__map-field {
      position: relative;
      display: flex;
      border-bottom: 1px solid $g-color-gray;
      margin-left: 17px;
      margin-right: 16px;

      &:last-child {
        border-bottom: none;
      }
    }

    &__drop-menu {
      padding: 15px 15px 15px 12px;
      width: 100px;
      flex-shrink: 0;
      position: relative;
      border-right: 1px solid $g-color-gray;
      display: grid;
      grid-template-rows: auto 20px;
      grid-template-areas:
        'icon btn'
        'subtitle subtitle'
        'title title';
      &-title {
        grid-area: title;
        font-size: 15px;
        font-weight: bold;
      }

      &-subtitle {
        grid-area: subtitle;
        font-size: 12px;
        line-height: 18px;
        color: rgba($g-color-secondary, 0.5);
      }

      &-icon {
        grid-area: icon;
        margin-bottom: 15px;
        display: inline-flex;
      }

      &-btn {
        justify-self: end;
        align-self: start;
        grid-area: btn;
      }
    }

    &__drop-list {
      flex: 1 1 100%;
      display: flex;
      overflow-x: scroll;
      @include h-scrolled;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 16px;

      #{$root}__template {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      &.cdk-drop-list-dragging {
        .c-label--color--dashed {
          position: absolute;

          .c-label__body {
            border-color: transparent;
          }
        }
      }
    }

    &__aside {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        background-color: rgba($g-color-secondary, 0.02);
        flex: 0 0 120px;
        width: 120px;
        border: 1px solid $g-color-gray;
        border-radius: $g-value-border-radius-xl;
        text-align: center;
      }

      &-title {
        padding-top: 11px;
        color: rgba($g-color-secondary, 0.5);
        font-size: 12px;
        line-height: 18px;
      }

      &-subtitle {
        font-size: 15px;
        font-weight: bold;
        padding-bottom: 11px;
        margin-bottom: 16px;
        border-bottom: 1px solid $g-color-gray;
      }

      &-menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        #{$root}__template {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
