@font-face {
  font-family: 'Circe';
  src: url('../../../assets/new-fonts/circe/Circe-Regular.woff2') format('woff2'),
    url('../../../assets/new-fonts/circe/Circe-Regular.woff') format('woff'),
    url('../../../assets/new-fonts/circe/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('../../../assets/new-fonts/circe/Circe-ExtraBold.woff2') format('woff2'),
    url('../../../assets/new-fonts/circe/Circe-ExtraBold.woff') format('woff'),
    url('../../../assets/new-fonts/circe/Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('../../../assets/new-fonts/circe/Circe-Bold.woff2') format('woff2'),
    url('../../../assets/new-fonts/circe/Circe-Bold.woff') format('woff'),
    url('../../../assets/new-fonts/circe/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
