.c-navigation {
  $root: &;

  padding: 0 20px;

  &__children {
    display: none;
    background-color: $g-color-white;
    border-radius: $g-value-border-radius-xl;
    padding: 9px 0;
    box-shadow: 0px 10px 20px rgba($g-color-secondary, 0.05);
    margin-top: 4px;
    margin-bottom: 8px;
  }

  &__child {
    display: block;
    @include h-typo--body-1;
    padding: 5px 10px 5px 30px;
    border-radius: 0;
    border-left: 2px solid transparent;

    color: $g-color-secondary;

    &:hover {
      color: $g-color-primary;
      text-decoration: none;
    }

    &.is-active {
      color: $g-color-primary;
      border-color: $g-color-primary;
      font-weight: bold;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__menu-item {
    &.is-active {
      color: $g-color-primary;
    }

    &.-no-children {
      &:after {
        content: '';
        display: none;
        width: 8px;
        margin-bottom: 3px;
        padding-left: 0;
        height: 8px;
        background: $g-color-primary;
        border-radius: 50%;
      }

      &.is-active {
        &:after {
          display: block;
        }
      }
    }
  }

  &__section {
    &:first-child {
      margin-top: 8px;
    }

    &.is-opened {
      #{$root} {
        &__menu-item {
          color: $g-color-primary;

          &:after {
            opacity: 1;
            transform: scale(1, -1);
            color: $g-color-primary;
          }
        }

        &__children {
          display: block;
        }
      }
    }
  }

  &__i-stub {
    padding: 6px 0;
    width: 70%;
    margin: 4.5vh auto;
    position: relative;
    background-color: #dedfe3;
  }

  &__i-stub:before {
    content: '';
    background-position: 0 0;
    height: 11px;
    width: 100%;
    position: absolute;
    top: 0;
    animation-name: backgrounds;
    animation-duration: 1s;
    animation-timing-function: easeOutCubic;
    background: linear-gradient(to left, #e2e1e1 10%, #e6e6e6 30%);
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}
