&--tables {
  #{$root} {
    &__item {
      white-space: nowrap;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin: 4px 0;
        background-color: $g-color-gray;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__item-text {
      display: block;
      padding: 5px 10px;
      border-radius: $g-value-border-radius-xl;
      min-width: 120px;
      @include h-typo--body-1;
      @include h-general-transition;
      font-weight: bold;

      &:hover {
        color: $g-color-primary;
        background-color: rgba($g-color-primary, 0.1);
      }
    }
  }
}
