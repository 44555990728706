&--builder {
  display: flex;
  flex-wrap: wrap;

  #{$root} {
    &__section {
      display: inline-block;
      flex: 0 0 100%;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      @media (min-width: 480px) {
        flex: 0 0 220px;
        max-width: 220px;
      }
    }
  }

  &.-preset {
    #{$root} {
      &__section {
        @media (min-width: 786px) {
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
