&--image {
  max-width: 1062px;

  #{$root} {
    &__inner {
      @include media-breakpoint-up(sm) {
        margin-top: 100px;
        margin-bottom: 100px;
      }
    }

    &__image-wrapper {
      position: relative;
      display: inline-block;
    }

    &__image {
      max-width: 100%;
    }

    &__close {
      position: absolute;
      right: 18px;
      top: 18px;
      font-size: 14px;
      line-height: 1;
      width: 30px;
      height: 30px;
      background-color: $g-color-white;
      opacity: 0.5;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include h-general-transition(opacity);

      &:hover {
        opacity: 1;
      }
    }
  }
}
