.p-dashboard {
  &__range {
    display: inline-flex;
    align-items: center;
    min-width: 260px;
    font-size: 13px;
    margin-left: 30px;
  }

  &__tabs {
    display: flex;
    align-items: center;
  }

  .c-page-header__title {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__statistic {
    background: linear-gradient(270deg, rgba(0, 14, 75, 0.0552) 0%, rgba(0, 14, 75, 0.0136) 100%);
    padding: 32px 31px 0;
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-up(md) {
      margin-left: -18px;
      margin-right: -18px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}
