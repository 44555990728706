.c-unsecured {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: rgba($g-color-yellow, 0.1);
  border-radius: 5px;
  @include h-typo--body-2;
  font-weight: bold;

  &__title {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 9px;
      font-size: 20px;
      color: $g-color-orange;
      width: auto;
    }
  }

  &__separator {
    margin: 0 14px;
    width: 2px;
    height: 19px;
    background-color: rgba($g-color-orange, 0.2);
    border-radius: 1px;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  // TODO: move orange button style to uikit
  &__button {
    background-color: $g-color-orange !important;

    .text {
      @include h-typo--additional;
      font-weight: bold;
      color: $g-color-white;
      display: inline-block;
      margin: 2px 0;
    }

    &:hover {
      background-color: $g-color-yellow !important;
    }
  }
}
