.c-nav-panel {
  .p500-ui-app-selector .p500-ui-selector__options--desktop {
    & .p500-ui-app-selector__option-logo-area {
      background-color: #273265;
      box-shadow: 0px 5px 30px rgba($g-color-primary, 0.4);
    }
    & .p500-ui-app-selector__subtitle {
      background-color: $g-color-black;
      padding: 0 4px;
    }
    & .p500-ui-app-selector__option {
      opacity: unset;
    }
  }
}
.c-sidebar__navigation {
  user-select: none;

  .p500-ui-nav-section.is-opened .p500-ui-nav-section__menu-item,
  .p500-ui-nav-section--menu-item:hover .p500-ui-nav-section__title,
  .p500-ui-nav-section--menu-item:hover:after,
  .p500-ui-nav-section.is-opened .p500-ui-nav-section__menu-item:after,
  .p500-ui-navigation .p500-ui-nav-section__icon {
    color: unset;
  }
  .p500-ui-navigation {
    padding: 0 10px;
  }

  .p500-ui-nav-section--menu-item.-incidents .p500-ui-nav-section__icon {
    color: $g-color-red !important;
  }

  .p500-ui-nav {
    &-section {
      &--menu-item {
        padding: 8px 10px;
      }

      &__menu-item {
        border-radius: 8px;
        margin-bottom: 6px;

        &::after {
          font-family: platform500;
          content: '\e903';
          font-size: 10px;
          color: rgba($g-color-black, 0.15);
        }

        &.-no-children::after {
          content: '';
          transform: translateX(-4px);
        }

        &.is-active {
          &,
          &:hover {
            background-color: rgba($g-color-primary, 0.05);
          }
          &:hover {
            color: $g-color-primary;
          }
        }

        &:hover {
          color: unset;
          background-color: rgba($g-color-black, 0.03);
          a,
          .p500-ui-nav-section__title {
            color: unset;
          }
        }
      }

      &__children {
        margin: 4px 10px 8px;
      }
    }

    &-search {
      margin: 14px auto 14px;

      &__search-input {
        border-radius: 8px;
        padding: 9px 22px 9px 34px;
        font-weight: bold;
        box-shadow: 0px 4px 16px rgba(0, 9, 50, 0.07);
      }

      &,
      & .p500-ui-icon-controls-search::before {
        color: rgba($g-color-black, 0.5) !important;
      }

      & .p500-ui-icon-controls-search {
        left: 11px;
        font-weight: bold;
      }

      & .p500-ui-icon-controls-close {
        top: unset;
      }
    }
  }
}

.p500-ui-cube-preloader {
  .-first {
    background-color: #3265cb !important;
  }
  .-second {
    background-color: #233582 !important;
  }
  .-third {
    background-color: #000e4b !important;
  }
}
