.c-date-interval {
  margin-right: 30px;
  display: flex;
  align-items: center;

  &.-page-header {
    margin-right: 30px;

    @include media-breakpoint-down(xs) {
      margin-right: 0;
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  &.-reverse {
    order: 2;
    margin-right: 0;
    margin-left: 30px;
  }

  &.-reports {
    margin-right: 16px;

    p500-ui-select-field {
      min-width: 140px;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      max-width: initial;
      margin-right: 0;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  &.-widget {
    display: none;
    @media (min-width: 680px) {
      display: flex;
    }
  }
}
