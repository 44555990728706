&--statistic-ext {
  #{$root} {
    &__body {
      border: none;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
      grid-auto-rows: minmax(max-content, 1fr);
      grid-gap: 10px;
      margin: 0 (-$x-main-content-mobile-padding);
      background-image: url('../../../../assets/new-img/bg/pattern.svg');
      background-color: $g-color-light-blue;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      padding: 15px;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        background-color: unset;
        background-image: unset;
        margin: initial;
        padding: initial;
      }
    }

    &__inner {
      @include media-breakpoint-up(md) {
        margin: initial;
        padding: 18px;
        border-radius: $g-value-border-radius-xl;
        background-image: url('../../../../assets/new-img/bg/pattern.svg');
        background-color: $g-color-light-blue;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        border: none;
      }
    }

    &__header {
      margin-bottom: 12px;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      display: flex;

      .status {
        margin-left: 8px;
        display: inline-flex;
        align-items: center;
      }

      .icon {
        display: block;
        margin-right: 6px;

        img {
          display: block;
        }
      }

      &.-danger {
        .status {
          color: $g-color-red;
        }
      }

      &.-success {
        .status {
          color: $g-color-green;
        }
      }

      &.-warning {
        .status {
          color: $g-color-orange;
        }
      }
    }

    &__footer {
      margin-top: 14px;
      opacity: 0.5;
      text-align: center;
    }

    &__item {
      &--tile {
        padding: 10px;
        background-color: $g-color-white;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 5px;

        .icon {
          width: 6px;
          height: 6px;
          display: inline-block;
          background-color: $g-color-secondary;
          border-radius: 50%;
          content: '';
          margin-right: 6px;
          flex: none;
          align-items: center;
        }

        .header {
          font-size: 13px;
          line-height: 20px;
          font-weight: bold;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
        }

        .key {
          font-size: 12px;
          line-height: 15px;
        }
      }

      &--counter {
        max-width: calc(180px - 20px);
        width: 100%;
        margin-right: 0;
        border-right: 1px solid rgba($g-color-primary, 0.5);

        .value {
          font-size: 32px;
          line-height: 1em;
          color: $g-color-secondary;
          font-weight: bold;
          text-align: center;
          margin-bottom: 2px;
        }

        .key {
          font-size: 14px;
          opacity: 0.5;
          text-align: center;
        }
      }

      &--danger {
        .header {
          color: $g-color-red;

          .icon {
            background-color: $g-color-red;
          }
        }
      }

      &--success {
        .header {
          color: $g-color-green;

          .icon {
            background-color: $g-color-green;
          }
        }
      }

      &--warning {
        .header {
          color: $g-color-orange;

          .icon {
            background-color: $g-color-orange;
          }
        }
      }
    }
  }
}
