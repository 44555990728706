.p-auth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  @include h-scrolled;
  padding: 30px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: fixed;
    background-color: $g-color-sidebar-bg;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../../assets/new-img/bg/auth.jpg');
  }

  &.-pattern {
    &:before {
      opacity: 0.3;
      background-image: none;

      @include media-breakpoint-up(md) {
        background-image: url('../../../assets/new-img/bg/bg-big-pattern.svg'), url('../../../assets/new-img/bg/bg-pattern.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: 91.7% 8.3%, 8.3% 91.7%;
        background-size: 280px 280px, 60px 160px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 50px 0;
  }

  &__content {
    width: 100%;
    max-width: 735px;

    @include media-breakpoint-up(md) {
      width: 93%;
    }

    @include media-breakpoint-up(mx) {
      width: 100%;
    }
  }

  &__inner {
    margin: 30px 0 0;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    height: auto;

    @include media-breakpoint-up(md) {
      margin: auto;
      flex-grow: initial;
    }
  }

  &__subtitle {
    color: rgba($g-color-secondary, 0.5);
    font-size: 12px;

    @include media-breakpoint-up(md) {
      font-size: 15px;
    }
  }

  &__logo {
    margin-bottom: 4px;
    width: 180px;
    object-fit: contain;

    @include media-breakpoint-up(md) {
      width: initial;
    }
  }

  &__logo-text {
    @include h-typo--headline-2;
    text-transform: uppercase;
    font-weight: 800;
  }

  &__header {
    width: 100%;
    text-align: center;
  }

  &__footer {
    display: none;
    width: 100%;
    margin-top: auto 0;
    text-align: center;
    font-size: 11px;
    color: rgba($g-color-secondary, 0.5);

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
