.c-banner {
  $root: &;

  display: flex;
  justify-content: center;
  margin-top: 9%;
  position: relative;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -5%;
    margin-bottom: 5%;
  }

  &__img {
    background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Crect x='20' y='10' width='80' height='80' rx='20' fill='%23EEF5FF'/%3E%3Cpath d='M43 19.5C35.5442 19.5 29.5 25.5442 29.5 33H28C28 24.7157 34.7157 18 43 18V19.5Z' fill='%230F5EF7' stroke='%230F5EF7'/%3E%3Cpath d='M77 80.5C84.4558 80.5 90.5 74.4558 90.5 67H92C92 75.2843 85.2843 82 77 82V80.5Z' fill='%23FFDDA6' stroke='%23FFDDA6'/%3E%3Cpath d='M60.0015 48.6806C64.4625 48.6806 68.0918 45.0513 68.0918 40.5903C68.0918 36.1294 64.4624 32.5 60.0015 32.5C55.5404 32.5 51.9111 36.1294 51.9111 40.5903C51.9111 45.0513 55.5404 48.6806 60.0015 48.6806Z' fill='%23FFDDA6'/%3E%3Cpath d='M46.953 68.1677H73.047C74.1256 68.1677 75 67.2933 75 66.2148C75 57.9437 68.271 51.2148 60.0001 51.2148C51.7291 51.2148 45 57.9437 45 66.2148C45 67.2933 45.8744 68.1677 46.953 68.1677Z' fill='%230061FF'/%3E%3Crect x='21.5' y='11.5' width='77' height='77' rx='18.5' stroke='white' stroke-width='3'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='120' height='120' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='10'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.054902 0 0 0 0 0.294118 0 0 0 0.05 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: 50% 35%;
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__desc {
    width: 100%;
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 10px;
    text-align: center;
    width: 292px;
  }
}
