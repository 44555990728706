&--notifications {
  position: static;

  padding: 0;

  #{$root} {
    &__title {
      position: sticky;
      top: 0;
      z-index: 1;

      margin: 0;
      padding: 15px;

      background-color: $g-color-pale-blue;
      border-bottom: 1px solid rgba($color: $g-color-gray, $alpha: 0.4);
    }

    &__content {
      padding: 0 15px;
    }

    &__item {
      position: relative;

      flex-direction: column;
      align-items: flex-start;

      white-space: normal;

      border-bottom: 1px solid rgba($color: $g-color-gray, $alpha: 0.4) !important;
      cursor: default;

      &:hover,
      &:focus {
        background-color: $g-color-light-blue;

        #{$root} {
          &__item-resolve {
            opacity: 1;
          }
        }
      }

      &--incident {
        cursor: pointer;
      }
    }

    &__item-title-wrapper {
      min-width: 151px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__item-title {
      @include h-typo--body-3;
      font-weight: 700;

      &::after {
        content: '';

        position: absolute;
        top: 18px;
        right: 25px;

        width: 9px;
        height: 9px;

        background-color: rgba($color: $g-color-secondary, $alpha: 0.2);
        border-radius: 50%;
      }

      &.-error {
        &::after {
          background-color: $g-color-yellow;
        }
      }

      &.-danger {
        &::after {
          background-color: $g-color-orange;
        }
      }

      &.-critical {
        &::after {
          background-color: $g-color-red;
        }
      }
    }

    &__item-resolve {
      padding-right: 12px;

      opacity: 0;
      @include h-general-transition(opacity);
      @include h-typo--body-3;
      color: $g-color-primary;
    }

    &__item-time {
      margin-bottom: 6px;

      @include h-typo--body-3;
      color: rgba($color: $g-color-secondary, $alpha: 0.3);
    }

    &__item-message {
      @include h-typo--body-2;
      color: $g-color-secondary;
    }

    &__footer {
      position: sticky;
      bottom: 0;
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      background-color: $g-color-pale-blue;

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  &.-box {
    @include media-breakpoint-up(sm) {
      position: absolute;

      padding: 0;

      #{$root} {
        &__title {
          position: static;

          margin-bottom: 0;
          padding: 15px 20px;

          background-color: $g-color-white;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }

        &__content {
          overflow-y: auto;
          max-height: 273px;
          padding: 0;

          background-color: $g-color-white;
        }

        &__item {
          padding: 14px;
          padding-right: 25px;
        }

        &__footer {
          position: static;

          background-color: $g-color-white;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }
}
