&--funnel-import {
  max-width: 906px;

  #{$root} {
    &__body {
      position: relative;
      padding: 20px 38px;
      background: rgba(230, 230, 230, 0.4);
      border-top: 1px solid $g-color-gray;
      border-bottom: 1px solid $g-color-gray;

      .row {
        margin-right: -5px;
        margin-left: -5px;
      }

      [class^='col-'],
      [class^=' col-'] {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    &__results {
      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
    }

    &__result {
      display: flex;
      align-items: center;
      @include h-typo--body-2;

      .count {
        display: inline-block;
        background: rgba(15, 94, 247, 0.1);
        border-radius: 100px;
        font-weight: bold;
        min-width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        margin-right: 8px;
      }
    }
  }
}
