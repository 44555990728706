.c-changelog {
  $root: &;

  #{$root} {
    &__inner {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(mx) {
        flex-direction: row;
      }
    }

    &__date {
      font-size: 13px;
      line-height: 20px;
      color: rgba($g-color-secondary, 0.3);
      width: 180px;
      margin-bottom: 8px;

      @include media-breakpoint-up(mx) {
        margin-bottom: 0;
      }
    }

    &__info {
      width: 100%;

      @include media-breakpoint-up(mx) {
        width: 680px;
      }
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
    }

    &__img {
      border-radius: $g-value-border-radius-xl;
      border: 1px solid rgba($g-color-primary, 0.1);
      width: 100%;
      margin-bottom: 18px;
    }

    &__desc {
      width: 100%;
      margin-bottom: 14px;
      font-weight: initial;
      font-size: 13px;
      line-height: 20px;
      color: rgba($g-color-secondary, 0.5);
    }

    &__title {
      margin-bottom: 18px;
    }

    &__version {
      margin-bottom: 18px;
    }

    &__change {
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      ul {
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0;
      }
      ul > li {
        list-style-type: none;
        font-size: 13px;
        line-height: 20px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__change-type {
      height: 18px;
      margin-bottom: 10px;
    }

    &__more {
      position: relative;
      padding: 0;
      margin-left: 23px;
      border: none;
      font: inherit;
      color: $g-color-primary;
      background-color: transparent;
      @include h-general-transition();

      &:after {
        position: absolute;
        content: '•';
        color: $g-color-secondary;
        font-size: 20px;
        line-height: 20px;
        top: 0;
        left: -13px;
      }
      &:hover {
        color: $g-color-primary-hover;
      }
    }
  }
}
