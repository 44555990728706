.c-form-builder {
  $root: &;

  overflow-x: hidden;

  &__title {
    @include h-typo--headline-2;
    font-family: $g-font-base;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
  }

  &__subtitle {
    @include h-typo--body-1;
    font-family: $g-font-base;
    font-weight: bold;
    margin-bottom: 14px;

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
  }

  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($g-color-light-blue, 0.5);
    border-radius: $g-value-border-radius-xl;

    &-modal {
      width: 100%;
      max-width: 416px;
      border-radius: 20px;
      background-color: $g-color-white;
      box-shadow: 0 20px 100px 0 rgba($g-color-black, 0.15);
      margin: 100px 10px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        margin: 50px 10px;
      }
    }

    &-body {
      padding: 30px 38px;
    }

    &-title {
      @include h-typo--headline-2;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &-footer {
      background-color: $g-color-light-blue;
      padding: 14px 38px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__language {
    background-color: rgba($g-natural-light-gray, 0.5);
    border: 1px solid rgba(230, 230, 230, 0.4);
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include h-typo--body-2;
    cursor: pointer;
    height: 36px;

    &.is-active,
    &:hover {
      background-color: rgba($g-color-light-blue, 0.3);
      border: 1px solid rgba($g-color-primary, 0.3);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__lang-primary {
    margin-left: 10px;
    color: rgba($g-color-primary, 0.3);
    font-size: 10px;
  }

  &__sort-block {
    padding: 6px 12px;
    background-color: $g-color-white;
    border: 1px dashed rgba($g-color-secondary, 0.15);
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 14px;
  }

  &__delimiter {
    height: 1px;
    background-color: rgba($g-color-gray, 0.4);
    margin: 20px 0;
  }

  .cdk-drag-placeholder {
    height: 36px;
    background-color: $g-color-white;
    border: 1px dashed rgba($g-color-secondary, 0.15);
    border-radius: 5px;
    margin-bottom: 14px;
  }

  gtd-action-button {
    margin-left: 12px;
  }

  &__accordion {
    padding: 16px 14px;
    display: block;
    &.p500-ui-accordion--open {
      background-color: rgba($g-natural-light-gray, 0.3);
    }
  }

  &__block {
    background-color: $g-color-white;
    border: 1px solid rgba($g-color-secondary, 0.05);
    border-radius: $g-value-border-radius-xl;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .body {
      position: static;
      padding-top: 16px;
    }

    .toggle {
      padding: 5px;
      cursor: pointer;
      margin-left: auto;
      color: rgba($g-color-secondary, 0.3);

      &:hover {
        color: $g-color-secondary;
      }
    }
  }
}
