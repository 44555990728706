gtd-ui-incidents-data {
  position: relative;

  @include media-breakpoint-up(sm) {
    position: absolute;
    top: -4px;
    right: 0;
    transform: translate(0, 0);
  }

  @include media-breakpoint-up(lg) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }
}

.c-incidents {
  $root: &;
  transition: transform 0.5s ease;
  position: static;
  flex-direction: column;
  z-index: 2;
  top: 32px;
  padding: 20px 15px;

  &.is-shown {
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
    transform: translate(-80%, 0);

    .c-incidents__item,
    .c-incidents__footer {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    transform: translate(0, 0);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__header-title {
    @include h-typo--additional;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba($g-color-secondary, 0.3);
    letter-spacing: 1px;
  }

  &__title {
    @include h-typo--body-3;
    font-weight: bold;
  }

  &__circle {
    position: relative;
    top: 5px;
    margin-left: 11px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: rgba($g-color-secondary, 0.5);
  }

  &__body {
    @include h-typo--body-2;
    color: $g-color-secondary;
  }

  &__title {
    @include h-typo--body-3;
    font-weight: bold;
  }

  &__subtitle {
    @include h-typo--body-3;
    color: rgba($g-color-secondary, 0.5);
  }

  &__resolve {
    opacity: 0;
    @include h-general-transition(opacity);
  }

  .content-right {
    margin-left: auto;
    display: flex;
  }

  &__item-header {
    display: flex;
    margin-bottom: 6px;
  }

  &__item {
    @include fadeIn($duration: 1s, $function: ease);
    padding: 14px 20px;
    background-color: $g-color-white;
    @include h-general-transition(background-color);
    cursor: pointer;
    border-radius: $g-value-border-radius-xl;
    box-shadow: 0px 10px 20px rgba(0, 14, 75, 0.05);
    margin-bottom: 15px;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:hover {
      background-color: rgba($g-color-light-blue, 0.8);

      #{$root} {
        &__resolve {
          opacity: 1;
        }
      }
    }

    &.-critical {
      #{$root} {
        &__title {
          color: $g-color-red;
        }

        &__circle {
          background-color: $g-color-red;
        }
      }
    }

    &.-error {
      #{$root} {
        &__title {
          color: $g-color-yellow;
        }

        &__circle {
          background-color: $g-color-yellow;
        }
      }
    }

    &.-danger {
      #{$root} {
        &__title {
          color: $g-color-orange;
        }

        &__circle {
          background-color: $g-color-orange;
        }
      }
    }

    &.-resolved {
      background-color: rgba($g-color-gray, 0.2);
      pointer-events: none;

      #{$root} {
        &__title {
          color: rgba($g-color-secondary, 0.5);
        }

        &__body {
          color: rgba($g-color-secondary, 0.5);
        }
      }
    }
  }

  &__footer {
    @include fadeIn($duration: 1s, $function: ease);
    text-align: center;
    padding: 10px 0;
  }

  .c-button__action {
    display: initial !important;
  }

  &.-box {
    width: 242px;
    background-color: $g-color-white;
    box-shadow: 10px 30px 60px rgba(0, 14, 75, 0.15);
    border-radius: 20px;
    max-height: 530px;
    padding: 0;

    #{$root} {
      &__header {
        margin: 0;
        padding: 20px 20px 10px;
        border-bottom: 1px solid rgba($g-color-gray, 0.4);
      }

      &__item {
        border-radius: 0;
        box-shadow: none;
        margin: 0;
      }

      &__footer {
        padding: 8px 0;
        border-top: 1px solid $g-color-gray;
      }

      &__content {
        overflow-y: auto;
        max-height: 443px;
      }
    }

    gtd-action-button {
      &:not(:last-child) {
        #{$root} {
          &__item {
            border-bottom: 1px solid rgba($g-color-gray, 0.4);
          }
        }
      }
    }
  }
}
