&--workflow {
  &#{$root}--color {
    &--blue {
      #{$root} {
        &__body {
          background-color: $g-color-dark-cyan;
          border-radius: 5px;
        }

        &__title {
          @include h-typo--body-1;
          font-weight: bold;
          text-transform: uppercase;
          color: $g-color-white;
        }

        &__subtitle {
          @include h-typo--body-2;
          color: rgba($color: $g-color-white, $alpha: 0.8);
        }
      }

      &.active {
        #{$root} {
          &__body {
            border: 2px solid $g-color-secondary;
          }
        }
      }
    }
  }

  &.-map-notification {
    &#{$root}--color {
      &--blue:hover {
        #{$root} {
          &__body {
            border: 2px solid $g-color-secondary;
          }
        }
      }
    }
  }
}
