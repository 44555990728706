.c-tour-board {
  $root: &;

  width: 360px;
  height: 100%;
  padding: 32px;
  background-color: $g-color-purple;
  color: $g-color-white;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;

  &__head {
    margin-bottom: 30px;
  }

  &__body {
    overflow-y: auto;
  }

  &__footer {
    margin-top: 30px;
  }

  &__section-number {
    margin-bottom: 10px;

    &:before {
      content: '';
      display: block;
      border-bottom: 1px solid rgba($g-color-white, 0.5);
      position: relative;
      top: 15px;
    }

    span {
      @include h-typo--headline-1;
      display: inline-block;
      padding: 0 28px;
      background-color: $g-color-purple;
      position: relative;
    }
  }

  &__section-title {
    @include h-typo--body-2;
    color: rgba($g-color-white, 0.5);
    font-weight: bold;
  }

  &__icon {
    margin-bottom: 32px;

    img {
      width: 130px;
      height: 130px;
      object-fit: contain;
    }
  }

  &__title {
    @include h-typo--headline-1;
    margin-bottom: 4px;
    text-align: center;
    position: relative;
  }

  &__description {
    @include h-typo--body-2;
    margin-bottom: 25px;
  }

  &__button-next {
    padding: 6px 15px;
    background-color: $g-color-white;
    border-radius: 16px;
    @include h-typo--body-2;
    color: $g-color-secondary;
    display: inline-block;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.3 !important;
    }
  }

  &__button-complete {
    padding: 6px 15px;
    border: 1px solid rgba($g-color-white, 0.5);
    border-radius: 16px;
    @include h-typo--body-2;
    color: $g-color-white;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 18px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__part {
    @include h-typo--body-1;
    color: rgba($g-color-white, 0.5);
    font-weight: bold;
  }

  &__steps {
    position: relative;
    text-align: left;
    margin-left: 28px;
    margin-bottom: 25px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      height: 100%;
      border-left: 2px solid rgba($g-color-white, 0.2);
    }
  }

  &__step {
    position: relative;
    padding-left: 32px;
    margin-bottom: 20px;
    @include h-typo--body-2;

    &:before {
      content: '';
      font-family: 'media500';
      font-size: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: $g-color-purple;
      color: $g-color-white;
      border: 1px solid $g-color-white;
      border-radius: 11px;
      text-align: center;
    }

    &.-passed {
      color: $g-color-cyan;

      &:before {
        content: '\e90e';
      }
    }
  }

  &__tour-icon {
    margin-bottom: 32px;

    img {
      width: 130px;
      height: 130px;
      object-fit: contain;
    }
  }

  &__tour-title {
    @include h-typo--headline-1;
    margin-bottom: 18px;
  }

  &__tour-description {
    @include h-typo--body-2;
    margin-bottom: 25px;
  }

  &__tour {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
  }

  &__back-btn {
    color: $g-color-white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  &__subtitle {
    @include h-typo--body-1;
    font-weight: bold;
    text-align: center;
    color: rgba($g-color-white, 0.5);
    margin-bottom: 24px;
  }

  &__tour-link {
    background-color: rgba($g-color-white, 0.2);
    padding: 20px;
    border-radius: $g-value-border-radius-xl;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:hover {
      background-color: rgba($g-color-white, 0.3);
    }

    &:after {
      content: '\e906';
      font-family: 'media500';
      display: inline-block;
      font-size: 14px;
      position: absolute;
      top: 20px;
      right: 17px;
    }
  }

  &__tours-list {
    margin-bottom: -32px;
  }

  &__tour-link-title {
    @include h-typo--body-1;
    font-weight: bold;
  }

  &__tour-link-icon {
    width: 22px;
    height: 22px;
    background-size: contain;
    margin-right: 11px;
  }

  &__tour-info {
    @include h-typo--body-2;
    display: flex;
    margin-bottom: 32px;
  }

  &__tour-progress {
    margin-right: 12px;
    @include h-typo--body-2;
  }
}
