.c-dot-separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba($g-color-secondary, 0.2);
  margin-left: 5px;
  margin-right: 20px;

  @media screen and (max-width: 480px) {
    display: none;
  }
}
