&--top-lined {
  #{$root} {
    &__body-container {
      position: relative;
      border-radius: $g-value-border-radius-xl;
    }

    &__table {
      padding-top: 5px;
    }

    &__top-line {
      display: flex;
      height: 4px;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;

      &-part {
        flex: 1;

        &.-red {
          background-color: $g-color-pattern-red;
        }

        &.-gold {
          background-color: $g-color-pattern-gold;
        }

        &.-blue {
          background-color: $g-color-pattern-blue;
        }
      }
    }
  }
}
