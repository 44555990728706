&--profile {
  #{$root} {
    &__direction {
      min-height: 64px;
      background-color: rgba($g-color-secondary, 0.03);
      border-radius: $g-value-border-radius-xl;
      padding: 12px;
    }

    &__direction-title {
      @include h-typo--body-1;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $g-color-secondary;
    }

    &__direction-subtitle {
      @include h-typo--body-3;
      word-break: break-word;
      color: rgba($g-color-secondary, 0.5);
    }

    &__left {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 100%;
      word-break: break-all;
      margin-bottom: 14px;
    }

    &__left-body {
      display: inline-flex;
      align-items: center;
      flex: 1;
    }

    &__square {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      flex-shrink: 0;
      background-color: $g-color-primary;
      color: $g-color-white;
      box-shadow: 0px 5px 10px rgba($g-color-primary, 0.2);
      border-radius: $g-value-border-radius-xl;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__icons {
      font-size: 18px;
    }

    &__title {
      @include h-typo--body-1;
      font-weight: bold;

      &.-is-required {
        &:after {
          color: $g-color-red;
          margin-left: 2px;
          content: '*';
        }
      }
    }

    &__subtitle {
      @include h-typo--body-3;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.3;
    }

    &__separator {
      margin: 0 6px;
      opacity: 0.3;
    }

    &__button {
      flex-shrink: 0;
      opacity: 0.2;
      margin-left: auto;
      padding-left: 10px;
      @include h-general-transition(opacity);
    }
  }
  &.-not-active {
    opacity: 0.6;
    filter: grayscale(70%);
    background: rgba($g-color-secondary, 0.05);
  }
}
