&--primary {
  @include h-typo--body-2;

  &.-blue {
    color: $g-color-primary;

    &:hover {
      color: $g-color-primary-hover;
    }
  }

  &.-red {
    color: $g-color-red;

    &:hover {
      color: $g-color-red-hover;
    }
  }

  &.-alt {
    opacity: 0.3;
    @include h-typo--additional;

    &:hover {
      opacity: 1;
    }
  }

  &.-alt-2 {
    @include h-typo--body-3;
    font-weight: bold;
  }

  #{$root} {
    &__icon {
      margin-right: 2px;
      font-size: 10px;
    }
  }
}
